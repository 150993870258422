import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { BtnHexagon } from '@/components/BtnHexagon'
import ArrowSvg from '@/assets/pagination-arrow.inline.svg'
import * as styles from './BtnPaginate.styles.scss'

const BtnPaginate = ({ onClick, direction, isDisabled }) => {
  return (
    <BtnHexagon
      onClick={onClick}
      isDisabled={isDisabled}
      ariaLabel={direction === 'prev' ? 'Previous' : 'Next'}
    >
      <ArrowSvg
        className={classNames(styles.icon, {
          [styles.prev]: direction === 'prev',
        })}
      />
    </BtnHexagon>
  )
}

BtnPaginate.defaultProps = {
  direction: 'next',
}

BtnPaginate.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(['prev', 'next']),
  isDisabled: PropTypes.bool,
}

export { BtnPaginate }
