import { getRichText } from '@/storyblok/helpers/getRichText'
import { getLink } from '@/storyblok/helpers/getLink'

export const getFeatureSlideshow = (blok, options = {}) => {
  const { imageAssets } = options
  const {
    planetSpinDesert,
    planetSpinLush,
    planetSpinObsidian,
    planetSpinOceanic,
    planetSpinVolcanic,
    speciesBhalgrul,
    speciesEarthling,
    speciesVesnak,
    speciesX13snt,
    speciesBhalgrulBg,
    speciesEarthlingBg,
    speciesVesnakBg,
    speciesX13sntBg,
    star,
    ori,
  } = imageAssets

  const SLIDE_VARIANTS = {
    'desertic-planet': {
      scene: 'planet',
      thumbIcon: 'desertic_planet',
      media: {
        animation: planetSpinDesert,
        still: { src: planetSpinDesert.bgImage },
      },
      preloadedImages: {
        planetSpinDesert: {
          // lazy load planet frame sequence images
          bgImage: planetSpinDesert.bgImage,
          maskImage: planetSpinDesert.maskImage,
        },
      },
    },
    'lush-planet': {
      scene: 'planet',
      thumbIcon: 'lush_planet',
      media: {
        animation: planetSpinLush,
        still: { src: planetSpinLush.bgImage },
      },
      preloadedImages: {
        planetSpinLush: {
          // lazy load planet frame sequence images
          bgImage: planetSpinLush.bgImage,
          maskImage: planetSpinLush.maskImage,
        },
      },
    },
    'obsidian-planet': {
      scene: 'planet',
      thumbIcon: 'obsidian_planet',
      media: {
        animation: planetSpinObsidian,
        still: { src: planetSpinObsidian.bgImage },
      },
      preloadedImages: {
        planetSpinObsidian: {
          // lazy load planet frame sequence images
          bgImage: planetSpinObsidian.bgImage,
          maskImage: planetSpinObsidian.maskImage,
        },
      },
    },
    'oceanic-planet': {
      scene: 'planet',
      thumbIcon: 'oceanic_planet',
      media: {
        animation: planetSpinOceanic,
        still: { src: planetSpinOceanic.bgImage },
      },
      preloadedImages: {
        planetSpinOceanic: {
          // lazy load planet frame sequence images
          bgImage: planetSpinOceanic.bgImage,
          maskImage: planetSpinOceanic.maskImage,
        },
      },
    },
    'volcanic-planet': {
      scene: 'planet',
      thumbIcon: 'volcanic_planet',
      media: {
        animation: planetSpinVolcanic,
        still: { src: planetSpinVolcanic.bgImage },
      },
      preloadedImages: {
        planetSpinVolcanic: {
          // lazy load planet frame sequence images
          bgImage: planetSpinVolcanic.bgImage,
          maskImage: planetSpinVolcanic.maskImage,
        },
      },
    },
    bhalgrul: {
      scene: 'species',
      thumbIcon: 'bhalgrul',
      headingIcon: { variant: 'bhalgrul' },
      media: {
        foreground: speciesBhalgrul,
        background: speciesBhalgrulBg,
      },
      preloadedImages: { speciesBhalgrul, speciesBhalgrulBg },
    },
    earthling: {
      scene: 'species',
      thumbIcon: 'earthling',
      headingIcon: { variant: 'earthling' },
      media: {
        foreground: speciesEarthling,
        background: speciesEarthlingBg,
      },
      preloadedImages: { speciesEarthling, speciesEarthlingBg },
    },
    vesnak: {
      scene: 'species',
      thumbIcon: 'vesnak',
      headingIcon: { variant: 'vesnak' },
      media: {
        foreground: speciesVesnak,
        background: speciesVesnakBg,
      },
      preloadedImages: { speciesVesnak, speciesVesnakBg },
    },
    x13snt: {
      scene: 'species',
      thumbIcon: 'x13snt',
      headingIcon: { variant: 'x13snt' },
      media: {
        foreground: speciesX13snt,
        background: speciesX13sntBg,
      },
      preloadedImages: { speciesX13snt, speciesX13sntBg },
    },
    star: {
      scene: 'token',
      headingIcon: { variant: 'star', hasBg: true },
      thumbIcon: 'star',
      theme: 'yellow',
      layout: 'right',
      media: { foreground: star },
      preloadedImages: { star },
    },
    ori: {
      scene: 'token',
      headingIcon: { variant: 'ori', hasBg: true },
      thumbIcon: 'ori',
      theme: 'pink',
      layout: 'right',
      media: { foreground: ori },
      preloadedImages: { ori },
    },
  }

  const props = {
    type: 'feature_slideshow',
    variant: blok.component,
    preloadedImages: {},
  }

  props.items = blok.items.map((item) => {
    const itemVariantProps = SLIDE_VARIANTS[item.variant] || {}

    // Add to preloadedImages object for this block
    props.preloadedImages = {
      ...props.preloadedImages,
      ...itemVariantProps.preloadedImages,
    }

    return {
      uid: item._uid,
      subheading: item.subheading,
      heading: item.heading,
      description: getRichText(item.description),
      icon: {
        variant: item.icon,
        subheading: item.icon_subheading,
        heading: item.icon_heading,
      },
      link: getLink({ link: item.link, title: item.link_label }),
      ...itemVariantProps,
    }
  })

  return props
}
