import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './ShipsScene.styles.scss'

const ShipsScene = ({ children, images }) => {
  const { featuredShip01, featuredShip02, dust } = images

  return (
    <div className={styles.ShipsScene}>
      <div>{children}</div>
      <div className={styles.dust}>
        <div className={styles.dust__inner}>
          <ResponsiveImg {...dust} isLazy={false} />
        </div>
      </div>
      <div className={styles.ships}>
        <div className={styles.ships__inner}>
          <div className={styles.featuredShip02} data-speed="0.8">
            <ResponsiveImg {...featuredShip02} isLazy={false} />
          </div>
          <div className={styles.featuredShip01} data-speed="0.9">
            <ResponsiveImg {...featuredShip01} isLazy={false} />
          </div>
        </div>
      </div>
    </div>
  )
}

ShipsScene.propTypes = {
  children: PropTypes.node,
  images: PropTypes.object.isRequired,
}

export { ShipsScene }
