import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { SpaceshipBlueprint } from './SpaceshipBlueprint'
import { MouseFrameAnimation } from '@/components/MouseFrameAnimation'
import * as styles from './SpaceshipBlueprints.styles.scss'
import BlueprintSvg from './images/blueprint.inline.svg'

const SpaceshipBlueprints = ({
  heading,
  introText,
  footerText,
  items,
  preloadedImages,
}) => {
  const { spaceshipHero } = preloadedImages

  return (
    <Block>
      <Heading
        className={styles.heading}
        tag="h3"
        type={{ desktop: 'h1', mobile: 'h2' }}
      >
        {heading}
      </Heading>
      <Container indent={{ desktop: 2 }}>
        <div className={styles.spaceshipHero} data-speed="0.95" data-lag="0.05">
          <ResponsiveImg
            {...spaceshipHero}
            className={styles.spaceshipHero__img}
            isLazy={false}
            enableGlitchCanvas={true}
          />
        </div>
      </Container>
      {introText && (
        <Container indent={{ desktop: 4 }}>
          <RichText type="s1" className={styles.introText}>
            {introText}
          </RichText>
        </Container>
      )}
      {!!items?.length && (
        <Container indent={{ desktop: 3 }}>
          <div className={styles.items}>
            {items.map((item) => {
              return (
                <div className={styles.itemContainer} key={item.uid}>
                  <SpaceshipBlueprint item={item} />
                </div>
              )
            })}
          </div>
        </Container>
      )}
      {footerText && (
        <Container indent={{ desktop: 4 }}>
          <RichText type="p1" className={styles.footerText}>
            {footerText}
          </RichText>
        </Container>
      )}
    </Block>
  )
}

SpaceshipBlueprints.propTypes = {
  heading: PropTypes.node,
  introText: PropTypes.node,
  footerText: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
  preloadedImages: PropTypes.object,
}

export { SpaceshipBlueprints }
