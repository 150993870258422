import React, { useEffect, useRef, useState } from 'react'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import { useGLRenderer } from '@/hooks/useGLRenderer'
import { useInView } from '@/hooks/useInView'
import { shaderConfig as defaultShaderConfig } from './shaders/glitch-effect'
import classNames from 'classnames'
import * as styles from './GlitchCanvas.styles.scss'

gsap.registerPlugin(ScrollTrigger)


const GlitchCanvas = ({
  src,
  isFull,
  imageSrcA,
  imageSrcB,
  transitionProgress,
  shaderConfig,
  setTransitionTexturesLoaded,
  isActive,
  objectFit,
  objectPosition,
  cropTextures,
  clampTextures,
  className,
  aspectRatio,
  inViewStart,
  inViewEnd,
}) => {
  const requestRef = useRef(null)

  const ref = useRef()
  const isInView = useInView(ref, { start: inViewStart, end: inViewEnd })

  const currentImageSrcARef = useRef(null)
  const currentImageSrcBRef = useRef(null)

  const startTimeRef = useRef(new Date().getTime() / 1000 + Math.random() * 100)
  const timeScaleRef = useRef(1 + 0.1 * (Math.random() - 0.5)) // subtle time scale different between canvases to avoid aligned glitches

  const [texturesLoaded, setTexturesLoaded] = useState(false)

  // const [autoWidth, setAutoWidth] = useState(null)
  // const [autoHeight, setAutoHeight] = useState(null)

  if (isActive === undefined) { // if isActive not specified on element default to true
    isActive = true
  }

  const {
    canvasRef,
    renderAnimationFrame,
    uniforms,
    textures,
    // resizeCanvas
  } = useGLRenderer({
    shaderConfig: shaderConfig || defaultShaderConfig,
    setTexturesLoaded,
    clampTextures,
    canvasContainerRef: ref,
    className: styles.canvas,
  })

  // on transitionProgress change, update transition progress in shader
  useEffect(() => {
    uniforms.current['transitionProgress']?.set(transitionProgress)
  }, [transitionProgress])

  // if cropTextures set then set edges of textures to 0 rgba
  useEffect(() => {
    uniforms.current['textureCropAmount']?.set(cropTextures ? 1 : 0)
  }, [cropTextures])

  // once textures loaded, adjust image size and positions
  useEffect(() => {
    if (setTransitionTexturesLoaded) {
      setTransitionTexturesLoaded(texturesLoaded)
    }

    if (texturesLoaded && canvasRef.current) {
      // // if image isn't to extend full and aspect ratio not set, scale canvas container to image size
      // if (!isFull && !!aspectRatio) {
      //   const imageA = textures.current[0].image
      //   setAutoWidth(imageA.width)
      //   setAutoHeight(imageA.height)
      //   resizeCanvas()
      // }

      // if texture fit property set, adjust texture scale
      if (objectFit) { // can be 'cover' to scale image up to fill canvas, or 'contain' to scale image down to fit within canvas
        const imageA = textures.current[0].image
        const imageWidth = imageA?.width
        const imageHeight = imageA?.height
        const canvas = canvasRef.current

        if (imageA && imageWidth && imageHeight && canvas) {
          const viewRatio = canvas.height / canvas.width
          const imageRatio = imageHeight / imageWidth
          const textureVerticalProportionScale = viewRatio / imageRatio // set vertical scaling to different between image proportion and view proportion
          let textureFitScale = 1
          if (objectFit === 'cover') {
            if (viewRatio > imageRatio) { // if view proportion is larger than image propotion (height proportion is taller than width)
              textureFitScale = imageRatio / viewRatio // scale image height up
            }
          } else { // otherwise assume fit is 'contain'
            if (viewRatio < imageRatio) { // if view proportion is smaller than image propotion (height proportion is shorter than width)
              textureFitScale = imageRatio / viewRatio // scale image width down
            }
          }
          uniforms.current['textureScale']?.set(textureFitScale, textureFitScale * textureVerticalProportionScale)
        }
      }

      // if texture align property set, adjust texture position
      if (objectPosition) {
        let textureAlignOffsetX = 0
        let textureAlignOffsetY = 0
        if (objectPosition.includes('top')) {
          textureAlignOffsetY = -0.5
        } else if (objectPosition.includes('bottom')) {
          textureAlignOffsetY = 0.5
        }
        if (objectPosition.includes('left')) {
          textureAlignOffsetX = 0.5
        } else if (objectPosition.includes('right')) {
          textureAlignOffsetX = -0.5
        }
        uniforms.current['textureAlignOffset']?.set(textureAlignOffsetX, textureAlignOffsetY)
      }
    }
  }, [texturesLoaded, objectFit, objectPosition, canvasRef])

  useEffect(() => {
    if (canvasRef.current) {
      // set glitch from and two source canvases when they are changed
      // call in timeout to ensure texturesLoaded useEffect watchers are fired in TransitionCanvas
      setTimeout(() => {
        if (imageSrcA && currentImageSrcARef.current !== imageSrcA) {
          textures.current[0].set(imageSrcA)
          currentImageSrcARef.current = imageSrcA
        }
        if (imageSrcB && currentImageSrcBRef.current !== imageSrcB) {
          textures.current[1].set(imageSrcB)
          currentImageSrcBRef.current = imageSrcB
        }
      }, 0)

      if (src) {
        textures.current[0].set(src)
      }
    }

    const animate = () => {
      if (isActive && isInView && canvasRef.current) {
        // console.log('glitch update active ', src || (imageSrcA?.substr && imageSrcA.substr(0, 60)))

        const curTime = new Date().getTime() / 1000
        const elapsedTime = (curTime - startTimeRef.current) * timeScaleRef.current

        uniforms.current.time.set(elapsedTime)

        renderAnimationFrame()
      }

      requestRef.current = requestAnimationFrame(animate)
    }

    animate()

    return () => {
      cancelAnimationFrame(requestRef.current)
    }
  }, [
    canvasRef,
    imageSrcA,
    imageSrcB,
    renderAnimationFrame,
    isActive,
    isInView,
    objectFit,
  ])

  return (
    <div ref={ref} className={classNames(styles.GlitchCanvas, className, {
      [styles.isFull]: isFull,
    })} style={{ paddingTop: !!aspectRatio && !isFull && `${100 / aspectRatio}%` }}> {/* , width: autoWidth, height: autoHeight */}
    </div >
  )
}

export { GlitchCanvas }