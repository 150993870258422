import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import useEmblaCarousel from 'embla-carousel-react'
import { Block } from '@/components/Block'
import { CarouselSlide } from '../CarouselSlide'
import HexagonSvg from '@/assets/hexagon-fill.inline.svg'
import * as styles from './Carousel.styles.scss'

const Carousel = ({ items }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: true,
  })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [, setScrollSnaps] = useState([])

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  )

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <Block>
      <div className={styles.Carousel}>
        {!!items?.length && (
          <div>
            <div className={styles.nav}>
              {items.map((_, index) => (
                <button
                  key={index}
                  aria-current={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                  className={styles.nav__item}
                >
                  <HexagonSvg className={styles.nav__icon} />
                </button>
              ))}
            </div>
            <div className={styles.embla} ref={viewportRef}>
              <div className={styles.embla__container}>
                {items.map((item) => (
                  <CarouselSlide
                    {...item}
                    key={item.uid}
                    className={styles.embla__item}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Block>
  )
}

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { Carousel }
