import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GlitchCanvas } from '@/components/GlitchCanvas'
import { shaderConfig } from '@/components/GlitchCanvas/shaders/glitch-transition-effect.js'
import * as styles from './TransitionCanvas.styles.scss'
import { gsap } from 'gsap'

const TransitionCanvas = ({
  isTransitioning,
  isEffectTransitioning,
  setIsEffectTransitioning,
  imageSrcA,
  imageSrcB,
  autoPadding,
  objectFit,
  objectPosition,
  cropTextures,
  inViewStart,
  inViewEnd,
}) => {
  const ref = useRef()

  const [texturesLoaded, setTransitionTexturesLoaded] = useState(false)

  const [transitionProgress, setTransitionProgress] = useState(0)

  const transitionProgressProps = { value: 0 }

  // useEffect(() => {
  //   console.log('TransitionCanvas isEffectTransitioning', isEffectTransitioning)
  // }, [isEffectTransitioning])

  // useEffect(() => {
  //   if (!isTransitioning) {
  //     setIsEffectTransitioning(false)
  //   }
  // }, [isTransitioning])

  // start effect transition if transitioning, once changed textures have loaded
  useEffect(() => {
    if (isTransitioning && texturesLoaded) {
      setIsEffectTransitioning(true)
    }
  }, [texturesLoaded])

  // reset transition animation when moving into isTransitioning
  useEffect(() => {
    if (isTransitioning) {
      transitionProgressProps.value = 0
      setTransitionProgress(transitionProgressProps.value)
    }
  }, [isTransitioning, isEffectTransitioning, imageSrcA, imageSrcB])

  useEffect(() => {
    // transition between source canvases when isEffectTransitioning state changed
    if (isEffectTransitioning) {
      const tl = gsap.timeline()
      tl.to(transitionProgressProps, {
        value: 1,
        duration: 0.5,
        onUpdate: () => {
          setTransitionProgress(transitionProgressProps.value)
        },
        onComplete: () => {
          setIsEffectTransitioning(false)
        }
      })

      return () => {
        tl.kill()
      }
    }
  }, [isEffectTransitioning])

  return (
    <div className={styles.TransitionCanvas + ' ' + (autoPadding ? styles.autoPadding : '')} ref={ref} style={{ opacity: isEffectTransitioning ? 1 : 0 }}>
      <GlitchCanvas
        isFull={true}
        imageSrcA={imageSrcA}
        imageSrcB={imageSrcB}
        shaderConfig={shaderConfig}
        transitionProgress={transitionProgress}
        isActive={isEffectTransitioning}
        setTransitionTexturesLoaded={setTransitionTexturesLoaded}
        objectFit={objectFit}
        objectPosition={objectPosition}
        cropTextures={cropTextures}
        inViewStart={inViewStart}
        inViewEnd={inViewEnd}
      />
    </div>
  )
}

TransitionCanvas.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { TransitionCanvas }
