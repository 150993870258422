// extracted by mini-css-extract-plugin
export var Footer = "bD_f4";
export var aniSpin = "bD_d";
export var credits = "bD_f8";
export var logo = "bD_ch";
export var nav__icon = "bD_f7";
export var nav__item = "bD_fZ";
export var nav__link = "bD_f0";
export var nav__list = "bD_fp";
export var primary = "bD_cb";
export var primary__inner = "bD_f5";
export var secondary = "bD_cc";
export var secondary__inner = "bD_f6";
export var web3 = "bD_f9";
export var web3__icon = "bD_gc";
export var web3__text = "bD_gb";