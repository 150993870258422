import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Media } from '@/components/Media'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { Frame } from '@/components/Frame'
import * as styles from './ScreenPreview.styles.scss'

const ScreenPreview = ({ description, heading, subheading, media }) => {
  const { device } = useRecoilValue(browserState)

  const frameProps =
    device === 'desktop'
      ? { strokeWidth: 3, cornerWidth: 37, cornerHeight: 110 }
      : { strokeWidth: 1, cornerWidth: 24, cornerHeight: 60 }

  return (
    <Block className={styles.ScreenPreview}>
      <Container indent={{ desktop: 2, mobile: 0 }}>
        {subheading && (
          <Heading tag="h2" type="s4" className={styles.subheading}>
            {subheading}
          </Heading>
        )}
        {heading && (
          <Heading
            tag="h3"
            type={{ desktop: 'h2', mobile: 'h3' }}
            className={styles.heading}
          >
            {heading}
          </Heading>
        )}
        {description && (
          <RichText type="p1" className={styles.description}>
            {description}
          </RichText>
        )}
        <div className={styles.screenshot}>
          <div className={styles.screenshot__header} />
          <div className={styles.screenshot__body}>
            <Media {...media} />
            {/* enableGlitchCanvas={true} */}
            <Frame {...frameProps} />
          </div>
        </div>
      </Container>
    </Block>
  )
}

ScreenPreview.propTypes = {
  heading: PropTypes.node,
  subheading: PropTypes.node,
  description: PropTypes.node,
  media: PropTypes.object,
}

export { ScreenPreview }
