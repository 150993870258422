import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './HeaderBasic.styles.scss'

const HeaderBasic = ({ heading, image }) => {
  return (
    <Block
      hasPaddingTop={false}
      hasPaddingBottom={false}
      className={styles.HeaderBasic}
      bgColor="custom"
    >
      {image && (
        <div className={styles.media} data-speed="0.8">
          {/* <div className={styles.gradient} /> */}
          <ResponsiveImg
            {...image}
            isFull
            objectFit="cover"
            objectPosition="center top"
            isLazy={false}
          />
        </div>
      )}
      <div className={styles.text}>
        <div className={styles.text__inner}>
          <Container indent={{ desktop: 2 }}>
            <Heading tag="h2" type="h1">
              {heading}
            </Heading>
          </Container>
        </div>
      </div>
    </Block>
  )
}

HeaderBasic.propTypes = {
  heading: PropTypes.node,
  image: PropTypes.object,
}

export { HeaderBasic }
