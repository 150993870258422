import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './TokenScene.styles.scss'

const TokenScene = ({ media, isVisible }) => {
  return (
    <div
      className={styles.TokenScene}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <div className={styles.image} data-foreground-media>
        <ResponsiveImg
          {...media?.foreground}
          isFull
          isLazy={false}
          objectFit="contain"
          objectPosition="center"
        />
      </div>
    </div>
  )
}

TokenScene.propTypes = {
  media: PropTypes.object,
}

export { TokenScene }
