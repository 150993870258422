import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './BtnPlay.styles.scss'
import PlayIconSVG from './images/play.inline.svg'

const BtnPlay = ({ onClick, ariaLabel }) => {
  const Tag = onClick ? 'button' : 'span'
  const type = onClick ? 'button' : undefined

  return (
    <Tag
      className={styles.BtnPlay}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
    >
      <span className={styles.icon}>
        <PlayIconSVG />
      </span>
    </Tag>
  )
}

BtnPlay.defaultProps = {
  ariaLabel: 'Play',
}
BtnPlay.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
}
export { BtnPlay }
