/**
 * Returns object with type classes
 * All type classes are defined in styles/base/type-classes
 *
 * @param {String|Object} type
 */

export const getTypeClasses = (type) => {
  if (!type) return {}

  return {
    [`type-${type}`]: typeof type === 'string',
    [`type-${type.mobile}-m`]: !!type.mobile,
    [`type-${type.desktop}-d`]: !!type.desktop,
  }
}
