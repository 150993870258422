import { Cta } from '@/blocks/Cta'
import { Faqs } from '@/blocks/Faqs'
import { FeatureCarousel } from '@/blocks/FeatureCarousel'
import { FeatureGrid } from '@/blocks/FeatureGrid'
import { FeatureRows } from '@/blocks/FeatureRows'
import { FeatureSlideshow } from '@/blocks/FeatureSlideshow'
import { HeaderAbout } from '@/blocks/HeaderAbout'
import { HeaderBasic } from '@/blocks/HeaderBasic'
import { HeaderGameplay } from '@/blocks/HeaderGameplay'
import { HeaderHome } from '@/blocks/HeaderHome'
import { HeaderPlanet } from '@/blocks/HeaderPlanet'
import { HeaderTokens } from '@/blocks/HeaderTokens'
import { HomeFeature } from '@/blocks/HomeFeature'
import { Icons } from '@/blocks/Icons'
import { IconsAndText } from '@/blocks/IconsAndText'
import { LargeTextList } from '@/blocks/LargeTextList'
import { Logos } from '@/blocks/Logos'
import { LogosGrid } from '@/blocks/LogosGrid'
import { PeopleCarousel } from '@/blocks/PeopleCarousel'
import { PeopleGrid } from '@/blocks/PeopleGrid'
import { PeopleList } from '@/blocks/PeopleList'
import { ScreenPreview } from '@/blocks/ScreenPreview'
import { SpaceshipBlueprints } from '@/blocks/SpaceshipBlueprints'
import { Text } from '@/blocks/Text'
import { Timeline } from '@/blocks/Timeline'
import { TokensOverview } from '@/blocks/TokensOverview'
import { TokensSectionHead } from '@/blocks/TokensSectionHead'

/**
 * Blocks for use on page headers
 */

export const HEADER_BLOCK_TYPES = {
  header_about: HeaderAbout,
  header_basic: HeaderBasic,
  header_gameplay: HeaderGameplay,
  header_home: HeaderHome,
  header_planet: HeaderPlanet,
  header_tokens: HeaderTokens,
}

/**
 * Blocks for use in the body of a page
 */

export const BODY_BLOCK_TYPES = {
  cta: Cta,
  faqs: Faqs,
  feature_carousel: FeatureCarousel,
  feature_grid: FeatureGrid,
  feature_rows: FeatureRows,
  feature_slideshow: FeatureSlideshow,
  home_feature: HomeFeature,
  icons_and_text: IconsAndText,
  icons: Icons,
  large_text_list: LargeTextList,
  logos_grid: LogosGrid,
  logos: Logos,
  people_carousel: PeopleCarousel,
  people_grid: PeopleGrid,
  people_list: PeopleList,
  screen_preview: ScreenPreview,
  spaceship_blueprints: SpaceshipBlueprints,
  text: Text,
  timeline: Timeline,
  tokens_overview: TokensOverview,
  tokens_section_head: TokensSectionHead,
}

/**
 * Export all block types
 */

export const BLOCK_TYPES = {
  ...HEADER_BLOCK_TYPES,
  ...BODY_BLOCK_TYPES,
}
