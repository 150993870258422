// extracted by mini-css-extract-plugin
export var Cta = "bl_dC";
export var aniSpin = "bl_d";
export var background = "bl_dH";
export var background__inner = "bl_dJ";
export var btn = "bl_by";
export var heading = "bl_bk";
export var inner = "bl_v";
export var innerTriiger = "bl_dD";
export var outer = "bl_dG";
export var outerTrigger = "bl_dF";
export var text = "bl_bp";
export var text__inner = "bl_bq";