import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetRecoilState } from 'recoil'
import { preloadsState } from '@/store'
import { BlockRenderer } from '@/storyblok/components/BlockRenderer'
import { BODY_BLOCK_TYPES, HEADER_BLOCK_TYPES } from '@/constants'
import { useLocation } from '@reach/router'
import * as styles from './Page.styles.scss'

const Page = ({ headerBlocks, bodyBlocks }) => {
  const { pathname } = useLocation()
  const setPreloads = useSetRecoilState(preloadsState)

  useEffect(() => {
    let images = {}
    const allBlocks = [...headerBlocks, ...bodyBlocks]

    allBlocks.forEach((item) => {
      if (item.preloadedImages) {
        images = { ...images, ...item.preloadedImages }
      }
    })

    console.log(`🌠 Request assets for ${pathname}`, images)

    setPreloads({ pathname, images })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.Page}>
      <BlockRenderer blocks={headerBlocks} types={HEADER_BLOCK_TYPES} />
      <BlockRenderer blocks={bodyBlocks} types={BODY_BLOCK_TYPES} />
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node,
}

export default Page
