import React, { useEffect, createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { browserState, smoothScrollState, transitionState } from '@/store'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollSmoother from 'gsap/ScrollSmoother'
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect'
import { usePageLoaded } from '@/hooks/usePageLoaded'
import { TRANSITION_STATES } from '@/constants/pageTransition'
import * as styles from './SmoothScroll.styles.scss'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

const SmoothScrollContext = createContext()

// https://codesandbox.io/s/gsap-scrollsmoother-next-js-starter-forked-final-demo-l0668y

const SmoothScroll = ({ children }) => {
  const isPageLoaded = usePageLoaded()
  const setIsSmoothScroll = useSetRecoilState(smoothScrollState)
  const { device } = useRecoilValue(browserState)
  const transition = useRecoilValue(transitionState)
  const [instance, setInstance] = useState(null)
  // const isScrollLocked = useRecoilValue(scrollLockState)

  /**
   * Create SmoothScroll
   */

  useIsomorphicLayoutEffect(() => {
    if (device === 'desktop') {
      const instance = ScrollSmoother.create({
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        // ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
        // normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
      })
      setIsSmoothScroll(true)
      setInstance(instance)
    }

    return () => {
      instance?.kill()
      setIsSmoothScroll(false)
    }
  }, [setIsSmoothScroll, setIsSmoothScroll])

  /**
   * Handle page transitions
   */

  useIsomorphicLayoutEffect(() => {
    if (transition === TRANSITION_STATES.IN) {
      if (instance) {
        // Reset scroll progress
        instance.scrollTo(0, false)
        // Trigger check for smooth effects
        instance.effects('[data-speed], [data-lag]', {})
      } else {
        window.scrollTo(0, 0)
      }
      // Refresh
      ScrollTrigger.refresh()
    }
  }, [transition])

  useEffect(() => {
    if (transition === TRANSITION_STATES.READY) ScrollTrigger.refresh()
  }, [transition])

  /**
   * Handle loaded state change
   * Height change when fonts have loaded for the first time
   */

  useEffect(() => {
    ScrollTrigger.refresh()
  }, [isPageLoaded]) // previously isLoading

  /**
   * Reset scroll position on refresh, this prevents ScrollTrigger issues.
   * https://stackoverflow.com/a/18633915
   */

  useEffect(() => {
    const onUnload = () => window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onUnload)

    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  /**
   * Locked state
   * Currently not working
   */
  //
  // useEffect(() => {
  //   if (!smoothScrollRef.current) return
  //   console.log('LOCK', isScrollLocked, smoothScrollRef.current)
  //   smoothScrollRef.current.paused(isScrollLocked)
  // }, [isScrollLocked])

  return (
    <SmoothScrollContext.Provider value={instance}>
      <div id="smooth-wrapper" className={styles.SmoothScroll}>
        <div id="smooth-content">{children}</div>
      </div>
    </SmoothScrollContext.Provider>
  )
}

SmoothScroll.propTypes = {
  children: PropTypes.node,
}

export { SmoothScroll, SmoothScrollContext }
