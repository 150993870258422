/**
 * render rich text from string
 */

exports.getPlainText = (text) => {
  if (text) {
    if (typeof text === 'string') {
      return text
    }

    if (typeof text === 'object') {
      let output = ''
      text.content?.forEach((node) => {
        if (node.type === 'paragraph') {
          node.content?.forEach((paragraph) => {
            if (paragraph.type === 'text') {
              output += paragraph.text
            }
          })
        }
      })

      return output
    }
  }

  return null
}
