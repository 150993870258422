import { getFluidImage } from '@/storyblok/helpers/getFluidImage'
import { getVimeo } from '@/storyblok/helpers/getVimeo'
import { getAspectRatio } from '@/helpers/getAspectRatio'

/**
 * Get Media helper for processing Storyblok media data
 */

export const getMedia = (media, config = { maxWidth: 1000 }) => {
  // If array, then use the first item
  if (Array.isArray(media) && !!media.length) {
    media = media[0]
  }

  if (media?.component) {
    if (media.component === 'media_image') {
      return {
        mediaType: 'image',
        ...getFluidImage(media.image, { ...config }),
      }
    }

    // Video File Loop
    else if (media.component === 'media_video_loop') {
      return {
        mediaType: 'video_loop',
        src: media.asset?.filename || media.video_url,
        aspectRatio: getAspectRatio(
          parseInt(media.width),
          parseInt(media.height)
        ),
      }
    }

    // Vimeo Loop
    else if (media.component === 'media_vimeo_loop') {
      return {
        mediaType: 'video_loop',
        ...getVimeo(media.vimeo),
      }
    }

    // Vimeo Player
    else if (media.component === 'media_vimeo_player') {
      return {
        mediaType: 'video_player',
        ...getVimeo(media.vimeo),
        poster: getMedia(media.poster_media, { maxWidth: 900 }),
      }
    }

    // YouTube Player
    else if (media.component === 'media_youtube_player') {
      return {
        mediaType: 'video_player',
        src: media.youtube_url,
        aspectRatio: getAspectRatio(
          parseInt(media.width),
          parseInt(media.height)
        ),
        poster: getMedia(media.poster_media, { maxWidth: 900 }),
      }
    }

    console.warn(`getMedia: format not supported: ${media?.component}`)
  }

  return null
}
