import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { getThemePropType } from '@/helpers/getThemePropType'
import { Block } from '@/components/Block'
import { Carousel } from '@/components/Carousel'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { Frame } from '@/components/Frame'
import { Icon } from '@/components/Icon'
import * as styles from './FeatureCarousel.styles.scss'

const FeatureCarousel = ({ heading, theme, items, uid }) => {
  const { windowWidth } = useRecoilValue(browserState)

  return (
    <Block>
      <Container indent={{ desktop: 2 }}>
        {heading && (
          <Heading className={styles.heading} tag="h3" type="h2">
            {heading}
          </Heading>
        )}
      </Container>
      {!!items?.length && (
        <Carousel
          uid={uid}
          items={items.map((item) => {
            return {
              uid: item.uid,
              children: (
                <div className={styles.item__inner}>
                  <div className={styles.item__bg} />
                  <Frame />
                  <div className={styles.item__head}>
                    {item.icon && (
                      <Icon
                        variant={item.icon}
                        theme={theme}
                        className={styles.item__icon}
                      />
                    )}
                  </div>
                  <div>
                    <Heading
                      tag="div"
                      type={{
                        desktop: windowWidth > 1200 ? 'h4' : 'h5',
                        mobile: 'h4',
                      }}
                      className={styles.item__heading}
                    >
                      {item.heading}
                    </Heading>
                    {item.description && (
                      <RichText type="p2" className={styles.item__description}>
                        {item.description}
                      </RichText>
                    )}
                  </div>
                </div>
              ),
            }
          })}
        />
      )}
    </Block>
  )
}

FeatureCarousel.propTypes = {
  heading: PropTypes.node,
  theme: getThemePropType,
  items: PropTypes.arrayOf(PropTypes.object),
}

export { FeatureCarousel }
