import { useSiteConfig } from '../hooks/useSiteConfig'
import { getMedia } from '@/storyblok/helpers/getMedia'
import { getRichText } from '@/storyblok/helpers/getRichText'
import { getLink } from '@/storyblok/helpers/getLink'
import { getRichHeading } from '@/storyblok/helpers/getRichHeading'
import { getPlainText } from '@/storyblok/helpers/getPlainText'
import { getHeaderImages } from '@/storyblok/helpers/getHeaderImages'
import { getAspectRatio } from '@/helpers/getAspectRatio'
import { useImageAssets } from '@/hooks/useImageAssets'

import { getFeatureSlideshow } from '@/storyblok/helpers/getFeatureSlideshow'

import trailerThumbSrc from '@/assets/images/trailer-thumb.webp'
import sunGlowSrc from '@/assets/images/sun-glow.png'

/**
 * Takes Storyblok blok data and outputs props that
 * can be passed to Block components
 *
 * @param {array} bloks
 * @param {object} location
 */

export const useBloks = (bloks, location = {}) => {
  const site = useSiteConfig()
  const imageAssets = useImageAssets()

  return bloks.map((blok, blokIndex) => {
    // General props
    let props = {
      type: blok.component,
      uid: blok._uid || `blok-${blokIndex}`,
      location: location,
      sbEditable: blok, // for SbEditable component
    }

    switch (blok.component) {
      case 'cta': {
        const VARIANTS = {
          '01': imageAssets['cta01'],
          '02': imageAssets['cta02'],
          '03': imageAssets['cta03'],
          '04': imageAssets['cta04'],
          '05': imageAssets['cta05'],
          '06': imageAssets['cta06'],
          '07': imageAssets['cta07'],
          '08': imageAssets['cta08'],
        }
        // fields coming from globals
        props.heading = site.cta?.heading
        props.button = site.cta?.button
        props.image = VARIANTS[blok.variant]

        break
      }
      case 'faqs': {
        props.heading = blok.heading
        props.items = blok.items.map((item) => {
          return {
            uid: item._uid,
            question: item.question,
            answer: getRichText(item.answer),
          }
        })
        break
      }
      case 'feature_carousel': {
        props.heading = blok.heading
        props.theme = blok.theme
        props.items = blok.items.map((item) => {
          return {
            uid: item._uid,
            heading: item.heading,
            description: getRichText(item.description),
            icon: item.icon,
          }
        })
        break
      }

      case 'feature_grid': {
        props.theme = blok.theme
        props.items = blok.items.map((item) => {
          return {
            uid: item._uid,
            heading: getRichHeading(item.heading),
            description: getRichText(item.description),
            icon: item.icon,
          }
        })
        break
      }
      case 'feature_rows': {
        props.items = blok.items.map((item) => {
          return {
            uid: item._uid,
            heading: getRichHeading(item.heading),
            description: getRichText(item.description),
            media: getMedia(item.media, { maxWidth: 1000 }),
          }
        })
        break
      }
      case 'token_slideshow':
      case 'species_slideshow':
      case 'planet_slideshow': {
        props = {
          ...props,
          ...getFeatureSlideshow(blok, { imageAssets }),
        }
        break
      }
      case 'header_basic': {
        props.heading = getRichHeading(blok.heading)
        const blockImage = getHeaderImages(blok.variant, { imageAssets })
        props.image = blockImage
        props.preloadedImages = { blockImage }

        break
      }
      case 'header_gameplay': {
        props.heading = blok.heading
        props.introText = getRichText(blok.intro_text)
        props.bodyText = getRichText(blok.body_text)
        const { headerGameplay } = imageAssets
        props.image = headerGameplay
        props.preloadedImages = { headerGameplay }
        break
      }
      case 'header_home': {
        props.intro = getRichText(blok.intro)
        props.description = blok.description.map((item) => {
          return {
            uid: item._uid,
            characters: item.text,
            className: item.appearance,
          }
        })
        props.scrollLabel = blok.scroll_label
        const width = parseInt(blok.video_width) || 1600
        const height = parseInt(blok.video_height) || 900
        props.video = blok.video_url
          ? {
              thumbMedia: getMedia(blok.video_thumb_media),
              thumbLabel: blok.video_thumb_label,
              video: {
                url: blok.video_url,
                width,
                height,
                aspectRatio: getAspectRatio(width, height),
              },
            }
          : null
        const {
          dust,
          nebula,
          headerHomePlanetStill,
          // planetSpinObsidian,
          // headerHomePlanet,
        } = imageAssets

        // animation
        // const animatedHomePlanet = {
        // ...planetSpinObsidian, // use planetSpinObsidian sequence frames and image properties
        // bgImage: headerHomePlanet.bgImage, // use unique home planet bg and mask assets
        // maskImage: headerHomePlanet.maskImage,
        // }
        props.preloadedImages = {
          dust,
          nebula,
          // animatedHomePlanet,
          headerHomePlanetStill,
          sunGlow: { src: sunGlowSrc },
          trailerThumb: { src: trailerThumbSrc },
        }
        props.images = {
          dust,
          nebula,
          planet: headerHomePlanetStill, // animatedHomePlanet
        }
        props.icons = blok.icons?.map((item) => {
          return {
            uid: item._uid,
            title: item.title,
            icon: item.icon,
          }
        })
        break
      }
      case 'header_planet': {
        props.heading = blok.heading
        const { headerPlanet } = imageAssets
        props.preloadedImages = { headerPlanet }
        break
      }
      case 'header_about': {
        props.heading = getRichHeading(blok.heading)
        props.bodyText = getRichText(blok.body_text)
        const blockImage = getHeaderImages(blok.variant, { imageAssets })
        props.image = blockImage
        props.preloadedImages = { blockImage }

        break
      }
      case 'header_tokens': {
        const THEMES = {
          star: 'yellow',
          ori: 'pink',
        }
        const VARIANTS = {
          star: imageAssets['star'],
          ori: imageAssets['ori'],
        }

        props.heading = getRichHeading(blok.heading)
        props.description = getRichText(blok.description)
        props.preloadedImages = {
          oil01: imageAssets['oil01'],
          oil02: imageAssets['oil02'],
          star: imageAssets['star'],
          ori: imageAssets['ori'],
        }
        props.tokens = blok.items.map((item) => {
          return {
            uid: item._uid,
            heading: item.heading,
            subheading: item.subheading,
            description: getRichText(item.description),
            theme: THEMES[item.variant],
            image: VARIANTS[item.variant],
          }
        })
        break
      }
      case 'home_feature': {
        const {
          asteroid01,
          // asteroid02,
          attack,
          featuredSpecies,
          featuredSpeciesBg,
          featuredShip01,
          featuredShip02,
          dust,
          flyingShip01,
          flyingShip02,
          // planetSpinOceanic, // animation
          // planetSpinDesert, // animation
          planetOceanic, // still
          planetDesert, // still
        } = imageAssets
        const LAYOUTS = {
          planets: 'right',
          gameplay: 'left',
          ships: 'right',
          featuredSpecies: 'left',
        }
        const IMAGES = {
          planets: {
            dust,
            flyingShip: flyingShip02,
            planet01: planetOceanic, // planetSpinOceanic,
            planet02: planetDesert, // planetSpinDesert,
          },
          gameplay: {
            asteroid01,
            // asteroid02,
            attack,
            flyingShip01,
          },
          ships: { featuredShip01, featuredShip02, dust },
          species: { featuredSpecies, featuredSpeciesBg },
        }
        const PRELOAD_IMAGES = {
          planets: {
            dust,
            flyingShip02,
            planetOceanic, // still
            planetDesert, // still
            // animation
            // planetSpinOceanic: {
            //   // lazy load planet frame sequence images
            //   bgImage: planetSpinOceanic.bgImage,
            //   maskImage: planetSpinOceanic.maskImage,
            // },
            // animation
            // planetSpinDesert: {
            //   // lazy load planet frame sequence images
            //   bgImage: planetSpinDesert.bgImage,
            //   maskImage: planetSpinDesert.maskImage,
            // },
          },
          gameplay: IMAGES.gameplay,
          ships: IMAGES.ships,
          species: IMAGES.species,
        }
        props.scene = blok.variant
        props.layout = LAYOUTS[blok.variant]
        props.preloadedImages = PRELOAD_IMAGES[blok.variant]
        props.images = IMAGES[blok.variant]
        props.subheading = blok.subheading
        // props.heading = getRichHeading(blok.heading)
        props.heading = getPlainText(blok.heading)
        props.description = getRichText(blok.description)
        props.link = getLink({ link: blok.link, title: blok.link_label })
        break
      }
      case 'icons': {
        props.items = blok.items?.map((item) => {
          return {
            uid: item._uid,
            title: item.title,
            icon: item.icon,
          }
        })
        break
      }
      case 'icons_and_text': {
        props.heading = blok.heading
        props.introText = getRichText(blok.intro_text)
        props.bodyText = getRichText(blok.body_text)
        props.items = blok.items?.map((item) => {
          return {
            uid: item._uid,
            title: item.title,
            icon: item.icon,
          }
        })
        break
      }
      case 'large_text_list': {
        props.items = blok.items?.map((item) => {
          return {
            uid: item._uid,
            heading: item.heading,
          }
        })
        break
      }
      case 'logos': {
        props.subheading = blok.subheading
        props.heading = blok.heading
        props.description = getRichText(blok.description)
        props.logos = blok.logos?.map((item) => {
          return {
            uid: item._uid,
            title: item.title,
            imageSrc: item.image?.filename,
            link: getLink({ link: item.link, target: '_blank' }),
          }
        })
        break
      }
      case 'logos_grid': {
        props.heading = blok.heading
        props.logos = blok.items?.map((item) => {
          return {
            uid: item._uid,
            title: item.title,
            imageSrc: item.image?.filename,
            link: getLink({ link: item.link, target: '_blank' }),
          }
        })
        break
      }
      case 'screen_preview': {
        props.subheading = blok.subheading
        props.heading = getRichHeading(blok.heading)
        props.description = getRichText(blok.description)
        props.media = getMedia(blok.media, { maxWidth: 1000 })
        break
      }
      case 'spaceship_blueprints': {
        const ANIMATIONS = {
          sulak: imageAssets['spaceshipSpinSulak'],
          ovi: imageAssets['spaceshipSpinOvi'],
          u5f: imageAssets['spaceshipSpinU5F'],
          zenith: imageAssets['spaceshipSpinZenith'],
        }
        const PRELOAD_IMAGES = {
          sulak: imageAssets['blueprintSulak'],
          ovi: imageAssets['blueprintOvi'],
          u5f: imageAssets['blueprintU5F'],
          zenith: imageAssets['blueprintZenith'],
        }
        props.heading = blok.heading
        props.introText = getRichText(blok.intro_text)

        props.footerText = getRichText(blok.footer_text)
        props.preloadedImages = {
          spaceshipHero: imageAssets['spaceshipHero'],
        }

        props.items = blok.items?.map((item) => {
          props.preloadedImages[`${item.spaceship}Blueprint`] = {
            ...PRELOAD_IMAGES[item.spaceship], // Preload blueprint image
          }
          // Preload first animation frame
          props.preloadedImages[`${item.spaceship}FirstFrame`] = {
            src: ANIMATIONS[item.spaceship]?.frames[0],
          }

          return {
            uid: item._uid,
            heading: item.heading,
            subheading: item.subheading,
            blueprint: PRELOAD_IMAGES[item.spaceship],
            animationFrames: ANIMATIONS[item.spaceship],
          }
        })
        break
      }
      case 'text': {
        props.heading = blok.heading
        props.body = getRichText(blok.body)
        props.link = getLink({ link: blok.link, title: blok.link_label })
        break
      }
      case 'people_carousel': {
        props.heading = blok.heading
        props.items = blok.items?.map((item) => {
          const links = []

          if (item.twitter) {
            links.push({
              uid: 'twitter',
              title: 'Twitter',
              url: item.twitter,
              icon: 'twitter',
            })
          }

          if (item.instagram) {
            links.push({
              uid: 'instagram',
              title: 'Instagram',
              url: item.instagram,
              icon: 'instagram',
            })
          }

          if (item.linkedin) {
            links.push({
              uid: 'linkedin',
              title: 'LinkedIn',
              url: item.linkedin,
              icon: 'linkedin',
            })
          }

          return {
            uid: item._uid,
            name: item.name,
            subheading: item.title,
            media: getMedia(item.media),
            links,
          }
        })
        break
      }
      case 'people_grid': {
        props.heading = blok.heading
        props.items = blok.items?.map((item) => {
          return {
            uid: item._uid,
            name: item.name,
            subheading: item.title,
            media: getMedia(item.media),
            logo: item.logo?.filename ? { src: item.logo?.filename } : null,
          }
        })
        break
      }
      case 'people_list': {
        props.heading = blok.heading
        props.items = blok.items?.map((item) => {
          return {
            uid: item._uid,
            name: item.name,
            role: item.role,
          }
        })
        break
      }
      case 'timeline': {
        props.items = blok.items?.map((item) => {
          return {
            uid: item._uid,
            heading: item.heading,
            subheading: item.subheading,
            description: getRichText(item.description),
            media: getMedia(item.media),
            icon: item.icon,
            appearance: item.appearance,
          }
        })
        break
      }
      case 'tokens_overview': {
        props.heading = blok.heading
        props.description = getRichText(blok.description)
        props.link = getLink({ link: blok.link, title: blok.link_label })
        props.preloadedImages = {
          silicium: imageAssets['silicium'],
          iron: imageAssets['iron'],
        }
        props.items = blok.items?.map((item) => {
          return {
            uid: item._uid,
            title: item.title,
            label: item.label,
            icon: item.icon,
            appearance: item.appearance,
          }
        })
        break
      }
      case 'tokens_section_head': {
        const THEMES = {
          star: 'yellow',
          ori: 'pink',
        }
        const VARIANTS = {
          star: { icon: imageAssets['star'] },
          ori: { icon: imageAssets['ori'] },
        }
        props.heading = getRichHeading(blok.heading)
        props.subheading = blok.subheading
        props.description = getRichText(blok.description)
        props.theme = THEMES[blok.variant]
        props.preloadedImages = VARIANTS[blok.variant] || {}
        break
      }
      default:
        console.warn(`useBloks: No props found for ${blok.component}`)
    }

    return props
  })
}
