import React, { useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { useScrollLock } from '@/hooks/useScrollLock'
import { useRecoilState, useRecoilValue } from 'recoil'
import { globalHistory, useLocation } from '@reach/router'
import TransitionService from '@/services/TransitionService'
import { getAspectRatio } from '@/helpers/getAspectRatio'
import { menuState, browserState } from '@/store'
import { Img } from '@/components/Img'
import { MENU_ID } from '@/constants'
import planetImgSrc from '@/assets/menuPlanet.webp'
import { useToggleTransition } from './Menu.gsap'
import * as styles from './Menu.styles.scss'

const Menu = ({ nav }) => {
  const ref = useRef()
  const scrollRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const { pathname } = useLocation()
  const { device } = useRecoilValue(browserState)
  const { lockScroll, unlockScroll } = useScrollLock(scrollRef)

  useToggleTransition(ref, {
    isOpen: isMenuOpen,
    selectors: {
      panel: `.${styles.panel}`,
      nav: `.${styles.nav__list}`,
    },
  })

  useEffect(() => {
    if (isMenuOpen && device === 'mobile') {
      lockScroll()
    } else {
      unlockScroll()
    }
  }, [isMenuOpen, device])

  const handleClickLink = (event, { url, linkType }) => {
    if (linkType === 'internal') {
      event.preventDefault()
      setIsMenuOpen(false)

      setTimeout(() => {
        const currentPath = globalHistory.location.pathname
        if (TransitionService.ready && currentPath !== url) {
          TransitionService.out(url)
            .then(() => {
              navigate(url)
            })
            .catch((error) => console.warn(error))
        }
      }, 500)
    }
  }

  return (
    <div className={styles.Menu} ref={ref}>
      <div className={styles.panel} id={MENU_ID}>
        <div className={styles.bg} />
        <div className={styles.inner} ref={scrollRef}>
          <nav className={styles.nav}>
            {!!nav.length && (
              <ul className={styles.nav__list}>
                {nav.map((item) => (
                  <li key={item.uid} className={styles.navItem}>
                    <a
                      href={item.url}
                      target={item.target}
                      download={item.download}
                      className={styles.navLink}
                      onClick={(event) => handleClickLink(event, item)}
                      aria-current={pathname === item.url ? 'page' : null}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </nav>
          <div className={styles.planet}>
            <Img
              src={planetImgSrc}
              aspectRatio={getAspectRatio(750, 578)}
              objectFit="contain"
              objectPosition="center top"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Menu.defaultProps = {
  nav: [],
}

Menu.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.object),
}

export { Menu }
