import { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Trigger Outro transition on scroll
 * Note the fixed positioning is handled with `useFixedPosition()`
 */

export const useOutroTransition = (ref, { trigger, isEnabled }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const $root = ref.current
    const $trigger = $root.querySelector(trigger)

    let scrollTrigger

    if (isEnabled) {
      scrollTrigger = ScrollTrigger.create({
        trigger: $trigger,
        start: 'top 25%',
        end: 'bottom bottom',
        onToggle: (self) => {
          setIsVisible(self.isActive)
        },
      })
    }

    return () => {
      scrollTrigger?.kill()
    }
  }, [ref, trigger, isEnabled])

  return isVisible
}
