import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Hexagon } from '@/components/Hexagon'
import * as styles from './BtnHexagon.styles.scss'

const BtnHexagon = ({ children, onClick, isDisabled, isActive, ariaLabel }) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={classNames(styles.BtnHexagon, {
        [styles.isDisabled]: isDisabled,
      })}
      aria-label={ariaLabel}
    >
      <Hexagon isHovering={isHovering || isActive}>
        <div className={styles.inner}>{children}</div>
      </Hexagon>
    </button>
  )
}

BtnHexagon.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

export { BtnHexagon }
