import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container } from '@/components/Container'
import { SlideshowNav } from '../SlideshowNav'
import { SlideContent } from '../SlideContent'
import { Block } from '@/components/Block'
import * as styles from './Slideshow.styles.scss'

const Slideshow = forwardRef((props, ref) => {
  const { items, onNavigate, renderTransitions, currentIndex, hasBorders } =
    props

  return (
    <Block
      hasPaddingTop={false}
      hasPaddingBottom={!hasBorders}
      bgColor={hasBorders ? 'custom' : undefined}
    >
      <div
        className={classNames(styles.Slideshow, {
          [styles.hasBorders]: !!hasBorders,
        })}
        ref={ref}
      >
        <div className={styles.inner}>
          {hasBorders && <div className={styles.gradient} />}
          {renderTransitions}
          <Container indent={{ desktop: 2 }}>
            <div className={styles.sizer} />
          </Container>
          {items.map((item) => {
            return (
              <div key={item.uid} data-slide>
                {item.renderScene}
                <div
                  className={classNames(styles.slideContent, {
                    [styles[`${item.layout}Layout`]]: !!item.layout,
                  })}
                  data-slide-content
                >
                  <div className={styles.slideContent__inner}>
                    <Container>
                      <div className={styles.slideContent__grid}>
                        <div className={styles.slideContent__content}>
                          <SlideContent {...item} />
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            )
          })}
          <SlideshowNav
            items={items}
            currentIndex={currentIndex}
            onClickItem={onNavigate}
          />
        </div>
      </div>
    </Block>
  )
})

Slideshow.defaultProps = {
  hasBorders: true,
}

Slideshow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onNavigate: PropTypes.func,
  renderTransitions: PropTypes.node,
  currentIndex: PropTypes.number,
  hasBorders: PropTypes.bool,
}

export { Slideshow }
