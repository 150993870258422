// extracted by mini-css-extract-plugin
export var SlideContent = "b2_hm";
export var aniSpin = "b2_d";
export var btn = "b2_by";
export var description = "b2_bP";
export var hasBg = "b2_hp";
export var heading = "b2_bk";
export var heading__icon = "b2_hn";
export var icon = "b2_n";
export var icon__image = "b2_g3";
export var icon__subheading = "b2_hs";
export var icon__svg = "b2_hq";
export var icon__text = "b2_hr";
export var subheading = "b2_bL";