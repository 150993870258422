import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

/**
 * Get Image data from Storyblok
 *
 * See options here:
 * https://github.com/bejamas/gatsby-storyblok-image
 */

export const getFluidImage = (asset, options = {}) => {
  const filename = asset.filename

  if (filename) {
    const image = getFluidGatsbyImage(filename, options)
    const fileType = filename.split(/[#?]/)[0].split('.').pop().trim()

    if (image) {
      let attributes

      if (fileType === 'svg') {
        // If SVG, return raw path instead of srcSet
        attributes = { src: filename }
      } else {
        attributes = {
          src: image.src,
          srcset: image.srcSet || null,
          srcSetWebp: image.srcSetWebp,
          srcWebp: image.srcWebp,
          sizes: image.sizes || null,
        }
      }

      return {
        mediaType: 'image',
        fileType,
        alt: image.alt,
        width: image.width,
        height: image.height,
        aspectRatio: image.aspectRatio || null,
        ...attributes,
      }
    }
  }
  return null
}
