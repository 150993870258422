// extracted by mini-css-extract-plugin
export var HeaderTokens = "bB_fJ";
export var aniSpin = "bB_d";
export var description = "bB_bP";
export var heading = "bB_bk";
export var image01 = "bB_fK";
export var image02 = "bB_fL";
export var token = "bB_fQ";
export var token__description = "bB_fS";
export var token__image = "bB_fR";
export var token__subheading = "bB_fT";
export var token__subheading__inner = "bB_fV";
export var tokens = "bB_fN";
export var tokens__inner = "bB_fP";
export var top = "bB_c1";
export var top__text = "bB_fM";