import { useRecoilValue } from 'recoil'
import { loadingState } from '@/store'
import { useLocation } from '@reach/router'

export const usePageLoaded = () => {
  const { pathname: currentPathname } = useLocation()
  const { isLoaded, pathname } = useRecoilValue(loadingState)

  return isLoaded && currentPathname === pathname
}
