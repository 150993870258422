import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { Frame } from '@/components/Frame'
import { SocialIcon } from '@/components/SocialIcon'
import * as styles from './Btn.styles.scss'

const Btn = ({
  children,
  href,
  onClick,
  target,
  type,
  icon,
  isInline,
  isDisabled,
}) => {
  const [hoverState, setHoverState] = useState('initial')
  const ref = useRef()

  const handleMouseOver = () => setHoverState('over')
  const handleMouseOut = () => setHoverState('out')

  const attributes = {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    onClick: onClick,
    className: classNames(styles.Btn, {
      [styles.isDisabled]: isDisabled,
    }),
  }

  // const renderInner = (
  //   <span className={styles.inner}>
  //     <span className={styles.outline} />
  //     <span className={styles.bg} />
  //     <span className={styles.bgHover} />
  //     <span className={styles.content}>
  //       {children && <span className={styles.text}>{children}</span>}
  //     </span>
  //     <span className={styles.contentHover} aria-hidden="true">
  //       {children && <span className={styles.textHover}>{children}</span>}
  //     </span>
  //   </span>
  // )

  const renderInner = (
    <span className={styles.inner}>
      <Frame strokeWidth={1} isActive={hoverState === 'over'} />
      {children && <span className={styles.text}>{children}</span>}
      {icon && <SocialIcon variant={icon} className={styles.icon} />}
    </span>
  )

  // if href then return a link
  if (href) {
    return (
      <span ref={ref}>
        <Link to={href} target={target} {...attributes}>
          {renderInner}
        </Link>
      </span>
    )
  }

  if (isInline) {
    return (
      <span ref={ref} {...attributes}>
        {renderInner}
      </span>
    )
  }

  // default to a button element
  return (
    <button
      type={type} // eslint-disable-line
      ref={ref}
      disabled={isDisabled}
      {...attributes}
    >
      {renderInner}
    </button>
  )
}

Btn.defaultProps = {
  onClick: () => {},
  type: 'button',
}

Btn.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.oneOf(['discord', 'instagram', 'twitter']),
  isDisabled: PropTypes.bool,
  isInline: PropTypes.bool,
}

export { Btn }
