// extracted by mini-css-extract-plugin
export var Container = "Z_cB";
export var aniSpin = "Z_d";
export var gridRowD = "Z_cC";
export var gridRowM = "Z_cD";
export var indentD1 = "Z_cF";
export var indentD2 = "Z_cG";
export var indentD3 = "Z_cH";
export var indentD4 = "Z_cJ";
export var indentM1 = "Z_cK";
export var indentM2 = "Z_cL";
export var indentM3 = "Z_cM";
export var indentM4 = "Z_cN";