import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Expandable } from '@/components/Expandable'
import { Heading } from '@/components/Heading'
import * as styles from './Faqs.styles.scss'

const Faqs = ({ heading, items, nextBlock, prevBlock }) => {
  const hasCustomPaddingBottom = nextBlock === 'faqs'
  const hasCustomPaddingTop = prevBlock === null

  return (
    <Block
      hasPaddingBottom={!hasCustomPaddingBottom}
      hasPaddingTop={!hasCustomPaddingTop}
      className={classNames(styles.Faqs, {
        [styles.customPaddingBottom]: hasCustomPaddingBottom,
        [styles.customPaddingTop]: hasCustomPaddingTop,
      })}
    >
      <Container>
        <div className={styles.grid}>
          <Heading className={styles.heading} tag="h3" type="h3">
            {heading}
          </Heading>
          <div className={styles.items}>
            {items.map((item) => {
              return (
                <Expandable key={item.uid} header={item.question}>
                  {item.answer}
                </Expandable>
              )
            })}
          </div>
        </div>
      </Container>
    </Block>
  )
}

Faqs.propTypes = {
  heading: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
  nextBlock: PropTypes.string,
  prevBlock: PropTypes.string,
}

export { Faqs }
