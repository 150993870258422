import EventEmitter from '@/utils/EventEmitter'
import {
  DURATION_OUT,
  DURATION_IN,
  TRANSITION_STATES,
} from '@/constants/pageTransition'

const states = TRANSITION_STATES

const events = {
  TRANSITION: 'transition',
}

class TransitionService {
  canTransitionIn = false
  state = states.READY

  constructor() {
    Object.assign(this, EventEmitter)
  }

  get ready() {
    return this.state === states.READY
  }

  setState = (val) => {
    this.state = val
    this.emit(events.TRANSITION, val)
  }

  // Transition old page OUT
  out = (newRoute) => {
    return new Promise((resolve, reject) => {
      if (!this.ready || window.location.pathname === newRoute)
        return reject(false)

      this.setState(states.OUT)
      this.canTransitionIn = false

      setTimeout(() => {
        this.canTransitionIn = true
        resolve()
      }, DURATION_OUT)
    })
  };

  // Transition new page IN
  in = () => {
    if (!this.canTransitionIn || this.state !== states.OUT) return

    return new Promise((resolve) => {
      this.setState(states.IN)

      setTimeout(() => {
        this.setState(states.READY)
        resolve()
      }, DURATION_IN)
    })
  }
}

// and also the service
let service = new TransitionService()
service.events = events

export default service
