import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { MouseFrameAnimation } from '@/components/MouseFrameAnimation'
import { Img } from '@/components/Img'
import * as styles from './PlanetScene.styles.scss'

const PlanetScene = ({ media, isActive, isVisible }) => {
  const { device } = useRecoilValue(browserState)
  const scrollSequenceRef = useRef()

  return (
    <div className={styles.PlanetScene}>
      <div className={styles.scrollTrigger} ref={scrollSequenceRef} />
      <div className={styles.planet}>
        <div className={styles.planet__inner}>
          {device === 'desktop' ? (
            <MouseFrameAnimation
              {...media.animation}
              inViewRef={scrollSequenceRef}
              isActive={isActive}
              isVisible={isVisible}
            />
          ) : (
            <Img {...media.still} isFull objectFit="cover" />
          )}
        </div>
      </div>
    </div>
  )
}

PlanetScene.propTypes = {
  media: PropTypes.object,
  isVisible: PropTypes.bool,
}

export { PlanetScene }
