// extracted by mini-css-extract-plugin
export var Menu = "bw_fm";
export var aniSpin = "bw_d";
export var bg = "bw_df";
export var inner = "bw_v";
export var nav = "bw_b0";
export var navItem = "bw_fq";
export var navLink = "bw_fr";
export var nav__list = "bw_fp";
export var panel = "bw_fn";
export var planet = "bw_fs";