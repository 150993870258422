import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { RandomReveal } from '@/hooks/useRandomReveal' // 'react-random-reveal'
import { SCRAMBLED_CHARACTER_SET } from '@/constants/scrambledText'
import { useInView } from '@/hooks/useInView'
import { getTypeClasses } from '@/helpers/getTypeClasses'
import * as styles from './ScrambledText.styles.scss'

const ScrambledText = ({ text, type, className, duration }) => {
  const { device } = useRecoilValue(browserState)
  const ref = useRef()
  const isInView = useInView(ref)

  return (
    <div
      ref={ref}
      className={classNames(
        styles.ScrambledText,
        { ...getTypeClasses(type) },
        className
      )}
    >
      <div className={styles.original}>{text}</div>
      <div className={styles.scramble} aria-hidden="true">
        {device === 'desktop' ? (
          <RandomReveal
            isPlaying={isInView}
            resetOnPlay={true}
            duration={duration}
            characters={text}
            characterSet={SCRAMBLED_CHARACTER_SET}
            ignoreCharacterSet={[' ']}
            updateInterval={0.1} // Default is 0.065
          />
        ) : (
          text
        )}
      </div>
    </div>
  )
}

ScrambledText.defaultProps = {
  duration: 0.5,
}

ScrambledText.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  duration: PropTypes.number,
}

export { ScrambledText }
