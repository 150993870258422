import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './SpeciesScene.styles.scss'

const SpeciesScene = ({ media, isVisible }) => {
  const { device } = useRecoilValue(browserState)

  return (
    <div
      className={styles.SpeciesScene}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <div className={styles.background}>
        <div
          className={styles.background__inner}
          data-speed="0.8"
          data-background-media
        >
          <ResponsiveImg
            {...media?.background}
            isFull
            objectFit="cover"
            objectPosition="center"
            isLazy={false}
          />
        </div>
      </div>
      <div className={styles.image} data-foreground-media>
        <ResponsiveImg
          {...media?.foreground}
          isFull
          isLazy={false}
          objectFit="contain"
          objectPosition={
            device === 'desktop' ? 'right bottom' : 'center bottom'
          }
        />
      </div>
    </div>
  )
}

SpeciesScene.propTypes = {
  media: PropTypes.object,
}

export { SpeciesScene }
