import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { Media } from '@/components/Media'
import { Hexagon } from '@/components/Hexagon'
import { Icon } from '@/components/Icon'
import { RichText } from '@/components/RichText'
import * as styles from './Timeline.styles.scss'
import classNames from 'classnames'
// import dashedLineSrc from './images/dashed-line.svg'

const Timeline = ({ items }) => {
  return (
    <Block>
      <Container>
        <div className={styles.items}>
          {items?.map((item) => {
            const isActive = item.appearance === 'active'

            return (
              <div
                key={item.uid}
                className={classNames(styles.item, {
                  [styles.isActive]: isActive,
                  [styles.isInActive]: !isActive,
                })}
              >
                <div className={styles.iconLine}>
                  <div className={styles.line} />
                  {item.icon && (
                    <>
                      <div className={styles.item__iconWrap}>
                        <Hexagon isHovering={isActive}>
                          <div className={styles.hexagonInner}>
                            <Icon variant={item.icon} className={styles.icon} />
                          </div>
                        </Hexagon>
                      </div>
                      <div
                        className={classNames(
                          styles.line,
                          styles.iconLine__lineBottom
                        )}
                      />
                    </>
                  )}
                </div>
                <div className={styles.image} data-speed="0.9">
                  <div className={styles.image__inner}>
                    <div className={styles.media}>
                      <Media {...item.media} isFull objectFit="cover" />
                    </div>
                  </div>
                </div>
                <div className={styles.text} data-speed="0.9">
                  <div className={styles.text__inner}>
                    {item.subheading && (
                      <div className={styles.subheading}>
                        <Heading
                          tag="h3"
                          type={{ desktop: 's3', mobile: 's4' }}
                          className={styles.subheading__inner}
                        >
                          {item.subheading}
                        </Heading>
                      </div>
                    )}
                    {item.heading && (
                      <Heading tag="h4" type="h3">
                        {item.heading}
                      </Heading>
                    )}
                    {item.description && (
                      <RichText type="p1" className={styles.description}>
                        {item.description}
                      </RichText>
                    )}
                  </div>
                </div>
                <div className={styles.lineFooter}>
                  <div className={styles.line} />
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </Block>
  )
}

Timeline.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { Timeline }
