import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './HeaderPlanet.styles.scss'

const HeaderPlanet = ({ heading, preloadedImages, nextBlock }) => {
  const { headerPlanet } = preloadedImages

  return (
    <Block hasPaddingTop={false} hasPaddingBottom={nextBlock === 'timeline'}>
      <div className={styles.inner}>
        <div className={styles.image} data-speed="0.8">
          <ResponsiveImg {...headerPlanet} />
        </div>
        <Heading tag="h2" type="h1" className={styles.heading}>
          <Container>{heading}</Container>
        </Heading>
      </div>
    </Block>
  )
}

HeaderPlanet.propTypes = {
  heading: PropTypes.node,
  preloadedImages: PropTypes.object,
}

export { HeaderPlanet }
