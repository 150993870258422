import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@/components/Heading'
import { Icon } from '@/components/Icon'
import { Hexagon } from '@/components/Hexagon'
import { useTransition } from './hooks/useTransition'
import * as styles from './HexagonThumbs.styles.scss'

const HexagonThumbs = ({ isVisible, items }) => {
  const ref = useRef()

  useTransition(ref, {
    isVisible,
    selectors: {
      item: `.${styles.item}`,
    },
  })

  return (
    <div className={styles.HexagonThumbs} ref={ref}>
      {items.map((item) => {
        return (
          <div key={item.uid} className={styles.item}>
            <Hexagon>
              <div className={styles.item__inner}>
                <Icon variant={item.icon} className={styles.item__image} />
              </div>
            </Hexagon>
            <Heading
              tag="div"
              type={{ desktop: 'h6', mobile: 'h6' }}
              className={styles.item__label}
            >
              {item.title}
            </Heading>
          </div>
        )
      })}
    </div>
  )
}

HexagonThumbs.propTypes = {
  isVisible: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
}

export { HexagonThumbs }
