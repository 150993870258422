import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './SpeciesScene.styles.scss'

const SpeciesScene = ({ children, images }) => {
  const { featuredSpecies, featuredSpeciesBg } = images

  return (
    <div className={styles.SpeciesScene}>
      <div className={styles.children}>{children}</div>
      <div className={styles.bg} data-speed="0.8">
        <ResponsiveImg {...featuredSpeciesBg} isLazy={false} />
      </div>
      <div className={styles.species}>
        <div className={styles.species__inner} data-speed="0.9">
          <ResponsiveImg {...featuredSpecies} isLazy={false} />
        </div>
      </div>
    </div>
  )
}

SpeciesScene.propTypes = {
  children: PropTypes.node,
  images: PropTypes.object.isRequired,
}

export { SpeciesScene }
