import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { useInView } from '@/hooks/useInView'
import { useFrameAnimation } from '@/hooks/useFrameAnimation'
import * as styles from './MouseFrameAnimation.styles.scss'

const MouseFrameAnimation = ({
  width,
  height,
  aspectRatio,
  frames,
  maskImage,
  bgImage,
  inViewRef,
  isActive,
  isVisible,
  enableGlitchEffect, // enable automatic glitch and flicker effects on the canvas
  autoAnimateSpeed, // set default auto animation speed
  minAutoAnimateSpeed, // minimum speed for auto animation (0 to 1) (to avoid frame stutter at slow speeds)
  driveAutoAnimateWithMousePosition, // set animation speed to play forward or reverse depending on mouse position right or left
  useCanvasBoundsForMousePosition, // use position of mouse inside canvas bounds to control frame position
  mousePositionBoundsScale, // amount to scale mouse bounds to reach max autoAnimateSpeed (e.g. 0.5 reaches maximum speed halfway out from center of canvas element)
}) => {
  const isInView = useInView(inViewRef)
  const totalFrames = frames.length

  const lastTimeRef = useRef(0)

  const mouseXRef = useRef(0)

  const targetFrameRef = useRef(0)
  const curFrameRef = useRef(0)
  const curFrameDeltaRef = useRef(0)
  const curFrameVelocityRef = useRef(0)

  const curProgressRef = useRef(0)
  const targetAutoAnimateSpeedRef = useRef(0)
  const curAutoAnimateSpeedRef = useRef(0)
  const curAutoAnimateVelocityRef = useRef(0)

  const canvasBoundingRect = useRef(null)

  if (isVisible === undefined) {
    isVisible = true
  }

  const { windowWidth } = useRecoilValue(browserState)
  const { canvasRef, animationRef, renderAnimationFrame } = useFrameAnimation({
    frames,
    width,
    height,
    maskImage,
    bgImage,
    enableGlitchEffect,
  })

  // update bounding rect of canvas on resize
  const resizeCanvas = useCallback(() => {
    if (canvasRef.current) {
      canvasBoundingRect.current = canvasRef.current.getBoundingClientRect()
    }
  }, [canvasRef])

  // wrap modulo of negative and postive numbers consistently
  const moduloWrap = (value, mod) => {
    return ((value % mod) + mod) % mod
  }

  useEffect(() => {
    let tickerUpdate
    let onMouseMove

    let resizeObserver

    if (isInView && isActive) {
      // easing parameters for mouse tracking (non auto animating elements)
      const mouseTrackAccel = 0.003
      const mouseTrackDecel = 0.9

      // easing parameters for auto animating amount left and right
      const mouseAutoAnimateTrackAccel = 0.02
      const mouseAutoAnimateTrackDecel = 0.75

      // minimum frame difference delta and frame velocity to stop updating frames (avoiding slow stuttering update at slow speeds)
      const mouseTrackFramesMinDelta = 1
      const mouseTrackFramesMinVel = 0.2

      let frameRendered = false // track whether component has had first render after re-initialisation on in view or active

      // reset frame delta and frame velocity when changing active state or visibility of component (to avoid continuing previous motion when last displayed)
      lastTimeRef.current = new Date().getTime() / 1000
      curFrameVelocityRef.current = 0

      onMouseMove = (event) => {
        mouseXRef.current = event.x
      }

      tickerUpdate = () => {
        const curTime = new Date().getTime() / 1000

        // get delta time for consistent speed
        let deltaTime = curTime - lastTimeRef.current
        deltaTime = gsap.utils.clamp(0.25 / 60, 4 / 60, deltaTime) // clamp delta time multiplier to reasonable values to avoid large velocity bursts if frame hasn't updated in a while
        lastTimeRef.current = curTime

        let mouseXProgress = 0
        let newFrame

        // if using canvas bounds to determine mouse scrub position (rather than full window)
        if (useCanvasBoundsForMousePosition) {
          // if bounds have been determined
          if (canvasBoundingRect.current) {
            // set mouseXProgress to 0 to 1 from left to right bounds of the canvas
            mouseXProgress = mouseXRef.current - canvasBoundingRect.current.x
            mouseXProgress = mouseXProgress / canvasBoundingRect.current.width
          }
        } else {
          // otherwise use mouse position within screen
          mouseXProgress = mouseXRef.current / windowWidth
        }

        // scale mouse progress around center of bounds by inverse of mousePositionBoundsScale
        mouseXProgress = (mouseXProgress - 0.5) / (mousePositionBoundsScale || 1) + 0.5

        if (autoAnimateSpeed) {
          // for autoAnimate mode, convert mouse X progress to -1 to 1, from left and right of bounds
          mouseXProgress = mouseXProgress * 2 - 1
        }

        // if auto rotating then increment frame rotation
        if (autoAnimateSpeed) {
          let curAutoAnimateSpeed = 1

          if (driveAutoAnimateWithMousePosition) {
            // set target auto animation speed to mouse position -1 on left of bounds, 1 on right of bounds
            let targetAutoAnimateSpeed = gsap.utils.clamp(-1, 1, mouseXProgress)
            // clamp minimum animation speed left or right to minimum of minAutoAnimateSpeed
            if (targetAutoAnimateSpeed > 0) {
              targetAutoAnimateSpeed = gsap.utils.clamp(minAutoAnimateSpeed, 1, targetAutoAnimateSpeed)
            } else {
              targetAutoAnimateSpeed = gsap.utils.clamp(-1, -minAutoAnimateSpeed, targetAutoAnimateSpeed)
            }

            // set target auto animate speed to ease to (to avoid changing animation direction sharply)
            targetAutoAnimateSpeedRef.current = targetAutoAnimateSpeed

            // if haven't rendered frame yet after re-initialising when active state of component is changed
            if (!frameRendered) {
              // reset animation speed and speed velocity
              curAutoAnimateSpeedRef.current = targetAutoAnimateSpeedRef.current
              curAutoAnimateVelocityRef.current = 0
            } else {
              // get target animation speed difference delta and ease auto animation velocity towards target
              const curAutoAnimateSpeedDelta = targetAutoAnimateSpeedRef.current - curAutoAnimateSpeedRef.current
              curAutoAnimateVelocityRef.current = curAutoAnimateVelocityRef.current * mouseAutoAnimateTrackDecel + curAutoAnimateSpeedDelta * mouseAutoAnimateTrackAccel
              curAutoAnimateSpeedRef.current += curAutoAnimateVelocityRef.current
            }
            frameRendered = true

            curAutoAnimateSpeed = curAutoAnimateSpeedRef.current
          }
          curAutoAnimateSpeed /= autoAnimateSpeed // scale animation by animation speed parameter
          curAutoAnimateSpeed *= deltaTime // multiply speed by time between frames to keep consistent

          curProgressRef.current += curAutoAnimateSpeed

          curFrameRef.current = moduloWrap(curProgressRef.current, 1) * totalFrames

          newFrame = Math.floor(curFrameRef.current)
        } else {
          // if not rotating then tween frame rotation to mouse position

          // set target frame to clamped mouse progress multiplied by number of frames
          targetFrameRef.current = gsap.utils.clamp(0, 1, mouseXProgress) * totalFrames

          // get target frame difference delta and ease velocity towards target
          curFrameDeltaRef.current = targetFrameRef.current - curFrameRef.current
          curFrameVelocityRef.current = curFrameVelocityRef.current * mouseTrackDecel + curFrameDeltaRef.current * mouseTrackAccel

          // stop updating frames if target frame difference delta and frame velocity are less than given thresholds
          if (Math.abs(curFrameDeltaRef.current) < mouseTrackFramesMinDelta && Math.abs(curFrameVelocityRef.current) < mouseTrackFramesMinVel) {
            curFrameVelocityRef.current = 0
          }
          curFrameRef.current += curFrameVelocityRef.current

          newFrame = Math.floor(curFrameRef.current)
        }

        animationRef.current = { frame: newFrame }
        renderAnimationFrame()
      }

      // if (not auto animating or driving auto animate with mouse position) and using canvas region for mouse position then monitor canvas bounding region
      if ((!autoAnimateSpeed || driveAutoAnimateWithMousePosition) && useCanvasBoundsForMousePosition && canvasRef.current) {
        resizeObserver = new ResizeObserver(resizeCanvas)
        resizeObserver.observe(canvasRef.current)
        resizeCanvas()
      }

      window.addEventListener('mousemove', onMouseMove)
      gsap.ticker.add(tickerUpdate)
    }

    return () => {
      resizeObserver?.disconnect()

      window.removeEventListener('mousemove', onMouseMove)
      gsap.ticker.remove(tickerUpdate)
    }
  }, [
    windowWidth,
    isInView,
    totalFrames,
    isActive,
    animationRef,
    renderAnimationFrame,
  ])

  return (
    <div
      className={styles.MouseFrameAnimation}
      style={{ paddingTop: !!aspectRatio && `${100 / aspectRatio}%`, display: isVisible ? "block" : "none" }}
    >
      <canvas className={styles.canvas} ref={canvasRef} />
    </div>
  )
}

MouseFrameAnimation.defaultProps = {
  aspectRatio: 1,
  isActive: true,
}

MouseFrameAnimation.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  frames: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
}

export { MouseFrameAnimation }
