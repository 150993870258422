import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import useCollapse from 'react-collapsed'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { RichText } from '@/components/RichText'
import { Heading } from '@/components/Heading'
import * as styles from './Expandable.styles.scss'

gsap.registerPlugin(ScrollTrigger)

const Expandable = ({ header, children, defaultExpanded }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded,
    onCollapseEnd: () => ScrollTrigger.refresh(),
    onExpandEnd: () => ScrollTrigger.refresh(),
  })

  return (
    <div
      className={classNames(styles.Expandable, {
        [styles.isExpanded]: !!isExpanded,
      })}
    >
      <button {...getToggleProps()} className={styles.header}>
        <Heading className={styles.header__text} type="s3" tag="div">
          {header}
        </Heading>
        <div className={styles.icon}>
          <div className={styles.icon__line} />
          <div className={styles.icon__line} />
        </div>
      </button>
      <section {...getCollapseProps()}>
        <RichText type="p1" className={styles.body}>
          {children}
        </RichText>
      </section>
    </div>
  )
}

Expandable.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
}

export { Expandable }
