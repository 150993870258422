import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useRecoilState } from 'recoil'
import { scrollLockState } from '@/store'

/**
 * Hook wrapper for scroll body-scroll-lock
 *
 * @usage
 * const scrollRef = useRef()
 * const { lockScroll, unlockScroll } = useScrollLock(scrollRef)
 */

export const useScrollLock = (ref) => {
  const [isScrollLocked, setIsScrollLocked] = useRecoilState(scrollLockState)

  const lockScroll = () => {
    if (typeof window === 'undefined') return

    if (!isScrollLocked) {
      console.log('🔒 lock scroll')
      disableBodyScroll(ref.current, { reserveScrollBarGap: true })
      setIsScrollLocked(true)
    }
  }
  const unlockScroll = () => {
    if (isScrollLocked) {
      console.log('🔒 unlock scroll')
      enableBodyScroll(ref.current)
      setIsScrollLocked(false)
    }
  }

  return { lockScroll, unlockScroll }
}
