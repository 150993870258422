// extracted by mini-css-extract-plugin
export var Text = "H_bs";
export var aniSpin = "H_d";
export var body = "H_bx";
export var btn = "H_by";
export var defaultPadding = "H_bt";
export var grid = "H_bj";
export var inner = "H_v";
export var main = "H_c";
export var reducedPadding = "H_bv";
export var side = "H_bw";