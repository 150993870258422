import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import * as styles from './LargeTextList.styles.scss'

const LargeTextList = ({ items }) => {
  return (
    <Block>
      <Container indent={{ desktop: 2 }}>
        {!!items?.length && (
          <ul className={styles.inner}>
            {items.map((item) => {
              return (
                <li key={item.uid} className={styles.item}>
                  <Heading tag="p" type="h4">
                    {item.heading}
                  </Heading>
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </Block>
  )
}

LargeTextList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { LargeTextList }
