import { atom } from 'recoil'

/**
 * Store smooth scroll state
 *
 * @usage
 * const [isSmoothScroll, setIsSmoothScroll] = useRecoilState(smoothScrollState)
 */

export const smoothScrollState = atom({
  key: 'smoothScrollState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
