import { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Trigger Outro transition on scroll
 * Note the fixed positioning is handled with `useFixedPosition()`
 */

export const useTransition = (ref, { trigger }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const $root = ref.current
    const $trigger = $root.querySelector(trigger)

    const scrollTrigger = ScrollTrigger.create({
      trigger: $trigger,
      start: 'top 25%',
      end: 'bottom bottom',
      onToggle: (self) => {
        setIsVisible(self.isActive)
      },
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [ref, trigger])

  return isVisible
}

// import { useEffect } from 'react'
// import { gsap } from 'gsap'
// import ScrollTrigger from 'gsap/ScrollTrigger'

// gsap.registerPlugin(ScrollTrigger)

// /**
//  * Psuedo fixed positioning using ScrollTrigger pinning
//  */

// export const useFixedPosition = (ref, { selector }) => {
//   useEffect(() => {
//     const $root = ref.current
//     const $element = $root.querySelectorAll(selector)

//     const scrollTrigger = ScrollTrigger.create({
//       trigger: $root,
//       start: 'top top',
//       end: 'bottom top',
//       pin: $element,
//       pinSpacing: false,
//       onToggle: (self) => {
//         // gsap.to($element, {
//         //   autoAlpha: self.isActive ? 1 : 0,
//         //   duration: 0.2,
//         // })
//       },
//     })

//     return () => {
//       scrollTrigger.kill()
//     }
//   }, [ref, selector])
// }
