import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { getAspectRatio } from '@/helpers/getAspectRatio'
import { RichText } from '@/components/RichText'
import { Img } from '@/components/Img'
import { Link } from '@/components/Link'
import logoSrc from './images/logo-web3.webp'
import * as styles from './HeaderAbout.styles.scss'

const HeaderAbout = ({ heading, image, bodyText }) => {
  return (
    <Block
      hasPaddingTop={false}
      hasPaddingBottom={false}
      className={styles.HeaderAbout}
      bgColor="custom"
    >
      {image && (
        <div className={styles.image} data-speed="0.8">
          <ResponsiveImg
            {...image}
            isFull
            objectFit="contain"
            objectPosition="center top"
            isLazy={false}
          />
        </div>
      )}
      <div className={styles.text}>
        <div className={styles.text__inner}>
          <Container indent={{ desktop: 2 }}>
            <Heading tag="h2" type={{ desktop: 'h1', mobile: 'h2' }}>
              {heading}
            </Heading>
            <div className={styles.secondary}>
              {bodyText && (
                <RichText type="p1" className={styles.bodyText}>
                  {bodyText}
                </RichText>
              )}
              <div className={styles.logo}>
                <Link to="https://web3studio.co/" target="_blank">
                  <div className={styles.logo__img}>
                    <Img
                      src={logoSrc}
                      alt="Web3 Studio"
                      aspectRatio={getAspectRatio(850, 96)}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </Block>
  )
}

HeaderAbout.propTypes = {
  heading: PropTypes.node,
  image: PropTypes.object,
  bodyText: PropTypes.node,
}

export { HeaderAbout }
