import { atom } from 'recoil'

/**
 * Store image loading state state here
 *
 * @usage
 * const [isImagesLoaded, setIsImagesLoaded] = useRecoilValue(imagesLoadedState)
 */

export const imagesLoadedState = atom({
  key: 'imagesLoadedState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
