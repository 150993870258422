import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import LogoSvg from '@/assets/logo.inline.svg'
import LogoStackedSvg from '@/assets/logo-stacked.inline.svg'
import * as styles from './Intro.styles.scss'

const Intro = ({ children, isVisible }) => {
  const { device } = useRecoilValue(browserState)
  const Logo = device === 'desktop' ? LogoSvg : LogoStackedSvg

  return (
    <div className={styles.intro} style={{ opacity: isVisible ? 1 : 0 }}>
      <Container indent={{ desktop: 2 }}>
        <h1>
          <Logo className={styles.intro__logo} aria-label="Star Origins" />
        </h1>
        {children && (
          <RichText type="s2" className={styles.intro__text}>
            {children}
          </RichText>
        )}
      </Container>
    </div>
  )
}

Intro.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
}

export { Intro }
