import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { loadingState, loadingProgressState } from '@/store'
import { LogoIcon } from '@/components/LogoIcon'
// import { useScrollLock } from '@/hooks/useScrollLock'
import { useTransition } from './Preloader.gsap'
import * as styles from './Preloader.styles.scss'

const Preloader = ({ children }) => {
  const loadingProgress = useRecoilValue(loadingProgressState)
  const { isLoaded } = useRecoilValue(loadingState)
  // const firstLoadRef = useRef(true)
  const ref = useRef()
  const scrollRef = useRef()
  // const { lockScroll, unlockScroll } = useScrollLock(scrollRef)

  useTransition(ref, {
    isLoading: !isLoaded,
    selectors: {
      loader: `.${styles.loader}`,
      icon: `.${styles.icon}`,
      children: `.${styles.children}`,
    },
  })

  // useEffect(() => {
  //   if (!isLoaded && firstLoadRef.current) {
  //     lockScroll()
  //     firstLoadRef.current = false
  //   } else {
  //     unlockScroll()
  //   }
  // }, [isLoaded])

  return (
    <div className={styles.Preloader} ref={ref}>
      <div className={styles.loader} ref={scrollRef}>
        <div className={styles.icon} aria-label="Loading">
          <div className={styles.icon__inner}>
            <LogoIcon />
          </div>
        </div>
        <div className={styles.progress}>{loadingProgress?.percentage}</div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  )
}

Preloader.propTypes = {
  children: PropTypes.node,
}

export { Preloader }
