import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as styles from './IconMenu.styles.scss'

const IconMenu = ({ isActive }) => {
  return (
    <span
      className={classNames(styles.IconMenu, { [styles.isActive]: isActive })}
    >
      <span className={styles.menu}>
        <span className={styles.menu__line} />
        <span className={styles.menu__line} />
      </span>
      <span className={styles.close}>
        <span className={styles.close__line} />
        <span className={styles.close__line} />
      </span>
    </span>
  )
}

IconMenu.defaultProps = {
  isActive: false,
}

IconMenu.propTypes = {
  isActive: PropTypes.bool,
}

export { IconMenu }
