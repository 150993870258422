import { getAspectRatio } from '@/helpers/getAspectRatio'

/**
 * Get Cta Button helper for processing Cta button types
 */

export const getCtaButton = (button) => {
  // If array, then use the first item
  if (Array.isArray(button) && !!button.length) {
    button = button[0]
  }

  if (button.component === 'video_lightbox') {
    const width = parseInt(button.video_width) || 1600
    const height = parseInt(button.video_height) || 900

    return {
      type: button.component,
      title: button.title,
      video: button.video_url
        ? {
            url: button.video_url,
            width,
            height,
            aspectRatio: getAspectRatio(width, height),
          }
        : null,
    }
  }

  console.warn(`Unsupported cta type: ${button.component}`)
  return null
}
