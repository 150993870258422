import React, { useRef } from 'react'
import PropTypes from 'prop-types'
// import ReactTooltip from 'react-tooltip'
import { useInView } from '@/hooks/useInView'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { HexagonGrid } from '@/components/HexagonGrid'
import { Icon } from '@/components/Icon'
import * as styles from './IconsAndText.styles.scss'

const IconsAndText = ({ heading, items, introText, bodyText }) => {
  const ref = useRef()
  const isInView = useInView(ref)

  return (
    <Block>
      <Container indent={{ desktop: 2 }}>
        {heading && (
          <Heading
            className={styles.heading}
            tag="h3"
            type={{ desktop: 'h1', mobile: 'h2' }}
          >
            {heading}
          </Heading>
        )}
      </Container>
      <Container>
        <div className={styles.grid}>
          {!!items?.length && (
            <div className={styles.items} ref={ref}>
              <HexagonGrid
                size="medium"
                delay={0}
                isVisible={isInView}
                items={items.map((item) => {
                  return {
                    children: (
                      <div>
                        <div className={styles.icon}>
                          <Icon variant={item.icon} className={styles.image} />
                        </div>
                      </div>
                    ),
                    uid: item.uid,
                    // onClick: () => {},
                    // children: (
                    //   <div>
                    //     <div
                    //       className={styles.icon}
                    //       data-tip
                    //       data-for={item.uid}
                    //     >
                    //       <Icon variant={item.icon} className={styles.image} />
                    //     </div>
                    //     <ReactTooltip id={item.uid} arrowColor="transparent">
                    //       {item.title}
                    //     </ReactTooltip>
                    //   </div>
                    // ),
                  }
                })}
              />
            </div>
          )}
          <div className={styles.text}>
            {introText && (
              <RichText type="s1" className={styles.introText}>
                {introText}
              </RichText>
            )}
            {bodyText && (
              <RichText type="p1" className={styles.bodyText}>
                {bodyText}
              </RichText>
            )}
          </div>
        </div>
      </Container>
    </Block>
  )
}

IconsAndText.propTypes = {
  heading: PropTypes.node,
  introText: PropTypes.node,
  bodyText: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
}

export { IconsAndText }
