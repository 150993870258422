import { useEffect, useRef } from 'react'
import { gsap, Power3 } from 'gsap'

export const useToggleTransition = (ref, { isOpen, selectors }) => {
  const { panel, nav } = selectors
  const firstRender = useRef(true)

  useEffect(() => {
    const $root = ref.current
    const $panel = $root.querySelector(panel)
    const $nav = $root.querySelectorAll(nav)

    const timeline = gsap.timeline({})

    if (firstRender.current) {
      gsap.set($panel, { opacity: 0 })
      gsap.set($nav, { opacity: 0 })
    }

    firstRender.current = false

    if (isOpen) {
      // transition in
      timeline.set($root, { display: 'block' })
      timeline.to(
        $panel,
        {
          opacity: 1,
          duration: 0.6,
          ease: Power3.easeOut,
        },
        'main'
      )
      timeline.to(
        $nav,
        {
          opacity: 1,
          duration: 0.8,
          ease: Power3.easeOut,
          stagger: 0.1,
        },
        'main'
      )
    } else {
      // transition out

      timeline.to(
        $panel,
        {
          opacity: 0,
          duration: 0.6,
          ease: Power3.easeOut,
        },
        'main'
      )
      timeline.to(
        $nav,
        {
          opacity: 0,
          duration: 0.8,
          ease: Power3.easeOut,
          stagger: 0.1,
        },
        'main'
      )
      timeline.set($root, { display: 'none' })
    }

    return () => {
      if (timeline) timeline.kill()
    }
  }, [ref, isOpen, panel, nav])
}
