import { useEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const useInnerScroll = (ref, { selectors, isEnabled }) => {
  const { element, trigger } = selectors

  useEffect(() => {
    const $root = ref.current
    const $element = $root.querySelector(element)
    const $trigger = $root.querySelector(trigger)

    let scrollTrigger

    if (isEnabled) {
      scrollTrigger = gsap.fromTo(
        $element,
        { yPercent: -100 },
        {
          yPercent: 0,
          ease: 'none',
          scrollTrigger: {
            trigger: $trigger,
            start: 'top bottom',
            end: 'top top',
            scrub: true,
          },
        }
      )
    }

    return () => {
      scrollTrigger?.kill()
    }
  }, [ref, isEnabled, element, trigger])
}
