import { THEMES } from '@/constants'

/**
 * Returns style object containing --theme-color
 *
 * @param {String} theme
 */

export const getThemeCssVar = (theme) => {
  if (!theme) return {}

  return {
    '--theme-color': THEMES[theme]?.color,
  }
}
