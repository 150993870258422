import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Block } from '@/components/Block'
import { VideoThumbLink } from '@/components/VideoThumbLink'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { ScrollDown } from '@/components/ScrollDown'

import { HeaderHomePlanet } from './components/HeaderHomePlanet'
import { Intro } from './components/Intro'
import { Outro } from './components/Outro'

import { useFixedPosition } from './hooks/useFixedPosition'
import { useIntroTransition } from './hooks/useIntroTransition'
import { useFooterTransition } from './hooks/useFooterTransition'
import { useOutroTransition } from './hooks/useOutroTransition'
import * as styles from './HeaderHome.styles.scss'

const HeaderHome = ({
  description,
  intro,
  scrollLabel,
  video,
  images,
  icons,
}) => {
  const { dust, nebula, planet } = images
  const { device } = useRecoilValue(browserState)
  const scrollToRef = useRef()
  const ref = useRef()
  const hasScrollEffects = device === 'desktop'

  useFixedPosition(ref, {
    isEnabled: hasScrollEffects,
    selector: `.${styles.fixedFooter}, .${styles.fixedBack}`,
  })
  useFooterTransition(ref, {
    isEnabled: hasScrollEffects,
    selector: `.${styles.footer}`,
  })
  const isIntroVisible = useIntroTransition(ref, {
    isEnabled: hasScrollEffects,
    trigger: `.${styles.middle}`,
  })
  const isOutroVisible = useOutroTransition(ref, {
    isEnabled: hasScrollEffects,
    trigger: `.${styles.bottom}`,
  })

  return (
    <>
      <Block
        className={classNames(styles.HeaderHome, {
          [styles.hasScrollEffects]: hasScrollEffects,
        })}
        hasPaddingTop={false}
        ref={ref}
      >
        <div className={styles.fixedBack}>
          <Intro isVisible={isIntroVisible}>{intro}</Intro>
        </div>
        <div className={styles.fixedFooter}>
          <div className={styles.footer}>
            {video && <VideoThumbLink {...video} />}
            <ScrollDown scrollToRef={scrollToRef}>{scrollLabel}</ScrollDown>
          </div>
        </div>
        <div className={styles.nebula} data-speed="0.8">
          <ResponsiveImg
            {...nebula}
            className={styles.nebula__img}
            isLazy={false}
          />
        </div>
        <div className={styles.top} />
        <div className={styles.middle}>
          <div className={styles.scrollToPoint} ref={scrollToRef} />
          <HeaderHomePlanet planet={planet} dust={dust} />
        </div>
        <div className={styles.bottom} />
      </Block>
      <Outro
        isVisible={isOutroVisible}
        isFixedPosition={hasScrollEffects}
        description={description}
        icons={icons}
      />
    </>
  )
}

HeaderHome.propTypes = {
  description: PropTypes.arrayOf(PropTypes.object),
  intro: PropTypes.node,
  scrollLabel: PropTypes.string,
  video: PropTypes.object,
  images: PropTypes.object,
  icons: PropTypes.arrayOf(PropTypes.object),
}

export { HeaderHome }
