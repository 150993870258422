import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { usePageLoaded } from '@/hooks/usePageLoaded'
import { useSlideshowTransition } from '../../hooks/useSlideshowTransition'
import { TransitionCanvas } from '../TransitionCanvas'
import { PlanetScene } from '../PlanetScene'
import { Slideshow } from '../Slideshow'

import * as styles from './SlideshowPlanets.styles.scss'

const SlideshowPlanets = ({ items }) => {
  const [isEffectTransitioning, setIsEffectTransitioning] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleClickThumb = (index) => setCurrentIndex(index)
  const ref = useRef()
  const isPageLoaded = usePageLoaded()

  const { isTransitioning, foregroundSrcA, foregroundSrcB } =
    useSlideshowTransition(ref, {
      currentIndex,
      selectors: { foregroundMedia: `canvas` },
    })

  return (
    <Slideshow
      ref={ref}
      onNavigate={handleClickThumb}
      currentIndex={currentIndex}
      renderTransitions={
        <div className={styles.transitionCanvas}>
          <div className={styles.transitionCanvas__inner}>
            <TransitionCanvas
              isTransitioning={isTransitioning}
              isEffectTransitioning={isEffectTransitioning}
              setIsEffectTransitioning={setIsEffectTransitioning}
              imageSrcA={foregroundSrcA}
              imageSrcB={foregroundSrcB}
              autoPadding={true}
              inViewStart="25% bottom" // set in view state to hidden when planet carousel is off screen (transition canvas is cropped lower within it)
            />
          </div>
        </div>
      }
      items={items.map((item, index) => {
        return {
          ...item,
          renderScene: isPageLoaded ? (
            <PlanetScene
              media={item.media}
              isActive={currentIndex === index && !isEffectTransitioning}
              isVisible={!isEffectTransitioning}
            />
          ) : null,
        }
      })}
    />
  )
}

SlideshowPlanets.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { SlideshowPlanets }
