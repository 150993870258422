import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { HexagonGrid } from '@/components/HexagonGrid'
import ExternalSvg from '@/assets/external-link.inline.svg'
import * as styles from './Logos.styles.scss'

const Logos = ({ heading, logos, subheading, description }) => {
  return (
    <Block>
      <Container>
        {subheading && (
          <Heading tag="h2" type="s4" className={styles.subheading}>
            {subheading}
          </Heading>
        )}
        {heading && (
          <Heading
            tag="h3"
            type={{ desktop: 'h2', mobile: 'h3' }}
            className={styles.heading}
          >
            {heading}
          </Heading>
        )}
        {description && (
          <RichText type="p1" className={styles.description}>
            {description}
          </RichText>
        )}
      </Container>
      {!!logos?.length && (
        <div className={styles.logos}>
          <HexagonGrid
            size="large"
            items={logos.map((item) => {
              const hasLink = !!item.link?.url
              const attributes = hasLink
                ? {
                    'data-tip': '',
                    'data-for': item.uid,
                  }
                : {}

              return {
                uid: item.uid,
                href: item.link?.url,
                target: item.link?.target,
                children: (
                  <div className={styles.logo} {...attributes}>
                    <img
                      src={item.imageSrc}
                      alt={item.title}
                      className={styles.logo__img}
                    />
                    {hasLink && (
                      <ReactTooltip id={item.uid} arrowColor="transparent">
                        <span className={styles.toolTipWrap}>
                          Visit {item.title}
                          <ExternalSvg className={styles.toolTip__icon} />
                        </span>
                      </ReactTooltip>
                    )}
                  </div>
                ),
              }
            })}
          />
        </div>
      )}
    </Block>
  )
}

Logos.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.object),
  heading: PropTypes.node,
  subheading: PropTypes.node,
  description: PropTypes.node,
}

export { Logos }
