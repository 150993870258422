import React from 'react'
import { useRecoilState } from 'recoil'
import { menuState } from '@/store'
import { IconMenu } from '@/components/IconMenu'
import { MENU_ID } from '@/constants'
import * as styles from './BtnMenu.styles.scss'

const BtnMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const handleClick = () => setIsMenuOpen((prev) => !prev)

  return (
    <button
      className={styles.BtnMenu}
      onClick={handleClick}
      aria-label={`${isMenuOpen ? 'Close' : 'Open'} Menu`}
      aria-expanded={isMenuOpen}
      aria-controls={MENU_ID} /* References menu id attribute */
    >
      <IconMenu isActive={isMenuOpen} />
    </button>
  )
}

export { BtnMenu }
