import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { Frame } from '@/components/Frame'
import { Media } from '@/components/Media'
import * as styles from './FeatureRows.styles.scss'

const FeatureRows = ({ items }) => {
  return (
    <Block>
      <Container indent={{ desktop: 2 }}>
        {items?.length && (
          <div className={styles.rows}>
            {items.map((item) => {
              return (
                <div key={item.uid} className={styles.row}>
                  <div className={styles.head}>
                    <div>
                      <Heading
                        tag="h3"
                        type={{ desktop: 'h3', mobile: 'h4' }}
                        className={styles.heading}
                      >
                        {item.heading}
                      </Heading>
                      {item.description && (
                        <RichText type="p1" className={styles.description}>
                          {item.description}
                        </RichText>
                      )}
                    </div>
                  </div>
                  <div className={styles.body}>
                    <div className={styles.body__inner}>
                      <Media
                        {...item.media}
                        isFull
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </div>
                    <Frame cornerWidth={10} cornerHeight={10} />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </Container>
    </Block>
  )
}

FeatureRows.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { FeatureRows }
