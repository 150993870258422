import { atom } from 'recoil'

/**
 * Store scroll lock state
 *
 * @usage
 * const [isScrollLocked, setIsScrollLocked] = useRecoilValue(scrollLockState)
 */

export const scrollLockState = atom({
  key: 'scrollLockState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
