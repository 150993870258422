import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import * as styles from './PeopleList.styles.scss'

const PeopleList = ({ heading, items }) => {
  return (
    <Block>
      <Container indent={{ desktop: 2 }}>
        {heading && (
          <Heading tag="h3" type="h4" className={styles.heading}>
            {heading}
            <div className={styles.divider} />
          </Heading>
        )}
        {!!items?.length && (
          <ul className={styles.items}>
            {items.map((item) => {
              return (
                <li key={item.uid} className={styles.item}>
                  <div>{item.name}</div>
                  <div className={styles.role}>{item.role}</div>
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </Block>
  )
}

PeopleList.propTypes = {
  heading: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
}

export { PeopleList }
