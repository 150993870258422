import { useEffect, useRef, useCallback } from 'react'
// import { useRecoilValue } from 'recoil'
import { gsap } from 'gsap'
import { usePageLoaded } from '@/hooks/usePageLoaded'

// https://codepen.io/osublake/pen/VwaKMpw/2152a28cffe2c2c0cca8a3e47f7b21c6?editors=0010
// https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258

export const useFrameAnimation = ({
  frames,
  width,
  height,
  maskImage,
  bgImage,
  enableGlitchEffect,
}) => {
  const GLITCH_DELAY = 0.015
  const GLITCH_DELAY_VARATION = 1
  const GLITCH_DELAY_VARATION_POW = 8.0
  const GLITCH_DURATION = 0.05
  const GLITCH_DURATION_VARITATION = 0.7
  const GLITCH_DURATION_VARITATION_POW = 2

  const GLITCH_FLICKER_ALPHA = true
  const GLITCH_FLICKER_ALPHA_AMOUNT = 0.3

  const GLITCH_LINE_HEIGHT = 14
  const GLITCH_OFFSET_VERT_AMOUNT = 70
  const GLITCH_OFFSET_VERT_AMOUNT_POW = 2.0
  const GLITCH_OFFSET_HORIZ_AMOUNT = 55
  const GLITCH_OFFSET_HORIZ_AMOUNT_PROGRESS_POW = 1.3

  const startTimeRef = useRef(-1)
  const glitchStartTimeRef = useRef(0)
  const glitchEndTimeRef = useRef(0)
  const glitchRect = useRef({})
  const glitchOffset = useRef({})

  const curFrameImage = useRef(null)
  const isImagesLoaded = usePageLoaded()
  const backgroundHasRendered = useRef(false)
  const canvasRef = useRef(null)
  const contextRef = useRef(null)
  const animationRef = useRef({ frame: 0, opacity: 1 })
  const imgs = useRef([])

  const maskRef = useRef(null)
  const maskLoaded = useRef(false)

  const bgImageRef = useRef(null)
  const bgImageLoaded = useRef(false)

  // set next glitch time
  const setNextGlitch = () => {
    const curTime = new Date().getTime() / 1000
    glitchStartTimeRef.current =
      curTime +
      (GLITCH_DELAY +
        Math.pow(
          Math.random() * GLITCH_DELAY_VARATION,
          GLITCH_DELAY_VARATION_POW
        ))
    glitchEndTimeRef.current =
      glitchStartTimeRef.current +
      (GLITCH_DURATION +
        Math.pow(
          Math.random() * GLITCH_DURATION_VARITATION,
          GLITCH_DURATION_VARITATION_POW
        ))

    glitchRect.current = {
      x: 0,
      y: Math.random(),
      width: 1,
      height: Math.random(),
    }
    glitchOffset.current = {
      x: (Math.random() - 0.5) * 2,
      y:
        Math.pow(Math.random(), GLITCH_OFFSET_VERT_AMOUNT_POW) *
        (Math.random() > 0.5 ? 1 : -1),
    }
  }

  const renderAnimationFrame = useCallback(() => {
    const context = contextRef.current
    const canvas = canvasRef.current

    if (context && canvas) {
      const backgroundLoaded = bgImage && bgImageLoaded.current

      const newFrameImage = imgs.current[animationRef.current?.frame]
      const newFrameLoaded = newFrameImage?.complete

      if (newFrameImage && newFrameLoaded) {
        curFrameImage.current = newFrameImage
      }
      if (!curFrameImage.current && imgs.current[0]) {
        curFrameImage.current = imgs.current[0]
      }
      const curFrameLoaded = curFrameImage.current?.complete

      // render if current frame available or background defined and loaded but not yet rendered - allowing lazy load of frames and keep rendering last available frame (including glitch effects if enabled)
      if (
        curFrameLoaded ||
        (backgroundLoaded && !backgroundHasRendered.current)
      ) {
        context.save()

        // Set ratio
        const { devicePixelRatio: ratio = 1 } = window
        context.scale(ratio, ratio)

        // Scale canvas
        let { width: elementWidth, height: elementHeight } =
          canvas.getBoundingClientRect()

        // if element not on screen set canvas size to bg image size if loaded
        elementWidth ||=
          (imgs && imgs.current && imgs.current[0] && imgs.current[0].width) ||
          0
        elementHeight ||=
          (imgs && imgs.current && imgs.current[0] && imgs.current[0].height) ||
          0

        elementWidth ||=
          (bgImage && bgImageLoaded.current && bgImageRef.current.width) || 0
        elementHeight ||=
          (bgImage && bgImageLoaded.current && bgImageRef.current.height) || 0

        if (canvas.width !== elementWidth || canvas.height !== elementHeight) {
          canvas.width = elementWidth * ratio
          canvas.height = elementHeight * ratio
        }

        // Clear canvas
        const { width: contextWidth, height: contextHeight } = context.canvas

        context.clearRect(0, 0, contextWidth, contextHeight)

        const previousGlobalCompositeOperation =
          context.globalCompositeOperation

        let previousGlobalAlpha

        if (curFrameLoaded) {
          if (maskImage) {
            if (maskLoaded.current) {
              // draw alpha'd mask if loaded
              context.drawImage(
                maskRef.current,
                0,
                0,
                width,
                height,
                0,
                0,
                contextWidth,
                contextHeight
              )
            }
            context.globalCompositeOperation = 'source-in' // set global composite operation to draw image within mask
          }

          // flicker alpha of image sequence if glitch enabled
          if (enableGlitchEffect && GLITCH_FLICKER_ALPHA) {
            previousGlobalAlpha = context.globalAlpha
            context.globalAlpha =
              1 - Math.random() * GLITCH_FLICKER_ALPHA_AMOUNT
          }

          // Draw image, stretched over canvas element
          // https://stackoverflow.com/a/23104764
          context.drawImage(
            curFrameImage.current,
            0,
            0,
            width,
            height,
            0,
            0,
            contextWidth,
            contextHeight
          )

          // if glitch effect enabled, add occasional glitches
          if (enableGlitchEffect) {
            const curTime = new Date().getTime() / 1000

            // set first glitch if start time not set
            if (startTimeRef.current === -1) {
              startTimeRef.current = curTime
              setNextGlitch()
            }

            if (curTime > glitchStartTimeRef.current) {
              // if during glitch start and end time
              if (curTime < glitchEndTimeRef.current) {
                const glitchProgress =
                  (curTime - glitchStartTimeRef.current) /
                  (glitchEndTimeRef.current - glitchStartTimeRef.current)

                // calculate canvas glitch destination rectangle
                const glitchDestRect = {
                  x: 0,
                  y: 0,
                  width: contextWidth,
                  height: glitchRect.current.height * GLITCH_LINE_HEIGHT,
                }
                glitchDestRect.y =
                  glitchRect.current.y *
                    (contextHeight -
                      glitchRect.current.height * GLITCH_LINE_HEIGHT) +
                  glitchProgress *
                    glitchOffset.current.y *
                    GLITCH_OFFSET_VERT_AMOUNT

                // get glitch source rectangle
                const glitchSourceScale = {
                  x: width / contextWidth,
                  y: height / contextHeight,
                }
                const glitchSourceRect = {
                  x: glitchDestRect.x * glitchSourceScale.x,
                  y: glitchDestRect.y * glitchSourceScale.y,
                  width: glitchDestRect.width * glitchSourceScale.x,
                  height: glitchDestRect.height * glitchSourceScale.y,
                }

                glitchSourceRect.x +=
                  Math.pow(
                    glitchProgress,
                    GLITCH_OFFSET_HORIZ_AMOUNT_PROGRESS_POW
                  ) *
                  glitchOffset.current.x *
                  GLITCH_OFFSET_HORIZ_AMOUNT

                context.clearRect(
                  glitchDestRect.x,
                  glitchDestRect.y,
                  glitchDestRect.width,
                  glitchDestRect.height
                )

                context.drawImage(
                  curFrameImage.current,
                  glitchSourceRect.x,
                  glitchSourceRect.y,
                  glitchSourceRect.width,
                  glitchSourceRect.height,
                  glitchDestRect.x,
                  glitchDestRect.y,
                  glitchDestRect.width,
                  glitchDestRect.height
                )
                // context.globalAlpha = previousGlobalAlpha
              } else {
                // set next glitch time
                setNextGlitch()
              }
            }

            if (GLITCH_FLICKER_ALPHA) {
              context.globalAlpha = previousGlobalAlpha
            }
          }
        }

        if (backgroundLoaded) {
          // draw bgImage if loaded

          previousGlobalAlpha = context.globalAlpha // save alpha state before adjusting bgImage alpha

          // if current frame has loaded then draw bgImage behind frame otherwise just draw bgImage
          if (curFrameLoaded) {
            context.globalCompositeOperation = 'destination-over' // draw bgImage behind planet

            // set alpha of bg draw to current animation value (tweened to 0 to blend out headerHomePlanet bg glow)
            context.globalAlpha = Math.pow(
              gsap.utils.clamp(0, 1, animationRef.current?.opacity + 0.2),
              0.9
            )
            // adjust mapping and easing of bg fade out for headerHomePlanetGlow
          }

          context.drawImage(
            bgImageRef.current,
            0,
            0,
            width,
            height,
            0,
            0,
            contextWidth,
            contextHeight
          )

          context.globalAlpha = previousGlobalAlpha

          backgroundHasRendered.current = true
        }

        context.globalCompositeOperation = previousGlobalCompositeOperation
        context.restore()
      }
    }
  }, [width, height])

  useEffect(() => {
    contextRef.current = canvasRef.current.getContext('2d')

    if (maskImage) {
      maskRef.current = new Image()
      maskRef.current.src = maskImage
      maskRef.current.onload = () => {
        maskLoaded.current = true
        renderAnimationFrame()
      }
    }

    if (bgImage) {
      bgImageRef.current = new Image()
      bgImageRef.current.src = bgImage
      bgImageRef.current.onload = () => {
        bgImageLoaded.current = true
        renderAnimationFrame()
      }
    }

    if (frames?.length) {
      imgs.datas = []
      imgs.current = frames.map((src, index) => {
        const img = new Image()
        img.src = src
        img.onload = () => {
          if (index === animationRef.current?.frame) {
            renderAnimationFrame()
          }
        }
        return img
      })
    }
  }, [frames, width, height, isImagesLoaded, renderAnimationFrame])

  return { canvasRef, animationRef, renderAnimationFrame }
}
