import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getTypeClasses } from '@/helpers/getTypeClasses'
import * as styles from './RichText.styles.scss'

const RichText = ({
  type,
  children,
  className,
  hasHeadingStyles,
  hasListStyles,
  hasImageStyles,
  hasTableStyles,
}) => {
  return (
    <div
      className={classNames(
        styles.RichText,
        {
          [styles.headingStyles]: hasHeadingStyles,
          [styles.listStyles]: hasListStyles,
          [styles.imageStyles]: hasImageStyles,
          [styles.tableStyles]: hasTableStyles,
          ...getTypeClasses(type),
        },
        className
      )}
    >
      {children}
    </div>
  )
}

RichText.defaultProps = {
  hasHeadingStyles: true,
  hasListStyles: true,
  hasImageStyles: true,
  hasTableStyles: true,
}

RichText.propTypes = {
  children: PropTypes.node.isRequired,
  hasHeadingStyles: PropTypes.bool,
  hasListStyles: PropTypes.bool,
  hasImageStyles: PropTypes.bool,
  hasTableStyles: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export { RichText }
