import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Btn } from '@/components/Btn'
import { VideoLightbox } from '@/components/VideoLightbox'
import * as styles from './VideoBtnLink.styles.scss'

const VideoBtnLink = ({ children, video }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const handleClickThumb = () => setIsLightboxOpen(true)
  const handleClose = () => setIsLightboxOpen(false)

  if (!video?.url) return

  return (
    <div>
      <VideoLightbox
        url={video?.url}
        aspectRatio={video?.aspectRatio}
        onClickClose={handleClose}
        isOpen={isLightboxOpen}
      />
      <Btn className={styles.thumb} onClick={handleClickThumb}>
        {children}
      </Btn>
    </div>
  )
}

VideoBtnLink.defaultProps = {
  children: 'Watch',
}

VideoBtnLink.propTypes = {
  children: PropTypes.string,
  video: PropTypes.object,
}

export { VideoBtnLink }
