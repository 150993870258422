import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './CarouselProgress.styles.scss'

const CarouselProgress = ({ slidesInView, totalSlides, scrollProgress }) => {
  const progress = slidesInView.length / totalSlides
  const remaining = scrollProgress * (1 - progress)

  return (
    <div className={styles.CarouselProgress}>
      <div
        className={styles.progress}
        style={{ transform: `translateX(${remaining * 100}%)` }}
      >
        <div
          className={styles.progress__inner}
          style={{ transform: `scaleX(${progress})` }}
        />
      </div>
    </div>
  )
}

CarouselProgress.propTypes = {
  slidesInView: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  totalSlides: PropTypes.number,
}

export { CarouselProgress }
