// extracted by mini-css-extract-plugin
export var TokensOverview = "bF_gd";
export var aniSpin = "bF_d";
export var btn = "bF_by";
export var description = "bF_bP";
export var grid = "bF_bj";
export var heading = "bF_bk";
export var image01 = "bF_fK";
export var image02 = "bF_fL";
export var isHighlighted = "bF_gg";
export var item = "bF_w";
export var item__head = "bF_b3";
export var item__icon = "bF_b4";
export var item__label = "bF_4";
export var item__title = "bF_gh";
export var items = "bF_bl";
export var items__list = "bF_gf";
export var text = "bF_bp";