import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState, loadingState } from '@/store'
import { Container } from '@/components/Container'
import { BtnMenu } from '@/components/BtnMenu'
import { Link } from '@/components/Link'
import { LogoIcon } from '@/components/LogoIcon'
import { Btn } from '@/components/Btn'
import { useScrollEffects } from './hooks/useScrollEffects'
import { useVisibility } from './hooks/useVisibility'
import * as styles from './Header.styles.scss'

const Header = ({ nav, ctas }) => {
  const { isLoaded } = useRecoilValue(loadingState)
  const { device } = useRecoilValue(browserState)
  const ref = useRef()
  const innerRef = useRef()
  useScrollEffects(ref)
  useVisibility(innerRef, { isVisible: isLoaded }) // use a different ref to scroll effects to avoid clashes

  return (
    <header className={styles.Header} ref={ref}>
      <div className={styles.gradient} />
      <Container>
        <div className={styles.inner} ref={innerRef}>
          <h1 className={styles.logo}>
            <LogoIcon href="/" />
          </h1>
          <nav className={styles.nav}>
            {device === 'mobile' ? (
              <BtnMenu />
            ) : (
              <div className={styles.nav__wrap}>
                <ul className={styles.nav__list}>
                  {nav?.map((item) => (
                    <li key={item.uid} className={styles.nav__item}>
                      <Link
                        to={item.url}
                        target={item.target}
                        download={item.download}
                        className={styles.nav__link}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className={styles.ctas}>
                  {!!ctas?.length && (
                    <ul className={styles.ctas__list}>
                      {ctas?.map((item) => {
                        return (
                          <li key={item.uid} className={styles.cta}>
                            {item.appearance === 'default' ? (
                              <Btn href={item.url}>{item.title}</Btn>
                            ) : (
                              <Btn href={item.url} icon={item.appearance} />
                            )}
                            {/* <Link
                              to={item.url}
                              target={item.target}
                              className={styles.cta__link}
                            >
                              <Frame />
                              {item.appearance === 'default' ? (
                                <span className={styles.cta__text}>
                                  {item.title}
                                </span>
                              ) : (
                                <SocialIcon
                                  variant={item.appearance}
                                  className={styles.cta__icon}
                                />
                              )}
                            </Link> */}
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </nav>
        </div>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  nav: [],
}

Header.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.object),
  ctas: PropTypes.arrayOf(PropTypes.object),
}

export { Header }
