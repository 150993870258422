import { useEffect } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Psuedo fixed positioning using ScrollTrigger pinning
 */

export const useFixedPosition = (ref, { selector, isEnabled }) => {
  useEffect(() => {
    const $root = ref.current
    const $fixedElements = $root.querySelectorAll(selector)

    const scrollTriggers = []
    if (isEnabled) {
      $fixedElements.forEach(($element) => {
        scrollTriggers.push(
          ScrollTrigger.create({
            trigger: $root,
            start: 'top top',
            end: 'bottom top',
            pin: $element,
            pinSpacing: false,
            onToggle: (self) => {
              gsap.to($element, {
                autoAlpha: self.isActive ? 1 : 0,
                duration: 0.2,
              })
            },
          })
        )
      })
    }

    return () => {
      scrollTriggers.forEach((i) => i.kill())
    }
  }, [ref, selector, isEnabled])
}
