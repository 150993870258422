import { useEffect, useLayoutEffect } from 'react'

/**
 * Hook which switches between useEffect and useLayoutEffect following the execution environment.
 *
 * More info
 * https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
 *
 */

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect
