import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getThemeCssVar } from '@/helpers/getThemeCssVar'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { Icon } from '@/components/Icon'
import { Frame } from '@/components/Frame'
import { Btn } from '@/components/Btn'
import * as styles from './SlideContent.styles.scss'

const SlideContent = ({
  className,
  subheading,
  heading,
  headingIcon,
  description,
  icon,
  link,
  theme,
}) => {
  return (
    <div
      className={classNames(styles.SlideContent, className)}
      style={getThemeCssVar(theme || 'cyan')}
    >
      {subheading && (
        <Heading tag="div" type="s4" className={styles.subheading}>
          {subheading}
        </Heading>
      )}
      <Heading tag="div" type="h2" className={styles.heading}>
        {headingIcon?.variant && (
          <div
            className={classNames(styles.heading__icon, {
              [styles.hasBg]: headingIcon.hasBg,
            })}
          >
            <Icon variant={headingIcon.variant} />
          </div>
        )}
        {heading}
      </Heading>
      {description && (
        <RichText type="p1" className={styles.description}>
          {description}
        </RichText>
      )}
      {icon?.variant && icon?.variant !== 'none' && (
        <div className={styles.icon}>
          <div className={styles.icon__image}>
            <Frame />
            <Icon
              variant={icon?.variant}
              className={styles.icon__svg}
              theme="white"
            />
          </div>
          <div className={styles.icon__text}>
            {icon?.subheading && (
              <div className={styles.icon__subheading}>{icon?.subheading}</div>
            )}
            <div>{icon?.heading}</div>
          </div>
        </div>
      )}
      {link?.url && (
        <div className={styles.btn}>
          <Btn to={link.url}>{link.title}</Btn>
        </div>
      )}
    </div>
  )
}

SlideContent.propTypes = {
  className: PropTypes.string,
  subheading: PropTypes.node,
  heading: PropTypes.node,
  description: PropTypes.node,
  icon: PropTypes.object,
  link: PropTypes.object,
}

export { SlideContent }
