import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Block } from '@/components/Block'
import { HexagonGrid } from '@/components/HexagonGrid'
import { Heading } from '@/components/Heading'
import { Container } from '@/components/Container'
import { Icon } from '@/components/Icon'
import { Portal } from '@/components/Portal'
import { useTransition } from './Icons.gsap'
import * as styles from './Icons.styles.scss'

const Icons = ({ items }) => {
  const { device } = useRecoilValue(browserState)
  const ref = useRef()
  const isFixedLayout = device === 'desktop'

  const isVisible = useTransition(ref, {
    trigger: `.${styles.scrollTrigger}`,
  })

  return (
    <Block
      className={classNames(styles.Icons, {
        [styles.isFixedLayout]: isFixedLayout,
      })}
      ref={ref}
    >
      <Portal isEnabled={isFixedLayout}>
        <div className={isFixedLayout ? styles.fixed : null}>
          <Container>
            {!!items?.length && (
              <div className={styles.items}>
                <HexagonGrid
                  size="large"
                  isVisible={isVisible || !isFixedLayout}
                  items={items.map((item) => {
                    return {
                      uid: item.uid,
                      children: (
                        <div className={styles.icon}>
                          <Icon variant={item.icon} className={styles.image} />
                          <Heading
                            tag="div"
                            type={{ desktop: 'h5', mobile: 'h6' }}
                            className={styles.label}
                          >
                            {item.title}
                          </Heading>
                        </div>
                      ),
                    }
                  })}
                />
              </div>
            )}
          </Container>
        </div>
      </Portal>
      <div className={styles.scrollTrigger} />
    </Block>
  )
}

Icons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { Icons }
