import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'

import { useBloks } from '@/storyblok/hooks/useBloks'
import Page from '@/templates/Page'

const PageStoryblok = ({ story }) => {
  const headerBlocks = useBloks(story.content.header || [])
  const bodyBlocks = useBloks(story.content.body || [])

  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Page headerBlocks={headerBlocks} bodyBlocks={bodyBlocks} />
    </SbEditable>
  )
}

PageStoryblok.propTypes = {
  story: PropTypes.object,
}

export default PageStoryblok
