// extracted by mini-css-extract-plugin
export var TokensSectionHead = "L_bJ";
export var aniSpin = "L_d";
export var body = "L_bx";
export var description = "L_bP";
export var grid = "L_bj";
export var head = "L_bK";
export var heading = "L_bk";
export var image = "L_br";
export var isInView = "L_bN";
export var subheading = "L_bL";
export var subheading__inner = "L_bM";