import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { transitionState } from '@/store/transitionState'
import { useTransition } from './PageTransition.gsap'
import * as styles from './PageTransition.styles.scss'

const PageTransition = ({ children }) => {
  const ref = useRef()
  const transition = useRecoilValue(transitionState)

  useTransition(ref, { transitionState: transition })

  return (
    <div className={styles.PageTransition} ref={ref}>
      {children}
    </div>
  )
}

PageTransition.propTypes = {
  children: PropTypes.node,
}

export { PageTransition }
