import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'
import { browserState } from '@/store/browserState'

/**
 * Store global state based on resize events
 */

const ResizeService = ({ children }) => {
  const [state, setBrowserState] = useRecoilState(browserState)
  const prevWindowSize = useRef({ height: 0, width: 0 })
  const viewportHeight = useRef(0)

  const onResize = () => {
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth

    // only update viewportHeight if height change is significant
    // this is to avoid change when bottom ui hides when scrolling on iOS

    const heightChange = Math.abs(windowHeight - prevWindowSize.current.height)
    const widthChange = Math.abs(windowWidth - prevWindowSize.current.width)

    // if (heightChange > 120 || widthChange > 0) {
    if (heightChange > 200 || widthChange > 0) {
      viewportHeight.current = windowHeight
      prevWindowSize.current = { height: windowHeight, width: windowWidth }

      // optional: store vh css variable
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      // document.documentElement.style.setProperty('--vh',`${windowHeight * 0.01}px`)
    }

    // Update CSS Variables
    const root = document.documentElement
    root.style.setProperty('--viewportHeight', `${viewportHeight.current}px`)
    root.style.setProperty('--windowWidth', `${windowWidth}px`)
    root.style.setProperty('--windowHeight', `${windowHeight}px`)

    setBrowserState({
      isReady: true,
      device: windowWidth > 768 ? 'desktop' : 'mobile',
      windowWidth,
      windowHeight,
      viewportHeight: viewportHeight.current, // this is updated less frequently
    })
  }

  useEffect(() => {
    // if SSR, set basic browserState
    if (typeof window === 'undefined') {
      setBrowserState({ isReady: true, device: 'mobile' })
      return
    }

    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // wait until resize state is ready
  return state.isReady ? children : null
}

ResizeService.propTypes = {
  children: PropTypes.node,
}

export { ResizeService }
