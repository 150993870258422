// extracted by mini-css-extract-plugin
export var HomeFeature = "bd_c6";
export var aniSpin = "bd_d";
export var btn = "bd_by";
export var centerLayout = "bd_c9";
export var description = "bd_bP";
export var grid = "bd_bj";
export var heading = "bd_bk";
export var leftLayout = "bd_c7";
export var rightLayout = "bd_c8";
export var subheading = "bd_bL";
export var text = "bd_bp";