import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getCssVars } from './Frame.helpers'
import * as styles from './Frame.styles.scss'

const Frame = ({ strokeWidth, cornerWidth, cornerHeight, isActive }) => {
  const style = getCssVars({ strokeWidth, cornerWidth, cornerHeight })

  return (
    <div
      className={classNames(styles.Frame, { [styles.isActive]: isActive })}
      style={style}
    >
      <span className={styles.borderTop} />
      <span className={styles.borderRight} />
      <span className={styles.borderBottom} />
      <span className={styles.borderLeft} />
      <span className={styles.cornerTopLeft} />
      <span className={styles.cornerTopRight} />
      <span className={styles.cornerBottomLeft} />
      <span className={styles.cornerBottomRight} />
    </div>
  )
}

Frame.defaultProps = {
  strokeWidth: 1,
  cornerWidth: 5,
  cornerHeight: 5,
}

Frame.propTypes = {
  strokeWidth: PropTypes.number,
  cornerWidth: PropTypes.number,
  cornerHeight: PropTypes.number,
  isActive: PropTypes.bool,
}

export { Frame }
