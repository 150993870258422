import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { ScrollFrameAnimation } from '@/components/ScrollFrameAnimation'
import * as styles from './HeaderHomePlanet.styles.scss'

const PLANET_X = 98 / 1200 // X position of planet within image artboard
const PLANET_Y = 96 / 1200 // Y position of planet within image artboard
const PLANET_SIZE = 1004 / 1200 // Scale of planet inside image artboard
const IMAGE_RATIO = 1200 / 1200
const MAX_WIDTH_DESKTOP = 1300
const MAX_WIDTH_MOBILE = 500

const HeaderHomePlanet = ({ planet, dust }) => {
  const { windowWidth, device } = useRecoilValue(browserState)
  const scrollSequenceRef = useRef()
  const imageWidth =
    device === 'desktop'
      ? Math.min(MAX_WIDTH_DESKTOP, windowWidth)
      : Math.min(MAX_WIDTH_MOBILE, windowWidth * 2.25)
  const imageHeight = imageWidth * IMAGE_RATIO

  return (
    <div
      className={styles.HeaderHomePlanet}
      ref={scrollSequenceRef}
      style={{
        '--imageWidth': `${imageWidth}px`,
        '--imageHeight': `${imageHeight}px`,
        '--planetX': `-${imageWidth * PLANET_X}px`,
        '--planetY': `-${imageHeight * PLANET_Y}px`,
        '--planetSize': `${imageWidth * PLANET_SIZE}px`,
      }}
    >
      <div className={styles.inner}>
        <div className={styles.dust} data-speed="0.6">
          <ResponsiveImg
            {...dust}
            className={styles.dust__img}
            isLazy={false}
          />
        </div>
        <div className={styles.image}>
          {planet.mediaType === 'animation_frames' ? (
            <ScrollFrameAnimation
              {...planet}
              scrollTriggerRef={scrollSequenceRef}
              fadeOutOpacity={true}
              showGlow={true}
              scrollTriggerOptions={{ start: 0 }} // ease: 'power2.out'
            />
          ) : (
            <ResponsiveImg
              {...planet}
              isFull
              objectFit="contain"
              isLazy={false}
            />
          )}
        </div>
      </div>
    </div>
  )
}

HeaderHomePlanet.propTypes = {
  planet: PropTypes.object,
  dust: PropTypes.object,
}

export { HeaderHomePlanet }
