// extracted by mini-css-extract-plugin
export var aniSpin = "bv_d";
export var frame = "bv_ff";
export var frame__cornerBottomLeft = "bv_fj";
export var frame__cornerTopRight = "bv_fh";
export var frame__svg = "bv_fg";
export var item = "bv_w";
export var item__animation = "bv_fl";
export var item__blueprint = "bv_fk";
export var item__image = "bv_3";
export var item__inner = "bv_2";
export var item__subheading = "bv_bS";