// extracted by mini-css-extract-plugin
export var HeaderGameplay = "Q_b8";
export var aniSpin = "Q_d";
export var bodyText = "Q_cf";
export var heading = "Q_bk";
export var image = "Q_br";
export var image__inner = "Q_b9";
export var introText = "Q_cd";
export var primary = "Q_cb";
export var secondary = "Q_cc";
export var text = "Q_bp";