import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import TransitionService from '@/services/TransitionService'
import { transitionState } from '@/store/transitionState'

/**
 * Hook for using the Transition Service in React
 *
 * This should be placed in the Layout component
 * The component using this hook must be inside <RecoilRoot>
 */

const useTransitionService = (options = {}) => {
  const { isDebug } = options
  const setTransition = useSetRecoilState(transitionState)

  useEffect(() => {
    const onTransition = (state) => {
      if (isDebug) console.info(`🚀 Transition:${state}`)
      setTransition(state)
    }
    TransitionService.on(TransitionService.events.TRANSITION, onTransition)

    return () => {
      TransitionService.off(TransitionService.events.TRANSITION, onTransition)
    }
  }, [setTransition])

  return null
}

export { useTransitionService }
