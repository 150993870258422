/**
 * Used for ScrambedText and ScrambedTextArray components
 */

export const SCRAMBLED_CHARACTER_SET = [
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'D',
  'A',
  'B',
  'C',
  'R',
  'X',
  'Z',
]
