export const getHeaderImages = (variant, { imageAssets }) => {
  const { header01, header02 } = imageAssets

  const IMAGES = {
    '01': header01,
    '02': header02,
  }

  return IMAGES[variant] || {}
}
