import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

export const useVisibility = (ref, { isVisible }) => {
  // Only transition in once
  const hasTransitioned = useRef(false)

  useEffect(() => {
    let tl

    if (isVisible && !hasTransitioned.current) {
      tl = gsap.timeline()
      tl.to(ref.current, { autoAlpha: 1, duration: 1, delay: 1 })
      hasTransitioned.current = true
    }
  }, [ref, isVisible])
}
