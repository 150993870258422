import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Frame } from '@/components/Frame'
import * as styles from './BtnClose.styles.scss'

const BtnClose = ({ onClick }) => {
  const [isHovering, setIsHovering] = useState(false)
  const handleMouseOver = () => setIsHovering(true)
  const handleMouseOut = () => setIsHovering(false)

  return (
    <button
      className={styles.BtnClose}
      aria-label="Close"
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Frame isActive={isHovering} />
      <span className={styles.icon}>
        <span className={styles.line} />
        <span className={styles.line} />
      </span>
    </button>
  )
}

BtnClose.propTypes = {
  onClick: PropTypes.func,
}

export { BtnClose }
