import { useEffect, useRef, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { canvasPoolState } from '@/store'

export const useGLRenderer = ({
  shaderConfig,
  setTexturesLoaded,
  clampTextures,
  canvasContainerRef,
  className,
}) => {
  const setCanvasPool = useSetRecoilState(canvasPoolState)

  const canvasRef = useRef(null)

  const glRef = useRef(null)
  const renderPlane = useRef(null)

  const uResolution = useRef(null)

  const uniforms = useRef({})
  const textures = useRef([])

  const MAX_CANVAS_COUNT = 11

  // dynamically add canvas from available canvas pool if no canvas specified and canvasContainerRef is specified and canvas pool capacity not exceeded
  useEffect(() => {
    // if no canvas specified and canvasContainerRef is specified
    if (!canvasRef.current && canvasContainerRef?.current) {

      // use existing used canvas or create new canvas and add to pool
      setCanvasPool((curCanvasPool) => {

        // original array items are readonly so shallow copy canvas pool items for updating
        const newCanvasPool = curCanvasPool.map(item => ({ ...item }))

        let canvas = null

        // grab existing unused canvas if available
        const availableCanvasItem = newCanvasPool.find(item => !item.used)

        if (availableCanvasItem) {
          // use unused canvas if exists and set to used

          canvas = availableCanvasItem.canvas
          availableCanvasItem.used = true

          // console.log('create gl renderer existing canvas', availableCanvasItem)
        } else if (curCanvasPool.length < MAX_CANVAS_COUNT) {
          // if no available canvases and allowed to create more create and add to canvas pool

          canvas = document.createElement('canvas')
          const newCanvasItem = { canvas, used: true }
          newCanvasPool.push(newCanvasItem)

          // console.log('create gl renderer new canvas', newCanvasItem)
        } else {
          // TODO: flag no available canvas to parent component (e.g. to switch to image)

          console.log('create gl renderer MAX_CANVAS_COUNT exceeded')
        }

        // add available canvas to canvasContainer
        if (canvas) {
          canvas.className = className
          canvasContainerRef.current.appendChild(canvas)

          canvasRef.current = canvas
        }

        // console.log('create gl renderer newCanvasPool', newCanvasPool)
        return newCanvasPool
      })
    }
  }, [canvasContainerRef])

  const renderAnimationFrame = useCallback(() => {
    const gl = glRef.current

    if (canvasRef.current && gl) {
      gl.clear(gl.COLOR_BUFFER_BIT)

      renderPlane.current.render(gl)
    }
  }, [canvasRef, glRef, renderPlane])

  const resizeCanvas = useCallback(() => {
    const canvas = canvasRef.current
    const gl = glRef.current

    const { width, height } = canvas.getBoundingClientRect()

    canvas.width = width
    canvas.height = height

    uResolution.current.set(width, height)
    gl.viewport(0, 0, width, height)
  }, [canvasRef, glRef, renderPlane])

  useEffect(() => {
    const canvas = canvasRef.current

    let curTextureIndex = 0

    let resizeObserver

    if (canvas) {
      const gl = (glRef.current = canvas.getContext('webgl'))

      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
      gl.enable(gl.BLEND)

      gl.clearColor(0.0, 0.0, 0.0, 0.0)

      const isPowerOfTwo = (value) => {
        return (Math.log(value) / Math.log(2)) % 1 === 0
      }
      const nextPowerOfTwo = (value) => {
        return Math.pow(2, Math.ceil(Math.log(value) / Math.log(2)))
      }

      const checkTexturesLoaded = () => {
        let numTexturesLoaded = 0
        textures.current.forEach((texture) => {
          if (texture.image.complete) {
            numTexturesLoaded++
          }
        })

        if (numTexturesLoaded === numTexturesLoaded) {
          setTexturesLoaded(true)
        }
      }

      // Uniform
      function Uniform(name, suffix) {
        this.name = name
        this.suffix = suffix
        this.location = gl.getUniformLocation(program, name)
      }
      Uniform.prototype.set = function (...values) {
        var method = 'uniform' + this.suffix
        var args = [this.location].concat(values)
        gl[method].apply(gl, args)
      }

      // Texture
      function Texture(name, suffix, imageSrc) {
        this.name = name
        this.suffix = suffix
        this.imageSrc = imageSrc
        this.location = gl.getUniformLocation(program, name)

        this.texture = gl.createTexture()

        let curTextureElement
        switch (curTextureIndex) {
          case 0:
            curTextureElement = gl.TEXTURE0
            break
          case 1:
            curTextureElement = gl.TEXTURE1
            break
          case 2:
            curTextureElement = gl.TEXTURE2
            break
          case 3:
            curTextureElement = gl.TEXTURE3
            break
          case 4:
            curTextureElement = gl.TEXTURE4
            break
          case 5:
            curTextureElement = gl.TEXTURE5
            break
          case 6:
            curTextureElement = gl.TEXTURE6
            break
          case 7:
            curTextureElement = gl.TEXTURE7
            break
          case 8:
            curTextureElement = gl.TEXTURE8
            break
        }
        this.curTextureElement = curTextureElement
        this.curTextureIndex = curTextureIndex

        curTextureIndex++

        gl.activeTexture(curTextureElement)
        gl.bindTexture(gl.TEXTURE_2D, this.texture)

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
        if (clampTextures) {
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
        } else {
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT)
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT)
        }

        gl.uniform1i(this.location, this.curTextureIndex)

        const image = new Image()
        image.crossOrigin = 'Anonymous' // allow cross domain use of images
        this.image = image

        image.addEventListener('load', () => {
          let targetSource = image

          // if either dimension of image is not a power of two, scale image up to next power of two
          if (!isPowerOfTwo(image.width) || !isPowerOfTwo(image.height)) {
            var imageResizeCanvas = document.createElement('canvas')
            imageResizeCanvas.width = nextPowerOfTwo(image.width * 2)
            imageResizeCanvas.height = nextPowerOfTwo(image.height * 2)

            const resizeCanvasContext = imageResizeCanvas.getContext('2d')
            resizeCanvasContext.drawImage(image, 0, 0, imageResizeCanvas.width, imageResizeCanvas.height)
            targetSource = imageResizeCanvas
          }

          gl.activeTexture(this.curTextureElement)
          gl.texImage2D(
            gl.TEXTURE_2D,
            0,
            gl.RGBA,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            targetSource
          )

          if (setTexturesLoaded) {
            checkTexturesLoaded()
          }
        })

        if (imageSrc) {
          this.set(imageSrc)
        }
      }
      Texture.prototype.set = function (value) {
        if (setTexturesLoaded) {
          setTexturesLoaded(false)
        }
        this.image.src = value
      }

      // Plane
      function Plane(gl) {
        const buffer = gl.createBuffer()
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
        gl.bufferData(gl.ARRAY_BUFFER, Plane.attributeValues, gl.STATIC_DRAW)
        const a_Position = gl.getAttribLocation(program, 'a_Position'),
          a_TexCoord = gl.getAttribLocation(program, 'a_TexCoord'),
          fsize = Plane.attributeValues.BYTES_PER_ELEMENT

        gl.vertexAttribPointer(a_Position, 2, gl.FLOAT, false, 4 * fsize, 0)
        gl.vertexAttribPointer(
          a_TexCoord,
          2,
          gl.FLOAT,
          false,
          4 * fsize,
          2 * fsize
        )
        gl.enableVertexAttribArray(a_Position)
        gl.enableVertexAttribArray(a_TexCoord)
      }
      Plane.attributeValues = new Float32Array([
        -1, 1, 0.0, 1.0, -1, -1, 0.0, 0.0, 1, 1, 1.0, 1.0, 1, -1, 1.0, 0.0,
      ])
      Plane.prototype.render = function (gl) {
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
      }

      function addShader(source, type) {
        var shader = gl.createShader(type)
        gl.shaderSource(shader, source)
        gl.compileShader(shader)
        var isCompiled = gl.getShaderParameter(shader, gl.COMPILE_STATUS)
        if (!isCompiled) {
          throw new Error(
            'Shader compile error: ' + gl.getShaderInfoLog(shader)
          )
        }
        gl.attachShader(program, shader)
      }

      // Init shader program
      const program = gl.createProgram()

      addShader(shaderConfig.vertexShader, gl.VERTEX_SHADER)
      addShader(shaderConfig.fragmentShader, gl.FRAGMENT_SHADER)

      gl.linkProgram(program)
      gl.useProgram(program)

      uResolution.current = new Uniform('viewResolution', '2f')

      let textureIndex = 0

      // add uniforms
      if (shaderConfig.uniforms) {
        shaderConfig.uniforms.forEach((uniformConfig) => {
          if (uniformConfig.suffix === 't') {
            const texture = new Texture(
              uniformConfig.name,
              uniformConfig.suffix,
              uniformConfig.value
            )
            textures.current.push(texture)
            textureIndex++
          } else {
            const uniform = new Uniform(uniformConfig.name, uniformConfig.suffix)
            if (Array.isArray(uniformConfig.value)) {
              uniform.set(...uniformConfig.value)
            } else {
              uniform.set(uniformConfig.value)
            }
            uniforms.current[uniformConfig.name] = uniform
          }
        })
      }

      gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1)

      // load textures and add samplers
      if (shaderConfig.textures && shaderConfig.textures.length) {
        shaderConfig.textures.forEach((textureImage) => {
          const texture = new Texture(
            'textureSampler' + (textureIndex + 1),
            't',
            textureImage
          )
          textures.current.push(texture)
          textureIndex++
        })
      }

      renderPlane.current = new Plane(gl)

      resizeObserver = new ResizeObserver(resizeCanvas)
      resizeObserver.observe(canvas)

      resizeCanvas()
    }

    return () => {
      resizeObserver?.disconnect()

      // flag renderer canvas as unused to allow re-use
      setCanvasPool((curCanvasPool) => {

        // original array items are readonly so shallow copy canvas pool items for updating
        const newCanvasPool = curCanvasPool.map(item => ({ ...item }))

        // find and flag renderer canvas as unused to allow re-use
        if (canvasRef.current) {
          const currentCanvasItem = newCanvasPool.find(item => item.canvas === canvasRef.current)

          // console.log('unuse gl renderer currentCanvasItem', currentCanvasItem)

          if (currentCanvasItem) {
            currentCanvasItem.used = false
          }
        }

        // console.log('unuse gl renderer newCanvasPool', newCanvasPool)
        return newCanvasPool
      })
    }
  }, [
    renderAnimationFrame,
  ])

  return {
    uniforms,
    textures,
    canvasRef,
    renderAnimationFrame,
    resizeCanvas,
  }
}
