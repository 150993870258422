// extracted by mini-css-extract-plugin
export var HeaderHome = "bc_cV";
export var aniSpin = "bc_d";
export var bottom = "bc_c3";
export var fixedBack = "bc_cY";
export var fixedFooter = "bc_cZ";
export var footer = "bc_c0";
export var hasScrollEffects = "bc_cW";
export var middle = "bc_c2";
export var nebula = "bc_c4";
export var nebula__img = "bc_c5";
export var scrollToPoint = "bc_cX";
export var top = "bc_c1";