// extracted by mini-css-extract-plugin
export var PlanetsScene = "b3_ht";
export var aniSpin = "b3_d";
export var children = "b3_dN";
export var dust01 = "b3_hy";
export var dust01__inner = "b3_hz";
export var dust02 = "b3_hG";
export var dust02__inner = "b3_hH";
export var flyingShip = "b3_hD";
export var flyingShip__inner = "b3_hF";
export var planet01 = "b3_hw";
export var planet01__inner = "b3_hx";
export var planet02 = "b3_hB";
export var planet02__inner = "b3_hC";
export var primary = "b3_cb";
export var primary__illustration = "b3_hv";
export var secondary = "b3_cc";