import React from 'react'
import PropTypes from 'prop-types'
import { gsap, Power1 } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import * as styles from './ScrollDown.styles.scss'

gsap.registerPlugin(ScrollToPlugin)

const ScrollDown = ({ children, scrollToRef }) => {
  // const scrollToRef = useRef()

  const onClickBtnScroll = () => {
    if (scrollToRef.current) {
      gsap.to(window, {
        duration: 1.25,
        scrollTo: { y: scrollToRef.current, offsetY: 30 },
        ease: Power1.easeInOut,
      })
    } else {
      console.warn('couldnt find scroll element')
    }
  }

  return (
    <div>
      <button className={styles.btnScroll} onClick={onClickBtnScroll}>
        {children}
        <span className={styles.line} />
        <span className={styles.autoplayLine} />
      </button>
      <div className={styles.scrollTo} ref={scrollToRef} />
    </div>
  )
}

ScrollDown.propTypes = {
  children: PropTypes.node,
  scrollToRef: PropTypes.object,
}

export { ScrollDown }
