import { atom } from 'recoil'

/**
 * Store current image preload requests
 *
 * @usage
 * const [preloads, setPreloads] = useRecoilState(preloadsState)
 */

export const preloadsState = atom({
  key: 'preloadsState', // unique ID (with respect to other atoms/selectors)
  default: { pathname: null, images: {} },
})
