import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { ScrollFrameAnimation } from '@/components/ScrollFrameAnimation'
import { usePageLoaded } from '@/hooks/usePageLoaded'
import * as styles from './PlanetsScene.styles.scss'

const PlanetsScene = ({ children, images }) => {
  const { dust, flyingShip, planet01, planet02 } = images
  const planet01Ref = useRef()
  const planet02Ref = useRef()
  const isPageLoaded = usePageLoaded()

  return (
    <div className={styles.PlanetsScene}>
      <div className={styles.primary}>
        <div className={styles.primary__illustration}>
          <div className={styles.dust01} data-speed="0.6">
            <div className={styles.dust01__inner}>
              <ResponsiveImg {...dust} isLazy={false} />
            </div>
          </div>
          <div className={styles.planet01} ref={planet01Ref} data-speed="0.9">
            <div className={styles.planet01__inner}>
              {isPageLoaded && planet01.mediaType === 'animation_frames' ? (
                <ScrollFrameAnimation
                  {...planet01}
                  scrollTriggerRef={planet01Ref}
                />
              ) : (
                <ResponsiveImg {...planet01} isLazy={false} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.children}>{children}</div>
      </div>
      <div className={styles.secondary}>
        <div className={styles.dust02} data-speed="0.6">
          <div className={styles.dust02__inner}>
            <ResponsiveImg {...dust} isLazy={false} />
          </div>
        </div>
        <div className={styles.flyingShip} data-speed="0.8">
          <div className={styles.flyingShip__inner}>
            <ResponsiveImg {...flyingShip} isLazy={false} />
          </div>
        </div>
        <div className={styles.planet02} data-speed="0.95">
          <div className={styles.planet02__inner} ref={planet02Ref}>
            {isPageLoaded && planet02.mediaType === 'animation_frames' ? (
              <ScrollFrameAnimation
                {...planet02}
                scrollTriggerRef={planet02Ref}
              />
            ) : (
              <ResponsiveImg {...planet02} isLazy={false} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PlanetsScene.propTypes = {
  children: PropTypes.node,
  images: PropTypes.object.isRequired,
}

export { PlanetsScene }
