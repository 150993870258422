import { useEffect, useState, useRef } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

/**
 * Hook which changes state when ref is visible in viewport
 * leverages GSAP ScrollTrigger for consistency.
 *
 * If GSAP isn't a dependency, a recommended 3rd party library is:
 * https://github.com/thebuilder/react-intersection-observer
 *
 */

gsap.registerPlugin(ScrollTrigger)

export const useInView = (ref, options = {}) => {
  const { start, end, isDebug } = options
  const prevRef = useRef(null)
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    const scrollTrigger = ScrollTrigger.create({
      start,
      end,
      trigger: ref?.current,
      onToggle: (self) => {
        if (self.isActive !== prevRef.current) {
          if (isDebug) console.log('useInView', self.isActive)
          setIsInView(self.isActive)
          prevRef.current = self.isActive
        }
      },
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [ref, start, end, isDebug])

  return isInView
}
