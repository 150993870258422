import { getAspectRatio } from '@/helpers/getAspectRatio'
import { useStaticQuery, graphql } from 'gatsby'

/**
 * Use Gatsby File System to source imagery and animation frames
 *
 * – See `/gatsby-config.js` for `gatsby-source-filesystem` config
 * – Image width, height and aspect ratio are automatically sourced via Sharp
 * – Supports mobile version of images when used with ResponsiveImg component
 */

export const useImageAssets = () => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        filter: { sourceInstanceName: { eq: "images" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            publicURL
            name
            relativeDirectory
            childImageSharp {
              original {
                height
                width
              }
            }
          }
        }
      }
      animationFrames: allFile(
        filter: { sourceInstanceName: { eq: "animation-frames" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            publicURL
            name
            relativeDirectory
            childImageSharp {
              original {
                height
                width
              }
            }
          }
        }
      }
    }
  `)

  const imageAssets = {}

  data.animationFrames?.edges?.forEach(({ node }) => {
    const { relativeDirectory, publicURL, childImageSharp } = node
    const { width, height } = childImageSharp?.original

    const path = relativeDirectory.split('/')
    const rootDirectory = path[0]
    const subDirectory = path[1]

    // If image object doesn't exit, create it
    if (!imageAssets[rootDirectory]) {
      imageAssets[rootDirectory] = {
        mediaType: 'animation_frames',
        width: width,
        height: height,
        aspectRatio: getAspectRatio(width, height),
        frames: [],
      }
    }

    // If the subdirectory is "_bg" set as bgImage
    if (subDirectory === '_bg') {
      imageAssets[rootDirectory].bgImage = publicURL
      return
    }

    // If the subdirectory is "_mask" set as maskImage
    if (subDirectory === '_mask') {
      imageAssets[rootDirectory].maskImage = publicURL
      return
    }

    // Otherwise push the image into the array of frames
    imageAssets[relativeDirectory].frames.push(publicURL)
  })

  data.images?.edges?.forEach(({ node }) => {
    const { publicURL, childImageSharp, name, relativeDirectory } = node
    const { width, height } = childImageSharp?.original

    // If image object doesn't exit, create it
    if (!imageAssets[name]) {
      imageAssets[name] = { mediaType: 'image' }
    }

    if (relativeDirectory === 'mobile') {
      imageAssets[name].mobile = {
        src: publicURL,
        width,
        height,
        aspectRatio: getAspectRatio(width, height),
      }
      return
    }

    // This can be triggered after merging different file structures.
    // Try `gatsby clean` to clear the image file cache.
    if (imageAssets[name]?.src) console.warn(`Duplicate asset key: ${name}. `)

    imageAssets[name] = {
      ...imageAssets[name],
      src: publicURL,
      width,
      height,
      aspectRatio: getAspectRatio(width, height),
    }
  })

  return imageAssets
}
