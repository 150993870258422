import { useEffect, useState } from 'react'
import { gsap } from 'gsap'

export const useLightboxTransition = (ref, { selectors, isOpen }) => {
  const { bg, content, btnClose } = selectors
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  useEffect(() => {
    const $root = ref.current
    let tl

    if ($root) {
      const $bg = $root.querySelector(bg)
      const $content = $root.querySelector(content)
      const $btnClose = $root.querySelector(btnClose)

      if (isOpen) {
        tl = gsap.timeline({
          onComplete: () => setIsVideoPlaying(true),
        })
        tl.set($root, { display: 'flex', immediateRender: false })
        tl.to($bg, { opacity: 1, duration: 1 })
        tl.to($content, { opacity: 1, duration: 0.75 }, '=-0.75')
        tl.to($btnClose, { opacity: 1, duration: 0.75 })
      } else {
        tl = gsap.timeline({
          onComplete: () => setIsVideoPlaying(false),
        })
        tl.to($content, { opacity: 0, duration: 0.75 })
        tl.to($btnClose, { opacity: 0, duration: 0.75 }, '=-0.25')
        tl.to($bg, { opacity: 0, duration: 1 }, '=-0.5')
        tl.set($root, { display: 'none', immediateRender: false })
      }
    }

    return () => {
      tl?.kill()
    }
  }, [ref, bg, content, btnClose, isOpen])

  return isVideoPlaying
}
