import { useEffect } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Fade footer elements on scroll
 * Note the positioning is handled with `useFixedPosition()`
 */

export const useFooterTransition = (ref, { selector, isEnabled }) => {
  useEffect(() => {
    const $root = ref.current
    const $element = $root.querySelector(selector)

    let scrollTrigger

    if (isEnabled) {
      scrollTrigger = ScrollTrigger.create({
        start: 0,
        end: 50,
        onToggle: (self) => {
          gsap.to($element, {
            autoAlpha: !self.isActive && self.direction === 1 ? 0 : 1,
            duration: 0.5,
          })
        },
      })
    }

    return () => {
      scrollTrigger?.kill()
    }
  }, [ref, selector, isEnabled])
}
