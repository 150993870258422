import React from 'react'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { GLBackground } from './GLBackground'
import { Img } from '@/components/Img'
import mobileBg from '@/assets/mobileBg.png'
import * as styles from './Background.styles.scss'

const Background = () => {
  const { device } = useRecoilValue(browserState)

  return (
    <div>
      {device === 'desktop' ? (
        <GLBackground />
      ) : (
        <Img src={mobileBg} className={styles.bgImage} objectFit="cover" />
      )}
    </div>
  )
  // return <Img className={styles.Background} src={webp} />
}

export { Background }
