/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const TransitionService = require('@/services/TransitionService').default

exports.onRouteUpdate = () => {
  TransitionService.in()
}

/**
 * Implement React Helmet Provider
 */

const React = require('react')
const { HelmetProvider } = require('react-helmet-async')

exports.wrapRootElement = ({ element }) => {
  return <HelmetProvider>{element}</HelmetProvider>
}

/**
 * Reset scroll position when navigating, required for custom smooth scroll
 */

// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   // const { pathname } = location
//   // list of routes for the scroll-to-top-hook
//   // const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   //if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//   window.scrollTo(0, 0)
//   // }

//   return false
// }
