import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  browserState,
  fontsLoadedState,
  imagesLoadedState,
  loadingState,
  preloadsState,
} from '@/store'
import { useImageLoader } from '@/hooks/useImageLoader'
import { FontFaceLoader } from '@/components/FontFaceLoader'
import { getPreloadedImages } from '@/helpers/getPreloadedImages'

/**
 * Coordinate fonts and image preloading
 */

const PreloadService = ({ autoResolveDelay, isDebug, fonts }) => {
  const allFontsLoaded = useRecoilValue(fontsLoadedState)
  const allImagesLoaded = useRecoilValue(imagesLoadedState)
  const setLoadingState = useSetRecoilState(loadingState)
  const { device } = useRecoilValue(browserState)
  const { pathname, images } = useRecoilValue(preloadsState)

  useImageLoader(getPreloadedImages(images, device), {
    autoResolveDelay,
    pathname,
    isDebug,
  })

  useEffect(() => {
    // Only update loading state if pathname exists
    if (pathname) {
      if (allImagesLoaded && allFontsLoaded) {
        setLoadingState({ pathname, isLoaded: true })
      } else {
        setLoadingState({ pathname, isLoaded: false })
      }
    }
  }, [device, allFontsLoaded, allImagesLoaded])

  return (
    <div>
      <FontFaceLoader
        fonts={fonts}
        autoResolveDelay={autoResolveDelay}
        isDebug={isDebug}
      />
    </div>
  )
}

PreloadService.defaultProps = {
  fonts: [],
}

PreloadService.propTypes = {
  isDebug: PropTypes.bool,
  fonts: PropTypes.arrayOf(PropTypes.string),
  autoResolveDelay: PropTypes.number,
}

export { PreloadService }
