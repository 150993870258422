import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Icons } from '@/blocks/Icons'
import { Portal } from '@/components/Portal'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { ScrambledTextArray } from '@/components/ScrambledTextArray'
import { HexagonThumbs } from '@/components/HexagonThumbs'
import * as styles from './Outro.styles.scss'

const Outro = ({ description, isVisible, isFixedPosition, icons }) => {
  const { device } = useRecoilValue(browserState)

  return (
    <div>
      <Portal isEnabled={isFixedPosition}>
        <div
          className={classNames(styles.outro, {
            [styles.isFixedPosition]: isFixedPosition,
          })}
        >
          <Container indent={{ desktop: 2 }}>
            <RichText className={styles.outro__inner}>
              <ScrambledTextArray
                textArray={description}
                type="s1"
                className={styles.outro__text}
                isVisible={isVisible || !isFixedPosition}
              />
            </RichText>
            {!!icons?.length && device === 'desktop' && (
              <HexagonThumbs items={icons} isVisible={isVisible} />
            )}
          </Container>
        </div>
      </Portal>
      {device === 'mobile' && <Icons items={icons} />}
    </div>
  )
}

Outro.propTypes = {
  description: PropTypes.arrayOf(PropTypes.object),
  isVisible: PropTypes.bool,
  isFixedPosition: PropTypes.bool,
  icons: PropTypes.arrayOf(PropTypes.object),
}

export { Outro }
