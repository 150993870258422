import { getAspectRatio } from '@/helpers/getAspectRatio'

/**
 * Process Vimeo data from the Storyblok Vimeo plugin
 */

export const getVimeo = (vimeo) => {
  const vimeoResponse = vimeo.vimeo_oembed?.response || {}
  const width = vimeoResponse.width || 500
  const height = vimeoResponse.height || 400

  return {
    src: vimeo.vimeo_raw,
    width,
    height,
    aspectRatio: getAspectRatio(width, height),
  }
}
