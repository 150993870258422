import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import * as styles from './Hexagon.styles.scss'

import StrokeOuterSvg from './images/stroke-outer.inline.svg'
import innerSrc from './images/inner.svg'
import innerHoverSrc from './images/inner-hover.svg'

import RotatedStrokeOuterSvg from './images/rotated-stroke-outer.inline.svg'
import rotatedInnerSrc from './images/rotated-inner.svg'
import rotatedInnerHoverSrc from './images/rotated-inner-hover.svg'

const Hexagon = ({
  children,
  href,
  target,
  onClick,
  isHovering,
  isRotated,
}) => {
  return (
    <Link
      className={classNames(styles.Hexagon, {
        [styles.isHovering]: isHovering,
        [styles.isRotated]: isRotated,
      })}
      to={href}
      target={target}
      onClick={onClick}
    >
      <div className={styles.inner}>
        <img
          src={isRotated ? rotatedInnerSrc : innerSrc}
          className={styles.fillInner}
          alt=""
        />
        <img
          src={isRotated ? rotatedInnerHoverSrc : innerHoverSrc}
          className={styles.fillInnerHover}
          alt=""
        />
        <div className={styles.children}>{children}</div>
      </div>
      {isRotated ? (
        <RotatedStrokeOuterSvg className={styles.strokeOuter} />
      ) : (
        <StrokeOuterSvg className={styles.strokeOuter} />
      )}
    </Link>
  )
}

Hexagon.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  isHovering: PropTypes.bool,
  isRotated: PropTypes.bool,
}

export { Hexagon }
