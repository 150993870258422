import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { ScrambledText } from '@/components/ScrambledText'
import { Btn } from '@/components/Btn'
import * as styles from './HomeFeature.styles.scss'

import { AttackScene } from './components/AttackScene'
import { PlanetsScene } from './components/PlanetsScene'
import { ShipsScene } from './components/ShipsScene'
import { SpeciesScene } from './components/SpeciesScene'

const SCENES = {
  gameplay: AttackScene,
  planets: PlanetsScene,
  ships: ShipsScene,
  species: SpeciesScene,
}

const HomeFeature = ({
  description,
  heading,
  link,
  subheading,
  scene,
  layout,
  images,
}) => {
  const hasScene = !!SCENES[scene]
  const Scene = hasScene ? SCENES[scene] : 'div'

  return (
    <Block
      className={classNames(styles.HomeFeature, {
        [styles[`${layout}Layout`]]: layout,
      })}
      hasPaddingTop={!hasScene}
      hasPaddingBottom={!hasScene}
      bgColor="custom"
    >
      <Scene images={images}>
        <Container indent={{ desktop: 2, mobile: 0 }}>
          <div className={styles.grid}>
            <div className={styles.text}>
              {subheading && (
                <Heading type="s4" tag="h2" className={styles.subheading}>
                  {subheading}
                </Heading>
              )}
              {heading && (
                <Heading
                  type={{ desktop: 'h2', mobile: 'h3' }}
                  tag="h3"
                  className={styles.heading}
                >
                  {/* {heading} */}
                  <ScrambledText text={heading} />
                </Heading>
              )}
              {description && (
                <RichText className={styles.description} type="p1">
                  {description}
                </RichText>
              )}
              {link && (
                <div className={styles.btn}>
                  <Btn href={link.url} target={link.target}>
                    {link.title}
                  </Btn>
                </div>
              )}
            </div>
          </div>
        </Container>
      </Scene>
    </Block>
  )
}

HomeFeature.propTypes = {
  description: PropTypes.node,
  heading: PropTypes.node,
  link: PropTypes.object,
  subheading: PropTypes.node,
  scene: PropTypes.oneOf(['planets', 'gameplay', 'ships', 'species']),
  layout: PropTypes.oneOf(['left', 'right', 'center']),
  images: PropTypes.object,
}

export { HomeFeature }
