import { atom } from 'recoil'

/**
 * Store app loading state
 *
 * @usage
 * const [loadingProgress, setLoadingProgress] = useRecoilValue(loadingProgressState)
 */

export const loadingProgressState = atom({
  key: 'loadingProgressState', // unique ID (with respect to other atoms/selectors)
  default: null,
})
