// extracted by mini-css-extract-plugin
export var Slideshow = "b0_g7";
export var aniSpin = "b0_d";
export var gradient = "b0_fX";
export var hasBorders = "b0_g8";
export var inner = "b0_v";
export var rightLayout = "b0_c8";
export var sizer = "b0_X";
export var slideContent = "b0_g9";
export var slideContent__content = "b0_hd";
export var slideContent__grid = "b0_hc";
export var slideContent__inner = "b0_hb";