import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { Btn } from '@/components/Btn'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { Icon } from '@/components/Icon'
import * as styles from './TokensOverview.styles.scss'
import classNames from 'classnames'

const TokensOverview = ({
  heading,
  description,
  items,
  link,
  preloadedImages,
}) => {
  const { silicium, iron } = preloadedImages

  return (
    <Block className={styles.TokensOverview}>
      <Container indent={{ desktop: 2 }}>
        <div className={styles.image01} data-speed="0.9">
          <ResponsiveImg {...iron} isLazy={false} enableGlitchCanvas={true} />
        </div>
        {heading && (
          <Heading
            className={styles.heading}
            type={{ desktop: 'h1', mobile: 'h2' }}
            tag="h3"
          >
            {heading}
          </Heading>
        )}
        <div className={styles.grid}>
          <div className={styles.text}>
            {description && (
              <RichText className={styles.description} type="p1">
                {description}
              </RichText>
            )}
            {link && (
              <div className={styles.btn}>
                <Btn href={link.url} target={link.target}>
                  {link.title}
                </Btn>
              </div>
            )}
          </div>
          {!!items?.length && (
            <div className={styles.items}>
              <ul className={styles.items__list}>
                {items.map((item) => {
                  return (
                    <li
                      key={item.uid}
                      className={classNames(styles.item, {
                        [styles.isHighlighted]: item.appearance === 'highlight',
                      })}
                    >
                      <div className={styles.item__head}>
                        {item.icon && (
                          <Icon
                            className={styles.item__icon}
                            variant={item.icon}
                            theme="white"
                          />
                        )}
                        <div className={styles.item__title}>{item.title}</div>
                      </div>
                      <div className={styles.item__label}>{item.label}</div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
        <div className={styles.image02} data-speed="1.2">
          <ResponsiveImg
            {...silicium}
            isLazy={false}
            enableGlitchCanvas={true}
          />
        </div>
      </Container>
    </Block>
  )
}

TokensOverview.propTypes = {
  heading: PropTypes.node,
  description: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.object,
  preloadedImages: PropTypes.object,
}

export { TokensOverview }
