import { useEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const useOuterScroll = (ref, { selectors, isEnabled }) => {
  const { element, trigger } = selectors

  useEffect(() => {
    const $root = ref.current
    const $element = $root.querySelector(element)
    const $trigger = $root.querySelector(trigger)

    let scrollTrigger

    if (isEnabled) {
      scrollTrigger = ScrollTrigger.create({
        trigger: $trigger,
        start: 'top top',
        end: 'bottom bottom',
        pin: $element,
      })
    }

    return () => {
      scrollTrigger?.kill()
    }
  }, [ref, isEnabled, element, trigger])
}
