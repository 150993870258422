import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container } from '@/components/Container'

import { SlideContent } from '../SlideContent'
import { PlanetScene } from '../PlanetScene'
import { SpeciesScene } from '../SpeciesScene'
import { TokenScene } from '../TokenScene'

import * as styles from './CarouselSlide.styles.scss'

const SCENES = {
  planet: PlanetScene,
  species: SpeciesScene,
  token: TokenScene,
}

const CarouselSlide = ({ className, scene, media, ...props }) => {
  const Scene = SCENES[scene]

  return (
    <div className={classNames(styles.CarouselSlide, className)}>
      <div className={styles.head}>
        {Scene && <Scene media={media} isVisible={true} />}
      </div>
      <div className={styles.navSpacer} />
      <div>
        <Container>
          <SlideContent {...props} />
        </Container>
      </div>
    </div>
  )
}

CarouselSlide.propTypes = {
  className: PropTypes.string,
}

export { CarouselSlide }
