// extracted by mini-css-extract-plugin
export var Frame = "bj_dk";
export var aniSpin = "bj_d";
export var borderBottom = "bj_dm";
export var borderLeft = "bj_dp";
export var borderRight = "bj_dq";
export var borderTop = "bj_dl";
export var cornerBottomLeft = "bj_dt";
export var cornerBottomRight = "bj_dv";
export var cornerTopLeft = "bj_dr";
export var cornerTopRight = "bj_ds";
export var isActive = "bj_dn";