import React, { useEffect } from 'react'
import FontFaceObserver from 'fontfaceobserver'
import { useRecoilState } from 'recoil'
import { fontsLoadedState } from '@/store'
import PropTypes from 'prop-types'

const FontFaceLoader = ({
  fonts,
  autoResolveDelay,
  renderLoader,
  children,
  isDebug,
}) => {
  const [isLoaded, setIsLoaded] = useRecoilState(fontsLoadedState)

  useEffect(() => {
    const fontObservers = []
    fonts.forEach((fontFamily) => {
      const observer = new FontFaceObserver(fontFamily)
      fontObservers.push(observer.load(null, autoResolveDelay))
    })

    Promise.all(fontObservers).then(
      () => {
        if (isDebug) console.log('✅ All fonts loaded')
        setIsLoaded(true)
      },
      () => {
        if (isDebug) console.warn('One or more webfonts failed to load') // eslint-disable-line no-console
        setIsLoaded(true)
      }
    )
  }, [fonts, autoResolveDelay, setIsLoaded, isDebug])

  return <>{isLoaded ? children : renderLoader}</>
}

FontFaceLoader.defaultProps = {
  fonts: [],
  autoResolveDelay: 3000,
}

FontFaceLoader.propTypes = {
  fonts: PropTypes.array,
  autoResolveDelay: PropTypes.number,
  renderLoader: PropTypes.node,
  children: PropTypes.node,
  isDebug: PropTypes.bool,
}

export { FontFaceLoader }
