import React from 'react'
import PropTypes from 'prop-types'
import { getThemePropType } from '@/helpers/getThemePropType'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { Icon } from '@/components/Icon'
import { Frame } from '@/components/Frame'
import { Expandable } from '@/components/Expandable'
import * as styles from './FeatureGrid.styles.scss'

const FeatureGrid = ({ items, theme }) => {
  const { device } = useRecoilValue(browserState)

  return (
    <Block>
      {items?.length && (
        <Container indent={{ desktop: 2 }}>
          {device === 'mobile' ? (
            <div>
              {items.map((item) => {
                return (
                  <Expandable
                    key={item.uid}
                    header={
                      <div className={styles.expandableHeading}>
                        {item.heading}
                      </div>
                    }
                  >
                    {item.description}
                  </Expandable>
                )
              })}
            </div>
          ) : (
            <div className={styles.grid}>
              {items.map((item) => {
                return (
                  <div key={item.uid} className={styles.item}>
                    <Frame />
                    <div className={styles.item__bg} />
                    <div className={styles.item__inner}>
                      <div className={styles.item__icon}>
                        <Icon
                          variant={item.icon}
                          className={styles.item__icon__svg}
                          theme={theme}
                        />
                      </div>
                      <div className={styles.item__footer}>
                        <Heading tag="h3" type="h4" className={styles.heading}>
                          {item.heading}
                        </Heading>
                        <RichText type="p1" className={styles.description}>
                          {item.description}
                        </RichText>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </Container>
      )}
    </Block>
  )
}

FeatureGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  theme: getThemePropType,
}

export { FeatureGrid }
