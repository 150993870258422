import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { Hexagon } from '@/components/Hexagon'
import ExternalSvg from '@/assets/external-link.inline.svg'
import * as styles from './LogosGrid.styles.scss'

const LogosGrid = ({ heading, logos }) => {
  const { device } = useRecoilValue(browserState)

  return (
    <Block>
      <Container indent={{ desktop: 2 }}>
        {heading && (
          <Heading tag="h3" type="h3" className={styles.heading}>
            {heading}
          </Heading>
        )}
        {!!logos?.length && (
          <div className={styles.logos}>
            {logos.map((item) => {
              const hasLink = !!item.link?.url
              const attributes = hasLink
                ? {
                    'data-tip': '',
                    'data-for': item.uid,
                  }
                : {}

              return (
                <Hexagon
                  href={item.link?.url}
                  target={item.link?.target}
                  key={item.uid}
                  isRotated={device === 'mobile'}
                >
                  <div className={styles.logo} {...attributes}>
                    <img
                      src={item.imageSrc}
                      alt={item.title}
                      className={styles.logo__img}
                    />
                    {hasLink && (
                      <ReactTooltip id={item.uid} arrowColor="transparent">
                        <span className={styles.toolTipWrap}>
                          Visit {item.title}
                          <ExternalSvg className={styles.toolTip__icon} />
                        </span>
                      </ReactTooltip>
                    )}
                  </div>
                </Hexagon>
              )
            })}
          </div>
        )}
      </Container>
    </Block>
  )
}

LogosGrid.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.object),
  heading: PropTypes.node,
}

export { LogosGrid }
