import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useSlideshowTransition } from '../../hooks/useSlideshowTransition'
import { Slideshow } from '../Slideshow'
import { SpeciesScene } from '../SpeciesScene'
import { TransitionCanvas } from '../TransitionCanvas'

import * as styles from './SlideshowSpecies.styles.scss'

const SlideshowSpecies = ({ items }) => {
  const [isEffectTransitioning, setIsEffectTransitioning] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleClickThumb = (index) => setCurrentIndex(index)
  const ref = useRef()

  const {
    isTransitioning,
    foregroundSrcA,
    foregroundSrcB,
    backgroundSrcA,
    backgroundSrcB,
  } = useSlideshowTransition(ref, {
    currentIndex,
    selectors: {
      foregroundMedia: `[data-foreground-media] img`,
      backgroundMedia: `[data-background-media] img`,
    },
  })

  return (
    <Slideshow
      ref={ref}
      onNavigate={handleClickThumb}
      currentIndex={currentIndex}
      renderTransitions={
        <div>
          <div className={styles.transitionBackgroundCanvas}>
            <div
              className={styles.transitionBackgroundCanvas__inner}
              data-speed="0.8"
            >
              <TransitionCanvas
                isTransitioning={isTransitioning}
                isEffectTransitioning={isEffectTransitioning}
                setIsEffectTransitioning={setIsEffectTransitioning}
                objectFit="cover"
                imageSrcA={backgroundSrcA}
                imageSrcB={backgroundSrcB}
              />
            </div>
          </div>
          <div className={styles.transitionCanvas}>
            <div className={styles.transitionCanvas__inner}>
              <TransitionCanvas
                isTransitioning={isTransitioning}
                isEffectTransitioning={isEffectTransitioning}
                setIsEffectTransitioning={setIsEffectTransitioning}
                objectFit="contain"
                objectPosition="bottom right"
                cropTextures={true}
                imageSrcA={foregroundSrcA}
                imageSrcB={foregroundSrcB}
              />
            </div>
          </div>
        </div>
      }
      items={items.map((item, index) => {
        return {
          ...item,
          renderScene: (
            <SpeciesScene
              media={item.media}
              isActive={currentIndex === index && !isEffectTransitioning}
              isVisible={!isEffectTransitioning}
            />
          ),
        }
      })}
    />
  )
}

SlideshowSpecies.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { SlideshowSpecies }
