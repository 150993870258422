import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player/lazy'
import { Portal } from '@/components/Portal'
import { BtnClose } from '@/components/BtnClose'
import { useLightboxTransition } from './hooks/useLightboxTransition'
import * as styles from './VideoLightbox.styles.scss'

const VideoLightbox = ({ url, aspectRatio, isOpen, onClickClose }) => {
  const ref = useRef()

  const isVideoPlaying = useLightboxTransition(ref, {
    isOpen: isOpen,
    selectors: {
      bg: `.${styles.bg}`,
      content: `.${styles.content}`,
      btnClose: `.${styles.btnClose}`,
    },
  })

  const handleClickClose = () => {
    if (typeof onClickClose === 'function') onClickClose()
  }

  if (!url) return

  return (
    <Portal>
      <div className={styles.VideoLightbox} ref={ref}>
        <button
          className={styles.bg}
          onClick={handleClickClose}
          aria-label="Close"
        />
        <div className={styles.btnClose}>
          <BtnClose onClick={handleClickClose} />
        </div>
        <div className={styles.content}>
          <div className={styles.video}>
            <div
              className={styles.sizer}
              style={{
                paddingTop: !!aspectRatio && `${100 / aspectRatio}%`,
              }}
            />
            <div className={styles.player}>
              {isVideoPlaying && (
                <ReactPlayer
                  playing={true}
                  volume={isOpen ? 1 : 0}
                  width={null}
                  height={null}
                  url={url}
                  controls={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

VideoLightbox.propTypes = {
  url: PropTypes.string,
  aspectRatio: PropTypes.number,
  isOpen: PropTypes.bool,
  onClickClose: PropTypes.func.isRequired,
}

export { VideoLightbox }
