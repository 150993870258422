import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Carousel } from '@/components/Carousel'
import { Heading } from '@/components/Heading'
import { Link } from '@/components/Link'
import { Media } from '@/components/Media'
import { SocialIcon } from '@/components/SocialIcon'
import { Container } from '@/components/Container'
import * as styles from './PeopleCarousel.styles.scss'

const PeopleCarousel = ({ heading, items, uid }) => {
  return (
    <Block>
      {heading && (
        <Container indent={{ desktop: 2 }}>
          <Heading tag="h3" type="h4" className={styles.heading}>
            {heading}
          </Heading>
        </Container>
      )}
      {!!items?.length && (
        <Carousel
          uid={uid}
          items={items.map((item) => {
            return {
              uid: item.uid,
              children: (
                <div className={styles.item__inner}>
                  <div className={styles.item__media}>
                    <Media {...item.media} isFull objectFit="cover" />
                  </div>
                  <div className={styles.item__headingWrap}>
                    <Heading tag="div" type={{ desktop: 'h5', mobile: 'h6' }}>
                      {item.name}
                    </Heading>
                    {!!item.links?.length && (
                      <div className={styles.item__links}>
                        {item.links?.map((item) => {
                          return (
                            <Link
                              key={item.uid}
                              to={item.url}
                              target="_blank"
                              aria-label={item.title}
                              className={styles.item__link}
                            >
                              <SocialIcon
                                variant={item.icon}
                                className={styles.item__link__icon}
                              />
                            </Link>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  {item.subheading && (
                    <Heading
                      tag="div"
                      type="s4"
                      className={styles.item__subheading}
                    >
                      {item.subheading}
                    </Heading>
                  )}
                </div>
              ),
            }
          })}
        />
      )}
    </Block>
  )
}

PeopleCarousel.propTypes = {
  heading: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
  uid: PropTypes.string,
}

export { PeopleCarousel }
