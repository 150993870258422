import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { BtnHexagon } from '@/components/BtnHexagon'
import { Icon } from '@/components/Icon'
import * as styles from './SlideshowNav.styles.scss'

const SlideshowNav = ({ items, onClickItem, currentIndex }) => {
  const handleClickThumb = (index) => {
    if (typeof onClickItem === 'function') onClickItem(index)
  }

  return (
    <nav className={styles.SlideshowNav}>
      {items.map((item, index) => {
        const id = `icon-${item.uid}`
        return (
          <div key={id} className={styles.item}>
            <ReactTooltip id={id} arrowColor="transparent">
              <span className={styles.toolTipWrap}>{item.heading}</span>
            </ReactTooltip>
            <BtnHexagon
              onClick={() => handleClickThumb(index)}
              isActive={currentIndex === index}
              ariaLabel={item.heading}
            >
              <div className={styles.thumb} data-tip data-for={id}>
                {item.icon ? (
                  <Icon
                    variant={item.thumbIcon}
                    className={styles.thumb__icon}
                  />
                ) : (
                  index + 1
                )}
              </div>
            </BtnHexagon>
          </div>
        )
      })}
    </nav>
  )
}

SlideshowNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onClickItem: PropTypes.func,
  currentIndex: PropTypes.number,
}

export { SlideshowNav }
