import { Img } from '@/components/Img'

import AllianceSvg from './images/alliance.inline.svg'
import CraftingSvg from './images/crafting.inline.svg'
import HeadquartersSvg from './images/headquarters.inline.svg'
import InfrastructureSvg from './images/infrastructure.inline.svg'
import MiningSvg from './images/mining.inline.svg'
import SkillTreeSvg from './images/skill-tree.inline.svg'
import SocialSvg from './images/social.inline.svg'
import TradingSvg from './images/trading.inline.svg'
import WarfareSvg from './images/warfare.inline.svg'

import SpaceBattlesSvg from './images/space-battles.inline.svg'
import AllianceConquestsSvg from './images/alliance-conquests.inline.svg'
import FixingPlanetsSvg from './images/fixing-planets.inline.svg'
import IncomeSvg from './images/income.inline.svg'
import LeaderboardSvg from './images/leaderboard.inline.svg'
import PlanetDefenceSvg from './images/planet-defence.inline.svg'

import BhalgrulSvg from './images/bhalgrul.inline.svg'
import EarthlingSvg from './images/earthling.inline.svg'
import VesnakSvg from './images/vesnak.inline.svg'
import X13sntSvg from './images/x13snt.inline.svg'

import AmberSvg from './images/amber.inline.svg'
import BlackstoneSvg from './images/blackstone.inline.svg'
import MetalSvg from './images/metal.inline.svg'
import NitrogenSvg from './images/nitrogen.inline.svg'
import OilSvg from './images/oil.inline.svg'
import OriSvg from './images/ori.inline.svg'
import QuartzSvg from './images/quartz.inline.svg'
import SiliciumSvg from './images/silicium.inline.svg'
import StarSvg from './images/star.inline.svg'
import SulfurSvg from './images/sulfur.inline.svg'

import DefenceSvg from './images/defence.inline.svg'
import DiplomacySvg from './images/diplomacy.inline.svg'
import StrengthSvg from './images/strength.inline.svg'
import TechnologySvg from './images/technology.inline.svg'

import planetDeserticSrc from './images/deserticPlanet.webp'
import planetLushSrc from './images/lushPlanet.webp'
import planetObsidianSrc from './images/obsidianPlanet.webp'
import planetOceanicSrc from './images/oceanicPlanet.webp'
import planetVolcanicSrc from './images/volcanicPlanet.webp'

import BoxSvg from './images/box.inline.svg'
import SaturnSvg from './images/saturn.inline.svg'
import SpaceshipConstructionSvg from './images/spaceship-construction.inline.svg'

export const VARIANTS = {
  desertic_planet: { component: Img, props: { src: planetDeserticSrc } },
  lush_planet: { component: Img, props: { src: planetLushSrc } },
  obsidian_planet: { component: Img, props: { src: planetObsidianSrc } },
  oceanic_planet: { component: Img, props: { src: planetOceanicSrc } },
  volcanic_planet: { component: Img, props: { src: planetVolcanicSrc } },

  alliance: { component: AllianceSvg },
  crafting: { component: CraftingSvg },
  headquarters: { component: HeadquartersSvg },
  infrastructure: { component: InfrastructureSvg },
  mining: { component: MiningSvg },
  skill_tree: { component: SkillTreeSvg },
  social: { component: SocialSvg },
  trading: { component: TradingSvg },
  warfare: { component: WarfareSvg },
  space_battles: { component: SpaceBattlesSvg },
  alliance_conquests: { component: AllianceConquestsSvg },
  fixing_planets: { component: FixingPlanetsSvg },
  income: { component: IncomeSvg },
  leaderboard: { component: LeaderboardSvg },
  planet_defence: { component: PlanetDefenceSvg },

  bhalgrul: { component: BhalgrulSvg },
  earthling: { component: EarthlingSvg },
  vesnak: { component: VesnakSvg },
  x13snt: { component: X13sntSvg },

  amber: { component: AmberSvg },
  blackstone: { component: BlackstoneSvg },
  metal: { component: MetalSvg },
  nitrogen: { component: NitrogenSvg },
  oil: { component: OilSvg },
  ori: { component: OriSvg },
  quartz: { component: QuartzSvg },
  silicium: { component: SiliciumSvg },
  star: { component: StarSvg },
  sulfur: { component: SulfurSvg },

  defence: { component: DefenceSvg },
  diplomacy: { component: DiplomacySvg },
  strength: { component: StrengthSvg },
  technology: { component: TechnologySvg },

  box: { component: BoxSvg },
  saturn: { component: SaturnSvg },
  spaceship_construction: { component: SpaceshipConstructionSvg },
}
