import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useFrameAnimation } from '@/hooks/useFrameAnimation'
import { Img } from '@/components/Img'
import * as styles from './ScrollFrameAnimation.styles.scss'

import sunGlowSrc from '@/assets/images/sun-glow.png' // TODO: to ensure preloaded

gsap.registerPlugin(ScrollTrigger)

/* 
https://codepen.io/osublake/pen/VwaKMpw/2152a28cffe2c2c0cca8a3e47f7b21c6?editors=0010
*/

const ScrollFrameAnimation = ({
  width,
  height,
  aspectRatio,
  frames,
  maskImage,
  bgImage,
  showGlow,
  fadeOutOpacity,
  scrollTriggerRef,
  scrollTriggerOptions = {},
  isDebug,
}) => {
  const { start, end, ease } = scrollTriggerOptions

  const { canvasRef, animationRef, renderAnimationFrame } = useFrameAnimation({
    frames,
    width,
    height,
    maskImage,
    bgImage,
  })
  const totalFrames = frames.length

  const glowAnimationStartState = { scale: 2, opacity: 1 }
  const bgGlowEndOpacity = 1 - 2.8

  const endBgOpacityState = fadeOutOpacity ? bgGlowEndOpacity : 1

  const [glowScale, setGlowScale] = useState(glowAnimationStartState.scale)
  const [glowOpacity, setGlowOpacity] = useState(
    glowAnimationStartState.opacity
  )

  const [glowScale2, setGlowScale2] = useState(glowAnimationStartState.scale)
  const [glowOpacity2, setGlowOpacity2] = useState(
    glowAnimationStartState.opacity
  )

  const glowAnimationRef = useRef({ ...glowAnimationStartState })

  useEffect(() => {
    const glowAnimationEndState = { scale: 0.5, opacity: 1 - 1.6 }

    let scrollTrigger
    let glowScrollTrigger

    if (scrollTriggerRef.current && canvasRef.current) {
      scrollTrigger = gsap.to(animationRef.current, {
        frame: totalFrames - 1,
        opacity: endBgOpacityState, // tween opacity if fading out bg with glow i.e. headerHomePlanet
        snap: 'frame',
        ease: ease || 'none',
        scrollTrigger: {
          trigger: scrollTriggerRef?.current,
          start,
          end: end || 'bottom top',
          scrub: 0.1, // 0.5,
          markers: isDebug,
        },
        onUpdate: renderAnimationFrame, // use animation onUpdate instead of scrollTrigger's onUpdate
      })
      if (showGlow) {
        const updateGlowState = () => {
          let curBaseOpacity = gsap.utils.clamp(
            0,
            1,
            glowAnimationRef.current.opacity
          )

          let curGlowScale = glowAnimationRef.current.scale
          let curGlowOpacity = gsap.utils.clamp(0, 1, Math.pow(curBaseOpacity, 0.4) * 0.5)

          setGlowScale(curGlowScale)
          setGlowOpacity(curGlowOpacity)

          let curGlowScale2 = curGlowScale * 0.45
          let curGlowOpacity2 = gsap.utils.clamp(0, 1, Math.pow(curBaseOpacity, 0.25) * 1.4)

          setGlowScale2(curGlowScale2)
          setGlowOpacity2(curGlowOpacity2)
        }

        glowScrollTrigger = gsap.to(glowAnimationRef.current, {
          scale: glowAnimationEndState.scale,
          opacity: glowAnimationEndState.opacity,
          scrollTrigger: {
            trigger: scrollTriggerRef?.current,
            start,
            end: end || 'bottom top',
            scrub: 0.1,
            markers: isDebug,
          },
          onUpdate: updateGlowState,
        })

        updateGlowState()
      }
    }

    return () => {
      scrollTrigger?.kill()
      glowScrollTrigger?.kill()
    }
  }, [
    isDebug,
    animationRef,
    canvasRef,
    renderAnimationFrame,
    endBgOpacityState,
    scrollTriggerRef,
    showGlow,
    totalFrames,
    start,
    end,
    ease,
  ])

  return (
    <div
      className={styles.ScrollFrameAnimation}
      style={{ paddingTop: !!aspectRatio && `${100 / aspectRatio}%` }}
    >
      <canvas className={styles.canvas} ref={canvasRef} />
      {showGlow && (
        <div>
          <div
            className={styles.glowElement}
            style={{ transform: `scale(${glowScale})`, opacity: glowOpacity }}
          >
            <Img src={sunGlowSrc} isLazy={false} />
          </div>
          <div
            className={styles.glowElement}
            style={{ transform: `scale(${glowScale2})`, opacity: glowOpacity2 }}
          >
            <Img src={sunGlowSrc} isLazy={false} />
          </div>
        </div>
      )}
    </div>
  )
}

ScrollFrameAnimation.defaultProps = {
  aspectRatio: 1,
  scrollTriggerOptions: {},
}

ScrollFrameAnimation.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  frames: PropTypes.array.isRequired,
  isDebug: PropTypes.bool,
  scrollTriggerOptions: PropTypes.object,
}

export { ScrollFrameAnimation }
