/**
 * Used syncing TransitionService with PageTransition etc
 */

export const DURATION_OUT = 800
export const DURATION_IN = 1200

export const TRANSITION_STATES = {
  READY: 'ready',
  OUT: 'out', // Old page transitions out
  IN: 'in', // New page transitions in
}
