import { useEffect } from 'react'
import { gsap } from 'gsap'

export const useTransition = (ref, { selectors, isVisible, delay }) => {
  const { item } = selectors

  useEffect(() => {
    const tl = gsap.timeline()
    const $items = ref.current?.querySelectorAll(item)

    if ($items) {
      if (isVisible) {
        tl.to($items, {
          opacity: 1,
          duration: 0.5, // 0.75,
          stagger: 0.1, // 0.35,
          delay,
          overwrite: true,
        })
      } else {
        tl.to($items, { opacity: 0, duration: 0.5 })
      }
    }

    return () => {
      tl.kill()
    }
  }, [ref, item, isVisible, delay])
}
