export const getPreloadedImages = (images, device) => {
  const array = []

  Object.keys(images).forEach((i) => {
    if (typeof images[i] !== 'object')
      console.warn('⚠️ Invalid Image format', i)

    const { src, frames, mobile, bgImage, maskImage } = images[i]

    if (device === 'mobile' && mobile?.src) {
      array.push(mobile.src)
    } else if (src && !array.includes(src)) {
      array.push(src)
    }

    if (bgImage) {
      array.push(bgImage)
    }

    if (maskImage) {
      array.push(maskImage)
    }

    // if frames
    if (frames) {
      frames.forEach((j) => {
        if (!array.includes(j)) array.push(j)
      })
    }
  })

  return array
}
