import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Heading } from '@/components/Heading'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { MouseFrameAnimation } from '@/components/MouseFrameAnimation'
import * as styles from './SpaceshipBlueprint.styles.scss'
import BlueprintSvg from './images/blueprint.inline.svg'

const SpaceshipBlueprint = ({ item }) => {
  const { device } = useRecoilValue(browserState)
  const [rolledOver, setRolledOver] = useState(false)

  const spaceShipRotationSpeed = 5 // number of cycles to spin per second (negative for clockwise)
  const driveAutoAnimateWithMousePosition = device === 'desktop' // only control spin rotation on desktop

  const onMouseOver = () => setRolledOver(true)
  const onMouseOut = () => setRolledOver(false)

  return (
    <div
      className={styles.item}
      onMouseOver={() => onMouseOver()}
      onMouseOut={() => onMouseOut()}
    >
      <div className={styles.frame}>
        <BlueprintSvg className={styles.frame__svg} />
        <div className={styles.frame__cornerTopRight} />
        <div className={styles.frame__cornerBottomLeft} />
      </div>
      <div className={styles.item__inner}>
        <div className={styles.item__image}>
          <ResponsiveImg
            {...item.blueprint}
            className={styles.item__blueprint}
            isLazy={false}
            enableGlitchCanvas={true}
          />
          <div className={styles.item__animation}>
            {item.animationFrames && (
              <MouseFrameAnimation
                {...item.animationFrames}
                isActive={rolledOver} // only update animation when blueprint is rolled over
                autoAnimateSpeed={spaceShipRotationSpeed} // set shift to auto rotate
                minAutoAnimateSpeed={0.8} // minimum minimum speed for auto animation
                driveAutoAnimateWithMousePosition={
                  driveAutoAnimateWithMousePosition
                } // set animation to play forward or reverse depending on mouse position right or left
                useCanvasBoundsForMousePosition={true} // use position of mouse inside canvas bounds to control frame position
                mousePositionBoundsScale={0.33} // amount to scale mouse bounds to reach max autoAnimateSpeed
                enableGlitchEffect={true}
              />
            )}
          </div>
        </div>
        {item.subheading && (
          <Heading tag="h4" type="s4" className={styles.item__subheading}>
            {item.subheading}
          </Heading>
        )}
        {item.heading && (
          <Heading tag="h5" type={{ desktop: 'h2', mobile: 'h3' }}>
            {item.heading}
          </Heading>
        )}
      </div>
    </div>
  )
}

export { SpaceshipBlueprint }
