import { useEffect, useRef } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ImageLoaderService } from './resources/ImageLoaderService'
import { imagesLoadedState, loadingProgressState } from '@/store'

export const useImageLoader = (
  images,
  { autoResolveDelay, isDebug, pathname }
) => {
  const setLoadingProgress = useSetRecoilState(loadingProgressState)
  const [, setIsLoaded] = useRecoilState(imagesLoadedState)
  const autoResolveTimeout = useRef()

  useEffect(() => {
    const handleError = () => {
      if (isDebug) console.warn('⛔️ Image loading error')
    }

    const handleSuccess = () => {
      if (isDebug) console.info('✅ All images loaded')
      console.log('💈 All images loaded')
      setIsLoaded(true)

      if (autoResolveTimeout.current) {
        clearTimeout(autoResolveTimeout.current)
        autoResolveTimeout.current = null
      }
    }

    const loadImages = () => {
      // Reset loading state
      setLoadingProgress(null)
      setIsLoaded(false)

      ImageLoaderService.loadImages(images, {
        isDebug,
        onSuccess: () => {
          setLoadingProgress((prevState) => {
            const prevTotalLoaded = prevState ? prevState.totalLoaded : 0
            const totalLoaded = prevTotalLoaded + 1
            const totalImages = images.length
            const progress = Math.min(totalLoaded / totalImages, 1)

            return {
              totalLoaded,
              progress: progress,
              percentage: `${Math.min(Math.ceil(progress * 100), 100)}%`,
            }
          })
        },
      }).then(handleSuccess, handleError)

      if (autoResolveDelay) {
        autoResolveTimeout.current = setTimeout(() => {
          if (isDebug) {
            console.warn(
              `Skip preload, loading images took longer than ${autoResolveDelay}ms`
            )
          }
          handleSuccess()
        }, autoResolveDelay)
      }
    }

    // if (isLoaded || !images || images.length === 0) {
    // handleSuccess()
    // } else {
    if (pathname) {
      console.log(`💈 Loading ${images?.length} images for ${pathname}`)
      loadImages()
    } else {
      // console.log('💈 Dont load images - no UID', pathname)
    }
    // }

    return () => {
      if (autoResolveTimeout.current) {
        clearTimeout(autoResolveTimeout.current)
        autoResolveTimeout.current = null
      }
    }
  }, [autoResolveDelay, pathname, setIsLoaded, isDebug]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
