import { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
// import { useSetRecoilState } from 'recoil'
// import { scrollLockState } from '@/store'

const MIN_WAIT_SECONDS = 1

export const useTransition = (ref, { isLoading, selectors }) => {
  // const setIsScrollLocked = useSetRecoilState(scrollLockState)
  const { icon, children, loader } = selectors
  const hasWaited = useRef()
  const minWaitTimeout = useRef()

  useLayoutEffect(() => {
    const $loader = ref.current.querySelector(loader)
    const $icon = $loader.querySelector(icon)
    const $children = ref.current.querySelector(children)
    let tl

    if (isLoading) {
      hasWaited.current = false
      minWaitTimeout.current = setTimeout(() => {
        hasWaited.current = true
      }, MIN_WAIT_SECONDS * 1000)

      console.log('Show Preloader')
      // setIsScrollLocked(true)
      tl = gsap.timeline()
      tl.set($icon, { opacity: 1 })
      tl.to($children, { opacity: 0, duration: 0.5 })
      tl.to($loader, {
        autoAlpha: 1,
        duration: 0.5,
      })
    } else {
      console.log('Hide Preloader')
      // setIsScrollLocked(false)
      clearTimeout(minWaitTimeout.current)
      tl = gsap.timeline({ delay: hasWaited.current ? 0 : MIN_WAIT_SECONDS })
      tl.to($icon, {
        overwrite: true,
        opacity: 0,
        duration: 0.25,
      })
      tl.to($loader, {
        overwrite: true,
        autoAlpha: 0,
        duration: 0,
      })
      tl.to($children, { opacity: 1, duration: 0.5 })
    }

    // return () => {
    // console.log('Cleanup Preloader animation')

    // tl.kill()
    // }
  }, [ref, isLoading, icon, loader, children])
}
