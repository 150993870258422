import React from 'react'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Img } from '@/components/Img'
import PropTypes from 'prop-types'

const ResponsiveImg = ({ mediaType, mobile, ...props }) => {
  const { device } = useRecoilValue(browserState)

  if (mediaType !== 'image') {
    console.warn(`Incorrect mediaType supplied to ResponsiveImg: ${mediaType}`)
    return
  }

  if (device === 'mobile' && mobile?.src) {
    return <Img {...props} {...mobile} />
  }

  return <Img {...props} />
}

ResponsiveImg.propTypes = {
  mediaType: PropTypes.string,
  mobile: PropTypes.object,
}

export { ResponsiveImg }
