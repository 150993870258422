import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'

import { Carousel } from './components/Carousel'
import { SlideshowSpecies } from './components/SlideshowSpecies'
import { SlideshowPlanets } from './components/SlideshowPlanets'
import { SlideshowTokens } from './components/SlideshowTokens'

const SLIDESHOW_VARIANTS = {
  planet_slideshow: SlideshowPlanets,
  species_slideshow: SlideshowSpecies,
  token_slideshow: SlideshowTokens,
}

const FeatureSlideshow = ({ items, variant }) => {
  const { device } = useRecoilValue(browserState)
  const SlideshowComponent = SLIDESHOW_VARIANTS[variant]

  return (
    <>
      {device === 'desktop' ? (
        <>{SlideshowComponent && <SlideshowComponent items={items} />}</>
      ) : (
        <Carousel items={items} />
      )}
    </>
  )
}

FeatureSlideshow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf([
    'planet_slideshow',
    'token_slideshow',
    'species_slideshow',
  ]),
}

export { FeatureSlideshow }
