// extracted by mini-css-extract-plugin
export var aniSpin = "bt_d";
export var description = "bt_bP";
export var expandableHeading = "bt_fb";
export var grid = "bt_bj";
export var heading = "bt_bk";
export var item = "bt_w";
export var item__bg = "bt_b7";
export var item__footer = "bt_fc";
export var item__icon = "bt_b4";
export var item__icon__svg = "bt_fd";
export var item__inner = "bt_2";