import { useEffect, useCallback } from 'react'
import { gsap } from 'gsap'
import { DURATION_OUT, DURATION_IN } from '@/constants/pageTransition'

// convert to seconds from milliseconds
const config = {
  DURATION_OUT: DURATION_OUT / 1000,
  DURATION_IN: DURATION_IN / 1000,
}

export const useTransition = (ref, { transitionState }) => {
  const playTransitionOut = useCallback(() => {
    const $root = ref.current
    const tl = gsap.timeline()
    tl.fromTo(
      $root,
      { opacity: 1 },
      { opacity: 0, duration: config.DURATION_OUT, ease: 'power1.in' }
    )
  }, [ref])

  const playTransitionIn = useCallback(() => {
    const $root = ref.current
    const tl = gsap.timeline()
    tl.to(
      $root,
      // { opacity: 1, duration: 0.4, delay: 0.4, ease: 'power1.out' },
      {
        opacity: 1,
        duration: config.DURATION_IN * 0.8,
        delay: config.DURATION_IN * 0.2,
        ease: 'power1.out',
      },
      0
    )
  }, [ref])

  useEffect(() => {
    switch (transitionState) {
      case 'in':
        playTransitionIn()
        break
      case 'out':
        playTransitionOut()
        break
      default:
        break
    }
  }, [transitionState, playTransitionOut, playTransitionIn])
}
