// extracted by mini-css-extract-plugin
export var aniSpin = "bY_d";
export var icon = "bY_n";
export var icon__image = "bY_g3";
export var icon__inner = "bY_dM";
export var icon__label = "bY_g4";
export var icons = "bY_g2";
export var isFixedPosition = "bY_gZ";
export var outro = "bY_gY";
export var outro__inner = "bY_g0";
export var outro__text = "bY_g1";