// extracted by mini-css-extract-plugin
export var Hexagon = "bq_dX";
export var aniSpin = "bq_d";
export var children = "bq_dN";
export var fillInner = "bq_dZ";
export var fillInnerHover = "bq_d0";
export var inner = "bq_v";
export var isHovering = "bq_d1";
export var isRotated = "bq_dY";
export var strokeInner = "bq_d3";
export var strokeOuter = "bq_d2";