import { useStaticQuery, graphql } from 'gatsby'
import { getLink } from '@/storyblok/helpers/getLink'
import faviconImg from '@/assets/favicon.png'
import faviconDarkmodeImg from '@/assets/favicon-darkmode.png'
import { getRichText } from '@/storyblok/helpers/getRichText'
import { getCtaButton } from '@/storyblok/helpers/getCtaButton'

/**
 * Pull in Storyblok config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyMetadata: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        global: storyblokEntry(slug: { eq: "global" }) {
          id
          full_slug
          content
        }
      }
    `
  )

  const gatsbyMetadata = data.gatsbyMetadata.siteMetadata || {}
  const storyblokGlobals = data.global ? JSON.parse(data.global.content) : {}

  const nav = []
  storyblokGlobals.header_nav?.forEach((item) => {
    nav.push(
      getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
        target: item.target,
      })
    )
  })

  const footerNav = []
  storyblokGlobals.footer_nav?.forEach((item) => {
    const links = []
    item.links?.forEach((link) => {
      links.push({
        appearance: link.appearance,
        ...getLink({
          uid: link._uid,
          title: link.title,
          link: link.link,
          target: link.target,
        }),
      })
    })
    footerNav.push({
      uid: item._uid,
      title: item.title,
      links,
    })
  })

  const headerCtas = []
  storyblokGlobals.header_ctas?.forEach((item) => {
    headerCtas.push({
      appearance: item.appearance,
      ...getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
        target: item.target,
      }),
    })
  })

  const siteTitle = storyblokGlobals.title || gatsbyMetadata.title

  return {
    metadata: {
      title: siteTitle,
      description: storyblokGlobals.description || gatsbyMetadata.description,
      subtitle: storyblokGlobals.subtitle || gatsbyMetadata.subtitle,
      image: storyblokGlobals.image?.filename || gatsbyMetadata.image,
      siteUrl: gatsbyMetadata.siteUrl,
    },
    favicon: {
      default: faviconImg,
      darkMode: faviconDarkmodeImg || faviconImg,
    },
    header: {
      title: siteTitle,
      nav,
      ctas: headerCtas,
    },
    menu: {
      nav,
    },
    footer: {
      notes: getRichText(storyblokGlobals.footer_notes),
      nav: footerNav,
    },
    cta: {
      heading: storyblokGlobals.cta_heading,
      button: getCtaButton(storyblokGlobals.cta_button),
    },
  }
}
