import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getThemeCssVar } from '@/helpers/getThemeCssVar'
import { getThemePropType } from '@/helpers/getThemePropType'
import { VARIANTS } from './variants'

import * as styles from './Icon.styles.scss'

const Icon = ({ variant, className, theme }) => {
  const Icon = VARIANTS[variant]?.component
  const props = VARIANTS[variant]?.props || {}

  if (Icon) {
    return (
      <Icon
        className={classNames(
          styles.Icon,
          { [styles.hasTheme]: !!theme },
          className
        )}
        style={getThemeCssVar(theme)}
        {...props}
      />
    )
  }
  return null
}

Icon.propTypes = {
  className: PropTypes.string,
  variant: (props, propName, componentName) => {
    if (!VARIANTS[props[propName]]) {
      return new Error(
        `Invalid variant "${props[propName]}" supplied to "${componentName}"`
      )
    }
  },
  theme: getThemePropType,
}

export { Icon }
