import React, { useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { Hexagon } from '@/components/Hexagon'
import { useTransition } from './HexagonGrid.gsap'
import * as styles from './HexagonGrid.styles.scss'

/**
 * Usage:
 *  <HexagonGrid
 *    items={items.map((item) => {
 *      return {
 *        uid: item.uid,
 *        children: (<div>Example</div>),
 *      }
 *    })}
 *  />
 */

const HexagonGrid = ({ items, isVisible, size, delay }) => {
  const { device } = useRecoilValue(browserState)
  const ref = useRef()

  useTransition(ref, {
    selectors: {
      item: `.${styles.item}`,
    },
    delay: delay,
    isVisible,
  })

  const isRotated = device === 'mobile'

  return (
    <div
      className={classNames(styles.HexagonGrid, {
        [styles[`${size}Size`]]: !!size,
        [styles.isRotated]: isRotated,
      })}
      ref={ref}
    >
      <div className={styles.inner}>
        {items.map(({ children, uid, ...props }) => {
          return (
            <div className={styles.item} key={uid}>
              <div className={styles.item__inner}>
                <Hexagon {...props} isRotated={isRotated}>
                  {children}
                </Hexagon>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

HexagonGrid.defaultProps = {
  isVisible: true,
  size: 'large',
  delay: 0,
}

HexagonGrid.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  isRotated: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium']),
  delay: PropTypes.number,
}

export { HexagonGrid }
