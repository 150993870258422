import React from 'react'
import PropTypes from 'prop-types'

import { useTransitionService } from '@/services/TransitionService'
import { ResizeService } from '@/services/ResizeService'
import { PreloadService } from '@/services/PreloadService'
import { Metadata } from '@/components/Metadata'
import { PageTransition } from '@/components/PageTransition'
import { Background } from '@/components/Background'
import { Header } from '@/components/Header'
import { Menu } from '@/components/Menu'
import { Footer } from '@/components/Footer'
import { Preloader } from '@/components/Preloader'
import { SmoothScroll } from '@/components/SmoothScroll'

import '@/styles/main.scss'
import * as styles from './Layout.styles.scss'

const Layout = ({ site, page, children }) => {
  useTransitionService({ isDebug: true })

  return (
    <div>
      <Metadata
        title={page.title}
        defaultTitle={
          site.metadata.subtitle
            ? `${site.metadata.title}: ${site.metadata.subtitle}`
            : site.metadata.title
        }
        titleTemplate={`%s — ${site.metadata.title}`}
        favicon={site.favicon}
        description={site.metadata.description}
        image={page.image || site.metadata.image}
        siteUrl={site.metadata.siteUrl}
        path={page.path}
      />
      <ResizeService>
        <PreloadService
          pageData={page}
          autoResolveDelay={30000}
          fonts={['Monument Extended', 'NB Architekt Std', 'GT America Mono']}
          // isDebug
        />
        <Background />
        <Menu {...site.menu} />
        <Header {...site.header} />
        <Preloader>
          <PageTransition>
            <SmoothScroll>
              <div className={styles.Layout}>
                <main className={styles.main}>{children}</main>
                <Footer {...site.footer} />
              </div>
            </SmoothScroll>
          </PageTransition>
        </Preloader>
      </ResizeService>
    </div>
  )
}

Layout.defaultProps = {
  site: {},
  page: {},
}

Layout.propTypes = {
  site: PropTypes.object,
  page: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Layout
