import { atom } from 'recoil'

/**
 * Store fonts state here
 *
 * @usage
 * const [isFontsLoaded, setIsFontsLoaded] = useRecoilValue(fontsLoadedState)
 */

export const fontsLoadedState = atom({
  key: 'fontsLoadedState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
