import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getTypeClasses } from '@/helpers/getTypeClasses'

const Heading = ({ children, className, type, tag, ...props }) => {
  const Tag = tag

  return (
    <Tag className={classNames(getTypeClasses(type), className)} {...props}>
      {children}
    </Tag>
  )
}

const H1 = (props) => <Heading tag="h1" type="h1" {...props} />
const H2 = (props) => <Heading tag="h2" type="h2" {...props} />
const H3 = (props) => <Heading tag="h3" type="h3" {...props} />
const H4 = (props) => <Heading tag="h4" type="h4" {...props} />
const H5 = (props) => <Heading tag="h5" type="h4" {...props} />
const H6 = (props) => <Heading tag="h6" type="h5" {...props} />

Heading.defaultProps = {
  type: 'h1',
  tag: 'h1',
}

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tag: PropTypes.string,
}

export { Heading, H1, H2, H3, H4, H5, H6 }
