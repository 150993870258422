import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getThemePropType } from '@/helpers/getThemePropType'
import { getThemeCssVar } from '@/helpers/getThemeCssVar'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Heading } from '@/components/Heading'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { useInView } from '@/hooks/useInView'
import * as styles from './TokensSectionHead.styles.scss'

const TokensSectionHead = ({
  subheading,
  heading,
  description,
  theme,
  preloadedImages,
}) => {
  const { icon } = preloadedImages
  const ref = useRef()
  const isInView = useInView(ref)

  return (
    <Block
      className={classNames(styles.TokensSectionHead, {
        [styles.isInView]: isInView,
      })}
      style={getThemeCssVar(theme)}
    >
      <Container>
        <div className={styles.grid}>
          <div className={styles.head}>
            <div className={styles.image}>
              <ResponsiveImg {...icon} isLazy={false} />
            </div>
          </div>
          <div className={styles.body}>
            {subheading && (
              <div className={styles.subheading}>
                <Heading
                  tag="h4"
                  type="s3"
                  className={styles.subheading__inner}
                >
                  {subheading}
                </Heading>
              </div>
            )}
            <Heading
              tag="h3"
              type={{ desktop: 'h2', mobile: 'h3' }}
              className={styles.heading}
            >
              {heading}
            </Heading>
            {description && (
              <RichText type="p1" className={styles.description}>
                {description}
              </RichText>
            )}
          </div>
        </div>
      </Container>
    </Block>
  )
}

TokensSectionHead.propTypes = {
  subheading: PropTypes.node,
  heading: PropTypes.node,
  description: PropTypes.node,
  preloadedImages: PropTypes.object,
  theme: getThemePropType,
}

export { TokensSectionHead }
