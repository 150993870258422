import React, { useEffect, useRef } from 'react'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import { useGLRenderer } from '@/hooks/useGLRenderer'
import { shaderConfig } from './shaders/background-shader'
import { useRecoilValue } from 'recoil'
import { smoothScrollState } from '@/store'
import * as styles from './GLBackground.styles.scss'

gsap.registerPlugin(ScrollTrigger)

const GLBackground = () => {
  const isSmoothScroll = useRecoilValue(smoothScrollState)

  // const scrollPosition = useRef({ position: 0 })

  const requestRef = useRef(null)

  const { canvasRef, renderAnimationFrame, uniforms, textures } = useGLRenderer({ shaderConfig })

  useEffect(() => {
    // TODO: avoid re-creating everything on use re-render

    const scrollPosition = { position: 0 }
    const minTouchMoveDist = Math.pow(10, 2)
    const touchPointDuration = 2 // 1.5
    const numTouchPointsInPool = 2
    const includeFollowPoint = true // include a mouse following point in addition to triggered points

    const moveTimeDuration = touchPointDuration / (numTouchPointsInPool - (includeFollowPoint ? 1 : 0))
    const moveTimeDurationVariation = 1.5 // 0.8 // 1.5 // 0.5
    const randomPlacementOffsetAmount = 250

    // mouse following point parameters
    const followPointAccel = 0.005 // 0.003
    const followPointDecel = 0.9
    const followPointMaxVel = 10

    const followPointSnapMinPointAmount = 0.05
    const followPointSnapMinDist = Math.pow(100, 2)

    const followPointAmountAccel = 0.007 // 0.003
    const followPointAmountAccelDown = 0.003
    const followPointAmountDecel = 0.9

    const followPointMaxProgress = 0.6 // 0.7
    const followPointMaxSpeedSqrt = 10
    const followPointMaxSpeed = Math.pow(followPointMaxSpeedSqrt, 2)

    let scrollTrigger = null

    let startTime = -999999
    let curTime = startTime
    let elapsedTime = startTime

    // update background scroll position for parallaxing stars and hex grid
    const updateScroll = () => {
      const curScrollPosition = scrollPosition.position * window.document.documentElement.scrollHeight
      uniforms.current.scrollPosition?.set(0, curScrollPosition)
    }

    let texturesLoaded = false
    let numTexturesLoaded = 0
    let totalNumTextures = textures.current.length

    // wait till shader textures loaded before showing background
    const checkTexturesLoaded = () => {
      if (!texturesLoaded && numTexturesLoaded === totalNumTextures) {
        texturesLoaded = true
        startTime = new Date().getTime() / 1000 // set shader time to now to start fading in bg at this point
      }
    }

    // add checks for load completion of all shader textures
    textures.current.forEach((texture) => {
      if (texture.image.complete) {
        numTexturesLoaded++
        checkTexturesLoaded()
      } else {
        texture.image.addEventListener('load', () => {
          numTexturesLoaded++
          checkTexturesLoaded()
        })
      }
    })

    let touchPoints = []
    let touchPoint = null
    let followPoint = null
    let followPointIndex = 0
    let followPointSpeed = 0
    let followPointTargetDelta = 0

    let followPointAmountTarget = 0
    let followPointAmount = 0
    let followPointAmountVel = 0


    for (let touchPointI = 0; touchPointI < numTouchPointsInPool; touchPointI++) {
      touchPoint = { x: 0, y: 0, touchX: 0, touchY: 0, animationStartTime: -9999 }

      // if including a following touch point (non-triggered point), make this the first touch point and set relevant variables
      if (touchPointI === 0 && includeFollowPoint) {
        touchPoint.targetX = 0
        touchPoint.targetY = 0
        touchPoint.velX = 0
        touchPoint.velY = 0
        touchPoint.isFollowPoint = includeFollowPoint
        followPoint = touchPoint
        followPointIndex = touchPointI
      }
      touchPoints.push(touchPoint)
    }

    let curTouchPointIndex = includeFollowPoint ? 1 : 0
    let curTouchPoint = touchPoints[curTouchPointIndex]

    let touchDelta = { x: 0, y: 0 }
    let touchMoveDist = 0
    let touchProgress = 0

    let firstPlacement = true
    let nextMoveTime = -99999

    const onMouseMove = (event) => {
      if (event.touches && event.touches[0]) {
        event = event.touches[0]
      }

      // if including a mouse following point (non-triggered point)
      if (includeFollowPoint) {
        // update follow point target to mouse position
        followPoint.targetX = event.clientX
        followPoint.targetY = event.clientY

        // measure change in target location
        touchDelta.x = followPoint.targetX - followPoint.x
        touchDelta.y = followPoint.targetY - followPoint.y
        followPointTargetDelta = touchDelta.x * touchDelta.x + touchDelta.y * touchDelta.y

        // if following point intensity is low and new point is far away from previous point
        if (followPointAmount < followPointSnapMinPointAmount && followPointTargetDelta > followPointSnapMinDist) {
          // set current follow point location to same as target
          followPoint.x = followPoint.targetX
          followPoint.y = followPoint.targetY

          // neutralise existing velocity
          followPoint.velX = 0
          followPoint.velY = 0
        }
      }

      touchDelta.x = event.clientX - curTouchPoint.touchX
      touchDelta.y = event.clientY - curTouchPoint.touchY
      touchMoveDist = touchDelta.x * touchDelta.x + touchDelta.y * touchDelta.y

      // if ready to trigger new non-following touch point (moved far enough and waited enough time)
      if (touchMoveDist > minTouchMoveDist && curTime > nextMoveTime) {
        if (!firstPlacement) {
          // set point position to mouse position
          curTouchPoint.touchX = event.clientX
          curTouchPoint.touchY = event.clientY

          // add some randomm offset
          curTouchPoint.x = curTouchPoint.touchX + (Math.random() - 0.5) * randomPlacementOffsetAmount
          curTouchPoint.y = curTouchPoint.touchY + (Math.random() - 0.5) * randomPlacementOffsetAmount
          curTouchPoint.animationStartTime = curTime

          // update uniforms relative to view size
          uniforms.current['touchPosition' + (curTouchPointIndex + 1)].set(curTouchPoint.x / canvasRef.current.width - 0.5, (1 - curTouchPoint.y / canvasRef.current.height) - 0.5)

          // set next touch point in pool with count of numTouchPointsInPool
          curTouchPointIndex++
          if (curTouchPointIndex >= numTouchPointsInPool) {
            curTouchPointIndex = includeFollowPoint ? 1 : 0
          }
          curTouchPoint = touchPoints[curTouchPointIndex]

          // set next time of allowed touch point creation for animation adding some variation
          nextMoveTime = curTime + moveTimeDuration + Math.random() * Math.pow(moveTimeDurationVariation, 1.5)
        }
        firstPlacement = false
      }
    }

    const animate = () => {
      curTime = new Date().getTime() / 1000
      elapsedTime = (curTime - startTime)

      if (texturesLoaded) { // only start animating background in once background textures have loaded (fade in time set in fadeInDuration in background-shader.js)
        uniforms.current.time.set(elapsedTime)
      }

      // update animation progress for all touch points
      for (let touchPointI = 0; touchPointI < numTouchPointsInPool; touchPointI++) {
        touchPoint = touchPoints[touchPointI]

        // if is mouse following point, update target and move towards mouse position
        if (touchPoint.isFollowPoint) {
          touchDelta.x = touchPoint.targetX - touchPoint.x
          touchDelta.y = touchPoint.targetY - touchPoint.y

          // update velocity toward target location
          touchPoint.velX = touchPoint.velX * followPointDecel + touchDelta.x * followPointAccel
          touchPoint.velY = touchPoint.velY * followPointDecel + touchDelta.y * followPointAccel
          touchPoint.velX = gsap.utils.clamp(-followPointMaxVel, followPointMaxVel, touchPoint.velX)
          touchPoint.velY = gsap.utils.clamp(-followPointMaxVel, followPointMaxVel, touchPoint.velY)
          touchPoint.x += touchPoint.velX
          touchPoint.y += touchPoint.velY

          // set follow point intensity amount based on velocity, ease toward target point intensity
          followPointSpeed = touchPoint.velX * touchPoint.velX + touchPoint.velY * touchPoint.velY
          followPointAmountTarget = gsap.utils.clamp(0, 1, Math.sqrt(followPointSpeed) / followPointMaxSpeedSqrt)
          followPointAmountVel = followPointAmountVel * followPointAmountDecel + (followPointAmountTarget - followPointAmount) * ((followPointAmountVel > 0) ? followPointAmountAccel : followPointAmountAccelDown)
          followPointAmount += followPointAmountVel

          // update progress of following point to follow point intensity
          touchProgress = gsap.utils.clamp(0, 1, followPointAmount) * followPointMaxProgress

          uniforms.current['touchPosition' + (followPointIndex + 1)].set(followPoint.x / canvasRef.current.width - 0.5, (1 - followPoint.y / canvasRef.current.height) - 0.5)
        } else {
          // for triggered non-following points, set progress touch point animation relative to touch point's animationStartTime
          touchProgress = (curTime - touchPoint.animationStartTime) / touchPointDuration
        }
        touchProgress = gsap.utils.clamp(0, 1, touchProgress)
        uniforms.current['touchProgress' + (touchPointI + 1)].set(touchProgress)
      }

      if (!isSmoothScroll) {
        uniforms.current.scrollPosition?.set(0, window.scrollY)
      }

      renderAnimationFrame()

      requestRef.current = requestAnimationFrame(animate)

    }

    if (canvasRef.current && isSmoothScroll) {
      scrollTrigger = gsap.to(scrollPosition, {
        position: 1,
        ease: 'none',
        scrollTrigger: {
          start: 0,
          end: 'bottom',
          scrub: 0,
        },
        onUpdate: updateScroll,
      })
    }

    animate()

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('touchmove', onMouseMove)

    return () => {
      scrollTrigger?.kill()

      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchmove', onMouseMove)
      cancelAnimationFrame(requestRef.current)
    }
  }, [
    // scrollPosition,
    isSmoothScroll,
    canvasRef,
    renderAnimationFrame,
  ])

  return (
    <div className={styles.GLBackground} >
      <canvas className={styles.canvas} ref={canvasRef} />
    </div>
  )
}

export { GLBackground }