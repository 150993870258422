import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './HeaderGameplay.styles.scss'

const HeaderGameplay = ({ heading, introText, bodyText, image }) => {
  return (
    <Block
      hasPaddingTop={false}
      className={styles.HeaderGameplay}
      bgColor="custom"
    >
      <div className={styles.image} data-speed="0.7">
        <div className={styles.image__inner}>
          <ResponsiveImg
            {...image}
            isFull
            objectFit="contain"
            objectPosition="left top"
            isLazy={false}
          />
        </div>
      </div>
      <div className={styles.text}>
        <Container indent={{ desktop: 2 }}>
          <div className={styles.primary}>
            <Heading tag="h2" type="h1" className={styles.heading}>
              {heading}
            </Heading>
          </div>
          <div className={styles.secondary}>
            {introText && (
              <RichText type="s1" className={styles.introText}>
                {introText}
              </RichText>
            )}
            {bodyText && (
              <RichText type="p1" className={styles.bodyText}>
                {bodyText}
              </RichText>
            )}
          </div>
        </Container>
      </div>
    </Block>
  )
}

HeaderGameplay.propTypes = {
  heading: PropTypes.node,
  introText: PropTypes.node,
  bodyText: PropTypes.node,
  image: PropTypes.object,
}

export { HeaderGameplay }
