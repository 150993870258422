import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { smoothScrollState } from '@/store'
import { Container } from '@/components/Container'
import { VideoBtnLink } from '@/components/VideoBtnLink'
import { Heading } from '@/components/Heading'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { useOuterScroll } from './hooks/useOuterScroll'
import { useInnerScroll } from './hooks/useInnerScroll'
import * as styles from './Cta.styles.scss'

const Cta = ({ heading, button, image }) => {
  const isSmoothScroll = useRecoilValue(smoothScrollState)

  const ref = useRef()

  useOuterScroll(ref, {
    isEnabled: isSmoothScroll,
    selectors: {
      element: `.${styles.outer}`,
      trigger: `.${styles.outerTrigger}`,
    },
  })

  useInnerScroll(ref, {
    isEnabled: isSmoothScroll,
    selectors: {
      element: `.${styles.inner}`,
      trigger: `.${styles.innerTriiger}`,
    },
  })

  return (
    <div className={styles.Cta} ref={ref}>
      <div className={styles.innerTriiger} />
      <div className={styles.outerTrigger} />
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div className={styles.background}>
            {image && (
              <div className={styles.background__inner}>
                <ResponsiveImg
                  {...image}
                  isFull
                  objectFit="cover"
                  isLazy={false}
                  enableGlitchCanvas={true}
                  inViewEnd={'bottom -500%'} // ensure glitch canvas renders when it is still at bottom of the screen - scroll trigger determination of in view state of transition canvas doesn't seem to be correct
                />
              </div>
            )}
          </div>
          <div className={styles.text}>
            <Container indent={{ desktop: 2 }}>
              <div className={styles.text__inner}>
                <Heading
                  tag="h2"
                  type={{ desktop: 'h2', mobile: 'h3' }}
                  className={styles.heading}
                >
                  {heading}
                </Heading>
                {button && (
                  <div className={styles.btn}>
                    {button?.type === 'video_lightbox' && (
                      <VideoBtnLink video={button.video}>
                        {button.title}
                      </VideoBtnLink>
                    )}
                    {/*
                      TBC: Support regular links
                      <Btn to={button.url} icon={button.icon}>{button.title}</Btn>
                    */}
                  </div>
                )}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

Cta.propTypes = {
  heading: PropTypes.node,
  button: PropTypes.object,
  image: PropTypes.object,
}

export { Cta }
