import { atom } from 'recoil'

/**
 * Store browser state here, update in ResizeService
 *
 * @usage
 * const { device } = useRecoilValue(browserState)
 */

export const canvasPoolState = atom({
  key: 'canvasPoolState', // unique ID (with respect to other atoms/selectors)
  default: [],
})
