import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { Frame } from '@/components/Frame'
import { Media } from '@/components/Media'
import { Img } from '@/components/Img'
import * as styles from './PeopleGrid.styles.scss'

const PeopleGrid = ({ heading, items }) => {
  return (
    <Block>
      <Container indent={{ desktop: 2 }}>
        {heading && (
          <Heading tag="h3" type="h4" className={styles.heading}>
            {heading}
          </Heading>
        )}
        {!!items?.length && (
          <div className={styles.items}>
            {items.map((item) => {
              return (
                <div key={item.uid} className={styles.item}>
                  <Frame />
                  <div className={styles.media}>
                    <Media {...item.media} isFull objectFit="cover" />
                  </div>
                  {item.name && (
                    <Heading tag="h4" type={{ desktop: 'h5', mobile: 'h6' }}>
                      {item.name}
                    </Heading>
                  )}
                  {item.subheading && (
                    <Heading tag="div" type="s4" className={styles.subheading}>
                      {item.subheading}
                    </Heading>
                  )}
                  {item.logo && (
                    <div className={styles.logo}>
                      <div className={styles.logo__inner}>
                        <Img
                          className={styles.logo__img}
                          {...item.logo}
                          isFull
                          objectFit="contain"
                          objectPosition="center"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </Container>
    </Block>
  )
}

PeopleGrid.propTypes = {
  heading: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
}

export { PeopleGrid }
