import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'

export const useSlideshowTransition = (ref, { selectors, currentIndex }) => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  const { foregroundMedia, backgroundMedia } = selectors
  const previousIndex = useRef(currentIndex)

  const [foregroundSrcA, setForegroundSrcA] = useState(null)
  const [foregroundSrcB, setForegroundSrcB] = useState(null)
  const [backgroundSrcA, setBackgroundSrcA] = useState(null)
  const [backgroundSrcB, setBackgroundSrcB] = useState(null)

  useEffect(() => {
    const $root = ref.current
    const $slides = $root.querySelectorAll('[data-slide]')
    const $currentSlide = $slides[currentIndex]
    const $prevSlide = $slides[previousIndex.current]

    const $allSlidesContent = $root.querySelectorAll('[data-slide-content]')
    const $slideContent = $currentSlide?.querySelector('[data-slide-content]')

    const foregroundMediaA = $prevSlide?.querySelector(foregroundMedia)
    const foregroundMediaB = $currentSlide?.querySelector(foregroundMedia)

    setForegroundSrcA(
      foregroundMediaA?.toDataURL
        ? foregroundMediaA.toDataURL()
        : foregroundMediaA?.src
    )
    setForegroundSrcB(
      foregroundMediaB?.toDataURL
        ? foregroundMediaB.toDataURL()
        : foregroundMediaB?.src
    )

    if (backgroundMedia) {
      const backgroundMediaA = $prevSlide?.querySelector(backgroundMedia)
      const backgroundMediaB = $currentSlide?.querySelector(backgroundMedia)

      setBackgroundSrcA(
        backgroundMediaA?.toDataURL
          ? backgroundMediaA.toDataURL()
          : backgroundMediaA?.src
      )
      setBackgroundSrcB(
        backgroundMediaB?.toDataURL
          ? backgroundMediaB.toDataURL()
          : backgroundMediaB?.src
      )
    }

    previousIndex.current = currentIndex

    setIsTransitioning(true)

    const tl = gsap.timeline()
    tl.to($allSlidesContent, {
      autoAlpha: 0,
    })
    tl.set($slides, {
      autoAlpha: 0,
    })
    tl.set($currentSlide, {
      autoAlpha: 1,
    })
    tl.to($slideContent, {
      autoAlpha: 1,
      onComplete: () => {
        setIsTransitioning(false)
      },
    })

    return () => {
      tl.kill()
    }
  }, [ref, currentIndex, foregroundMedia, backgroundMedia])

  // Return state and refs from hook
  return {
    isTransitioning,
    foregroundSrcA,
    foregroundSrcB,
    backgroundSrcA,
    backgroundSrcB,
  }
}
