import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Heading } from '@/components/Heading'
import { Btn } from '@/components/Btn'
import * as styles from './Text.styles.scss'

const Text = ({ heading, body, link, prevBlock }) => {
  return (
    <Block
      className={classNames(styles.Text, {
        [styles.reducedPadding]: !prevBlock,
      })}
      hasPaddingTop={false}
    >
      <div className={styles.inner}>
        <Container>
          <div className={styles.grid}>
            <div className={styles.side}>
              <Heading tag="h3" type="h4">
                {heading}
              </Heading>
            </div>
            <div className={styles.main}>
              {body && (
                <RichText type="p1" className={styles.body}>
                  {body}
                </RichText>
              )}
              {link?.url && (
                <div className={styles.btn}>
                  <Btn href={link.url} target={link.target}>
                    {link.title}
                  </Btn>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </Block>
  )
}

Text.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node,
  link: PropTypes.object,
}

export { Text }
