import React, { useMemo, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { RandomReveal } from '@/hooks/useRandomReveal' // 'react-random-reveal'
import { SCRAMBLED_CHARACTER_SET } from '@/constants/scrambledText'
import { getTypeClasses } from '@/helpers/getTypeClasses'
import * as styles from './ScrambledTextArray.styles.scss'

const ScrambledTextArray = ({ textArray, type, className, isVisible }) => {
  const ref = useRef()
  const { device } = useRecoilValue(browserState)

  const plainText = useMemo(
    () =>
      textArray?.map((item, index) => {
        return `${item.characters}${index !== textArray.length - 1 && ' '}`
      }),
    [textArray]
  )

  return (
    <div
      className={classNames(
        styles.ScrambledTextArray,
        {
          ...getTypeClasses(type),
          [styles.isVisible]: !!isVisible,
        },
        className
      )}
      aria-label={plainText}
      ref={ref}
    >
      {textArray?.map((item) => {
        return (
          <span
            className={classNames(styles.part, {
              [item.className]: !!item.className,
            })}
            key={item.uid}
            aria-hidden="true"
          >
            {device === 'desktop' ? (
              <>
                <RandomReveal
                  isPlaying={isVisible}
                  resetOnPlay={true}
                  duration={0.5}
                  characters={item.characters}
                  characterSet={SCRAMBLED_CHARACTER_SET}
                  ignoreCharacterSet={[' ']}
                  updateInterval={0.1} // Default is 0.065
                />{' '}
              </>
            ) : (
              `${item.characters} `
            )}
          </span>
        )
      })}
    </div>
  )
}

ScrambledTextArray.propTypes = {
  textArray: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      characters: PropTypes.string,
      className: PropTypes.string,
    })
  ),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isVisible: PropTypes.bool,
}

export { ScrambledTextArray }
