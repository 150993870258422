import React from 'react'
import PropTypes from 'prop-types'
import { getThemeCssVar } from '@/helpers/getThemeCssVar'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { RichText } from '@/components/RichText'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './HeaderTokens.styles.scss'

const HeaderTokens = ({ heading, description, tokens, preloadedImages }) => {
  const { oil01, oil02 } = preloadedImages

  return (
    <Block hasPaddingTop={false} className={styles.HeaderTokens}>
      <Container>
        <div className={styles.top}>
          <div className={styles.image01} data-speed="0.7">
            <ResponsiveImg {...oil01} isLazy={false} />
          </div>
          <div className={styles.image02} data-speed="0.9">
            <ResponsiveImg {...oil02} isLazy={false} />
          </div>
          <div className={styles.top__text}>
            <Heading tag="h2" type="h1" className={styles.heading}>
              {heading}
            </Heading>
            {description && (
              <RichText type="s1" className={styles.description}>
                {description}
              </RichText>
            )}
          </div>
        </div>
      </Container>
      {!!tokens?.length && (
        <div className={styles.tokens}>
          <Container>
            <div className={styles.tokens__inner}>
              {tokens.map((item) => {
                return (
                  <div
                    key={item.uid}
                    style={getThemeCssVar(item.theme || 'cyan')}
                    className={styles.token}
                  >
                    {item.image && (
                      <div className={styles.token__image}>
                        <ResponsiveImg
                          {...item.image}
                          isLazy={false}
                          enableGlitchCanvas={true}
                        />
                      </div>
                    )}
                    <Heading tag="h3" type="h2">
                      {item.heading}
                    </Heading>
                    {item.subheading && (
                      <Heading
                        tag="h3"
                        type="s3"
                        className={styles.token__subheading}
                      >
                        <div className={styles.token__subheading__inner}>
                          {item.subheading}
                        </div>
                      </Heading>
                    )}
                    {description && (
                      <RichText type="p1" className={styles.token__description}>
                        {item.description}
                      </RichText>
                    )}
                  </div>
                )
              })}
            </div>
          </Container>
        </div>
      )}
    </Block>
  )
}

HeaderTokens.propTypes = {
  heading: PropTypes.node,
  description: PropTypes.node,
  tokens: PropTypes.arrayOf(PropTypes.object),
}

export { HeaderTokens }
