import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { LogoIcon } from '@/components/LogoIcon'
import { Heading } from '@/components/Heading'
import { Link } from '@/components/Link'
import { SocialIcon } from '@/components/SocialIcon'
import LogoWeb3Svg from '@/assets/logo-web3.inline.svg'
import * as styles from './Footer.styles.scss'

const Footer = ({ nav, notes }) => {
  return (
    <div className={styles.Footer}>
      <div className={styles.primary}>
        <Container indent={{ desktop: 2 }}>
          <nav className={styles.primary__inner}>
            <div className={styles.logo}>
              <Link to="/">
                <LogoIcon />
              </Link>
            </div>
            {nav?.map((group) => {
              return (
                <div key={group.uid}>
                  <Heading tag="div" type="h6">
                    {group.title}
                  </Heading>
                  {!!group.links?.length && (
                    <ul className={styles.nav__list}>
                      {group.links?.map((item) => {
                        return (
                          <li key={item.uid} className={styles.nav__item}>
                            <Link
                              to={item.url}
                              target={item.target}
                              className={styles.nav__link}
                            >
                              {item.title}
                              {item.appearance !== 'default' && (
                                <SocialIcon
                                  variant={item.appearance}
                                  className={styles.nav__icon}
                                />
                              )}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )
            })}
          </nav>
        </Container>
      </div>
      <div className={styles.secondary}>
        <Container indent={{ desktop: 2 }}>
          <div className={styles.secondary__inner}>
            {notes && <RichText>{notes}</RichText>}
            <div className={styles.credits}>
              Website by{' '}
              <a
                href="https://web3studio.co/"
                target="_blank"
                rel="noreferrer"
                className={styles.web3}
              >
                <span className={styles.web3__text}>Web3 Studio</span>
                <LogoWeb3Svg className={styles.web3__icon} />
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

Footer.propTypes = {
  notes: PropTypes.node,
  nav: PropTypes.arrayOf(PropTypes.object),
}

export { Footer }
