import imageStars from '../../images/stars-crop.webp'
import imageHexGrid from '../../images/hex-grid.png'

export const shaderConfig = {
  textures: [
    imageStars,
    imageHexGrid,
  ],
  uniforms: [
    {
      name: 'time',
      suffix: '1f',
      value: 0,
    },
    {
      name: 'fadeInDuration',
      suffix: '1f',
      value: 2.5,
    },
    {
      name: 'touchPosition1',
      suffix: '2f',
      value: [0, 0],
    },
    {
      name: 'touchProgress1',
      suffix: '1f',
      value: 0,
    },
    {
      name: 'touchPosition2',
      suffix: '2f',
      value: [0, 0],
    },
    {
      name: 'touchProgress2',
      suffix: '1f',
      value: 0,
    },
    {
      name: 'touchPointSize',
      suffix: '1f',
      value: 2.0,
    },
    {
      name: 'scrollPosition',
      suffix: '2f',
      value: [0, 0],
    },
    {
      name: 'starsTextureSize',
      suffix: '2f',
      value: [762, 762],
    },
    {
      name: 'hexGridTextureSize',
      suffix: '2f',
      value: [1000, 866],
    },
    {
      name: 'hexGridScale',
      suffix: '1f',
      value: 0.2,
    },
    {
      name: 'starsSpeed',
      suffix: '1f',
      value: 0.2,
    },
    {
      name: 'starsSpeedLayer2Multiplier',
      suffix: '1f',
      value: 1.3,
    },
    {
      name: 'starsLayer2Scale',
      suffix: '1f',
      value: 0.78,
    },
    {
      name: 'hexGridSpeed',
      suffix: '1f',
      value: 0.4,
    },
    {
      name: 'hexGridColor',
      suffix: '4f',
      value: [87 / 255, 225 / 255, 255 / 255, 0.15],
    },
  ],
  fragmentShader: `
    #ifdef GL_ES
      precision mediump float;
    #endif

    uniform vec2 viewResolution;
    uniform float time;

    uniform float fadeInDelay;
    uniform float fadeInDuration;

    varying vec2 vTexCoord;

    uniform sampler2D textureSampler1;
    uniform sampler2D textureSampler2;

    uniform vec2 scrollPosition;

    uniform vec2 touchPosition1;
    uniform float touchProgress1;
    uniform vec2 touchPosition2;
    uniform float touchProgress2;
    uniform float touchPointSize;
    
    uniform vec2 starsTextureSize;
    uniform vec2 hexGridTextureSize;

    uniform float hexGridScale;
    uniform vec4 hexGridColor;
    const float hexGridFluctuationScale = 1.5;
    const float hexGridIntensity = 0.7;
    const float hexGridGlowIntensity = 1.5;
    const float hexGridInnerGlowIntensity = 2.0;
    const float hexGridGlowPower = 1.5;
    const float hexGridInnerGlowPower = 1.1;
    const float hexGridGlowSubtractionScale = 1.0;
    const float hexGridInnerGlowSubtractionScale = 1.1;
    const float hexGridAllowedInnerGlowSubtractionScale = 1.05;
    
    const float gridFluctuationSpeed = 1.0;
    const float gridRadialFluctuationFrequency = 10.0;
    const float gridFluctuationOffsetAmount = 0.1;
    const float gridIntensitySubtractionScale = 1.05;
    const float gridIntensityPower = 0.95;
    const float gridVerticalFluctuationFrequency = 2.0;

    const float gridInnerFluctuationSpeed = 5.0;
    const float gridInnerRadialFluctuationFrequency = 85.0;
    const float gridInnerFluctuationOffsetAmount = 0.15;
    const float gridInnerFluctuationDistOffsetAmount = 0.1;
    const float gridInnerIntensitySubtractionScale = 1.0;
    const float gridInnerFluctuationIntensitySubtractionScale = 1.1;
    const float gridInnerVerticalFluctuationFrequency = 15.0;
    const float gridInnerHorizontalFluctuationFrequency = 20.0;

    const float gridFluctuationVerticalOffsetSpeed = 0.45;

    const float scanlineSpeed = 0.75;

    const float gridCenterRegionScale =  0.2;

    uniform float starsSpeed;
    uniform float starsLayer2SpeedMultiplier;
    uniform float starsLayer2Scale;

    uniform float hexGridSpeed;

    float getGridFluctuationIntensity(vec2 coordFluctationScrollOffset) {
      // coordFluctationScrollOffset *= vec2(1.0, 2.0);
      
      vec2 verticalInnerFluctuationAmountOffset = vec2(0.0);
      verticalInnerFluctuationAmountOffset.x = sin(coordFluctationScrollOffset.x * 12.0 - time * 0.345);
      verticalInnerFluctuationAmountOffset.y = sin(coordFluctationScrollOffset.x * 9.34 + time * 0.723);
      
      verticalInnerFluctuationAmountOffset.x = coordFluctationScrollOffset.y + verticalInnerFluctuationAmountOffset.x * 0.02;
      verticalInnerFluctuationAmountOffset.y = coordFluctationScrollOffset.y + coordFluctationScrollOffset.x * 0.252 - verticalInnerFluctuationAmountOffset.y * 0.04;

      float verticalInnerFluctuationAmount = sin(verticalInnerFluctuationAmountOffset.x * 3.14159 * gridInnerVerticalFluctuationFrequency + time * 1.345);
      verticalInnerFluctuationAmount += sin(verticalInnerFluctuationAmountOffset.y * 3.14159 * 1.763 * gridVerticalFluctuationFrequency - time * 0.893);

      verticalInnerFluctuationAmount = verticalInnerFluctuationAmount * 0.25 + 0.5;

      
      vec3 horizontalInnerFluctuationAmountOffset = vec3(0.0);
      horizontalInnerFluctuationAmountOffset.x = sin(coordFluctationScrollOffset.y * 12.0 - time * 0.345);
      horizontalInnerFluctuationAmountOffset.y = sin(coordFluctationScrollOffset.y * 4.34 + time * 0.723);
      horizontalInnerFluctuationAmountOffset.z = sin(coordFluctationScrollOffset.y * 4.34 + time * 0.323);

      horizontalInnerFluctuationAmountOffset.x = (coordFluctationScrollOffset.x + horizontalInnerFluctuationAmountOffset.x * 0.0042);
      horizontalInnerFluctuationAmountOffset.y = (coordFluctationScrollOffset.x - coordFluctationScrollOffset.y * 0.2 - horizontalInnerFluctuationAmountOffset.y * 0.0153);
      horizontalInnerFluctuationAmountOffset.z = (coordFluctationScrollOffset.x + coordFluctationScrollOffset.y * 0.334 + horizontalInnerFluctuationAmountOffset.z * 0.034);

      float horizontalInnerFluctuationAmount = sin(horizontalInnerFluctuationAmountOffset.x * 3.14159 * gridInnerHorizontalFluctuationFrequency + time * 1.345);
      horizontalInnerFluctuationAmount += sin(horizontalInnerFluctuationAmountOffset.y * 3.14159 * 1.763 * gridInnerHorizontalFluctuationFrequency - time * 0.893);
      horizontalInnerFluctuationAmount += sin(horizontalInnerFluctuationAmountOffset.z * 3.14159 * 1.142 * gridInnerHorizontalFluctuationFrequency - time * 0.345);

      horizontalInnerFluctuationAmount = horizontalInnerFluctuationAmount / 3.0 * 0.5 + 0.5;


      // float verticalInnerFluctuationAmount = (sin((coordFluctationScrollOffset.y + sin(coordFluctationScrollOffset.x * 12.0 - time * 0.345) * 0.02) * 3.14159 * gridInnerVerticalFluctuationFrequency + time * 1.345) + sin((coordFluctationScrollOffset.y + coordFluctationScrollOffset.x * 0.252- sin(coordFluctationScrollOffset.x * 9.34 + time * 0.723) * 0.04) * 3.14159 * 1.763 * gridVerticalFluctuationFrequency - time * 0.893)) * 0.25 + 0.5;
      // float horizontalInnerFluctuationAmount = (sin((coordFluctationScrollOffset.x + sin(coordFluctationScrollOffset.y * 12.0 - time * 0.345) * 0.0042) * 3.14159 * gridInnerHorizontalFluctuationFrequency + time * 1.345) + sin((coordFluctationScrollOffset.x - coordFluctationScrollOffset.y * 0.2 - sin(coordFluctationScrollOffset.y * 4.34 + time * 0.723) * 0.0153) * 3.14159 * 1.763 * gridInnerHorizontalFluctuationFrequency - time * 0.893) + sin((coordFluctationScrollOffset.x + coordFluctationScrollOffset.y * 0.334 + sin(coordFluctationScrollOffset.y * 4.34 + time * 0.323) * 0.034) * 3.14159 * 1.142 * gridInnerHorizontalFluctuationFrequency - time * 0.345)) / 3.0 * 0.5 + 0.5;

      float innerGridFluctuationIntensity = verticalInnerFluctuationAmount * horizontalInnerFluctuationAmount;
      return innerGridFluctuationIntensity;
    }

    vec2 getTouchIntensity(vec2 touchPosition, float touchProgress, vec2 viewRatio, vec2 gridOffset) {
      vec2 touchGridCenterPos = (vTexCoord - touchPosition - vec2(0.5)) * viewRatio / touchPointSize;

      float touchGridRadialDist = sqrt(touchGridCenterPos.x * touchGridCenterPos.x + touchGridCenterPos.y * touchGridCenterPos.y);
      float touchGridRadialPos = (atan(touchGridCenterPos.x, touchGridCenterPos.y) + 3.14159) / 6.28318;

      float touchAmount = clamp(touchProgress / 0.5, 0.0, 1.0) * clamp(1.0 - touchProgress, 0.0, 0.8);
      // float touchAmount = clamp(touchProgress / 0.5, 0.0, 1.0) * clamp(1.0 - touchProgress, 0.0, 0.8);
      // float touchAmount = clamp(touchProgress / 0.5, 0.0, 1.0) * pow(clamp(1.0 - touchProgress, 0.0, 1.0), 0.8);
      // float touchAmount = 0.5 - 0.5 * cos(pow(1.0 - touchProgress, 1.5) * 6.28318);

      float touchGridScale = mix(0.2, 0.42, pow(touchProgress, 1.0)); // 0.1, 0.3
      // float touchGridScale = mix(0.12, 0.42, pow(touchProgress, 1.0)); // 0.1, 0.3
      float touchGridRippleScale = mix(0.3, 0.46, touchProgress); // 0.15, 0.3
      float touchBaseGridIntensityAmount = (touchGridScale - touchGridRadialDist) / touchGridRippleScale;
      touchBaseGridIntensityAmount = clamp(touchBaseGridIntensityAmount, 0.0, 1.0);
      touchBaseGridIntensityAmount = 0.5 - 0.5 * cos(pow(1.0 - touchBaseGridIntensityAmount, 1.0) * 6.28318);
      touchBaseGridIntensityAmount *= touchAmount;

      // touchBaseGridIntensityAmount *= 1.5;
      // touchBaseGridIntensityAmount = min(touchBaseGridIntensityAmount, 1.0);


      float gridBaseIntensity = getGridFluctuationIntensity(gridOffset);
      
      float gridInnerIntensity = gridBaseIntensity; // * 1.1; // 1.5;
      float gridIntensity = (gridBaseIntensity + 0.1) * 1.2; // 2.0;
      
      float touchGridIntensityInnerAmount = touchBaseGridIntensityAmount * gridInnerIntensity; 
      touchGridIntensityInnerAmount = min(touchGridIntensityInnerAmount, 1.0);

      float touchGridIntensityAmount = touchBaseGridIntensityAmount * gridIntensity;
      touchGridIntensityAmount = min(touchGridIntensityAmount, 1.0);

      return vec2(touchGridIntensityInnerAmount, touchGridIntensityAmount);
    }

    void main() {
      vec2 scrollOffset = scrollPosition / viewResolution;

      vec2 hexGridSizeRatio = viewResolution / hexGridTextureSize;

      vec2 viewportRatio = vec2(1.0, viewResolution.y / viewResolution.x);

      // first layer of stars
      vec2 starsOffsetCoord = (vTexCoord - scrollOffset * starsSpeed - 0.5) * (viewResolution / starsTextureSize) + 0.5;
      vec4 outputColor = texture2D(textureSampler1, fract(starsOffsetCoord));

      // second layer of stars
      vec2 starsOffsetCoord2 = (vTexCoord - scrollOffset * starsSpeed * starsLayer2SpeedMultiplier - 0.5) * (viewResolution / starsTextureSize) + vec2(0.3, 0.5) * starsLayer2Scale + 0.5;
      starsOffsetCoord2 = (starsOffsetCoord2 - 0.5) + 0.5;
      outputColor += texture2D(textureSampler1, fract(starsOffsetCoord2)) * 0.5;

      // hex grid
      vec2 coordFluctationScrollOffset = vTexCoord * hexGridSizeRatio / vec2(hexGridFluctuationScale, hexGridFluctuationScale * 0.5) - scrollOffset * gridFluctuationVerticalOffsetSpeed;
      vec2 centerPos = (vTexCoord - 0.5) * viewportRatio;

      float radialDist = sqrt(centerPos.x * centerPos.x + centerPos.y * centerPos.y);
      float radialPos = (atan(centerPos.x, centerPos.y) + 3.14159) / 6.28318;

      
      vec2 centerPosOffset = (coordFluctationScrollOffset - 0.5) * viewportRatio;
      float offsetRadialDist = sqrt(centerPosOffset.x * centerPosOffset.x + centerPosOffset.y * centerPosOffset.y);
      float offsetRadialPos = (atan(centerPosOffset.x, centerPosOffset.y) + 3.14159) / 6.28318;

      // base hex grid
      float radialDistOffsetAmount = 1.0 + gridFluctuationOffsetAmount * (sin(radialPos * 3.14159 * 6.0 + time * 0.2) + sin(radialPos * 3.14159 * 4.0 - time * 0.823));
      float baseGridRadialDist = radialDist * radialDistOffsetAmount;

      float gridIntensity = sin(baseGridRadialDist * gridRadialFluctuationFrequency - time * gridFluctuationSpeed) * 0.5 + 0.5;

      float centerRegionAmount = min(1.0, radialDist / gridCenterRegionScale - 0.2);
      float gridRadialFluctuationAmount = (sin(radialPos * 3.14159 * 8.0 - time * 0.3674) + sin(radialPos * 3.14159 * 6.0 + time * 0.743)) * 0.25 + 0.5;
      gridRadialFluctuationAmount = mix(1.0, gridRadialFluctuationAmount, centerRegionAmount);
      gridIntensity *= gridRadialFluctuationAmount;

      float verticalFluctuationAmount = (sin(coordFluctationScrollOffset.y * 3.14159 * gridVerticalFluctuationFrequency - time * 0.745) + sin(coordFluctationScrollOffset.y * 3.14159 * 1.763 * gridVerticalFluctuationFrequency + time * 0.567)) * 0.25 + 0.5;
      gridIntensity *= verticalFluctuationAmount;

      // gridIntensity = max(0.0, 1.0 - (1.0 - gridIntensity) * gridIntensitySubtractionScale);
      gridIntensity = pow(gridIntensity, gridIntensityPower);

      gridIntensity *= hexGridIntensity;


      float scanlineMultiplier = 0.5 + 0.5 * pow(0.5 + 0.5 * sin(((vTexCoord.y - scrollOffset.y * hexGridSpeed) * viewResolution.y / 4.0 + time * scanlineSpeed) * 6.28318), 0.6);

      vec2 hexGridTextCoordOffset = (vTexCoord - scrollOffset * hexGridSpeed - 0.5) * hexGridSizeRatio / hexGridScale + 0.5;
      vec4 gridTextureSample = texture2D(textureSampler2, fract(hexGridTextCoordOffset));
      outputColor.xyz += gridTextureSample.x * hexGridColor.xyz * hexGridColor.w * gridIntensity * scanlineMultiplier;

      
      float innerGridIntensity = getGridFluctuationIntensity(coordFluctationScrollOffset);

      innerGridIntensity = max(0.0, 1.0 - (1.0 - innerGridIntensity) * gridInnerIntensitySubtractionScale);
      
      float curGridGlowIntensityMultiplier = max(0.0, 1.0 - (1.0 - gridIntensity) * hexGridAllowedInnerGlowSubtractionScale);

      vec2 touch1GridOffset = -coordFluctationScrollOffset * vec2(0.6) + vec2(0.234, 0.3552);
      vec2 touch1GridIntensityAmount = getTouchIntensity(touchPosition1, touchProgress1, hexGridSizeRatio, touch1GridOffset);

      vec2 touch2GridOffset = coordFluctationScrollOffset * vec2(0.5) + vec2(0.6463, 0.8453);
      vec2 touch2GridIntensityAmount = getTouchIntensity(touchPosition2, touchProgress2, hexGridSizeRatio, touch2GridOffset);

      innerGridIntensity += max(touch1GridIntensityAmount.x, touch2GridIntensityAmount.x);
      curGridGlowIntensityMultiplier += max(touch1GridIntensityAmount.y, touch2GridIntensityAmount.y);
      
      innerGridIntensity *= curGridGlowIntensityMultiplier;


      float curGridGlowIntensity = max(0.0, 1.0 - (1.0 - innerGridIntensity) * hexGridGlowSubtractionScale);
      curGridGlowIntensity = pow(curGridGlowIntensity, hexGridGlowPower);
      outputColor.xyz += gridTextureSample.z * hexGridColor.xyz * curGridGlowIntensity * hexGridGlowIntensity * scanlineMultiplier;
      
      float curGridInnerGlowIntensity = max(0.0, 1.0 - (1.0 - innerGridIntensity) * hexGridInnerGlowSubtractionScale);
      curGridInnerGlowIntensity = pow(curGridInnerGlowIntensity, hexGridInnerGlowPower);
      outputColor.xyz += gridTextureSample.x * mix(hexGridColor.xyz, vec3(1.0), 0.5) * max(0.0, curGridInnerGlowIntensity * hexGridInnerGlowIntensity) * scanlineMultiplier;


      // vec2 coord = gl_FragCoord.xy / viewResolution;
      // outputColor *= vec4( coord.x, coord.y, sinWave, 1.0 );

      // outputColor.r = innerGridIntensity * 2.0;


      outputColor.rgb *= clamp(time / fadeInDuration, 0.0, 1.0);
      
      gl_FragColor = outputColor;
    }
  `,
  vertexShader: `
    attribute vec4 a_Position;
    
    attribute vec2 a_TexCoord;
    varying vec2 vTexCoord;
    
    void main() {
      gl_Position = a_Position;
      vTexCoord = a_TexCoord;
    }
  `
}