import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const Portal = ({ children, isEnabled }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [isEnabled])

  if (!isEnabled) {
    return children
  }

  if (!hasMounted) {
    return null
  }

  return ReactDOM.createPortal(children, document.querySelector(`#__portal`))
}

Portal.defaultProps = {
  isEnabled: true,
}

Portal.propTypes = {
  children: PropTypes.node,
  isEnabled: PropTypes.bool,
}

export { Portal }
