import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as styles from './SocialIcon.styles.scss'

import DiscordSvg from './images/discord.inline.svg'
// import FacebookSvg from './images/facebook.inline.svg'
import InstagramSvg from './images/instagram.inline.svg'
import LinkedinSvg from './images/linkedin.inline.svg'
import OpenseaSvg from './images/opensea.inline.svg'
// import PinterestSvg from './images/pinterest.inline.svg'
import TwitterSvg from './images/twitter.inline.svg'
// import VimeoSvg from './images/vimeo.inline.svg'
// import YoutubeSvg from './images/youtube.inline.svg'

const ICONS = [
  { id: 'discord', icon: DiscordSvg },
  // { id: 'facebook', icon: FacebookSvg },
  { id: 'instagram', icon: InstagramSvg },
  { id: 'linkedin', icon: LinkedinSvg },
  { id: 'opensea', icon: OpenseaSvg },
  // { id: 'pinterest', icon: PinterestSvg },
  { id: 'twitter', icon: TwitterSvg },
  // { id: 'vimeo', icon: VimeoSvg },
  // { id: 'youtube', icon: YoutubeSvg },
]

const SocialIcon = ({ variant, className }) => {
  const match = ICONS.find((network) => network.id === variant)
  const Icon = match && match.icon ? match.icon : undefined

  if (Icon) {
    return <Icon className={classNames(styles.SocialIcon, className)} />
  }
  return null
}

SocialIcon.defaultProps = {
  variant: 'facebook',
}

SocialIcon.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
}

export { SocialIcon }
