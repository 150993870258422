import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import * as styles from './AttackScene.styles.scss'

const AttackScene = ({ children, images }) => {
  const {
    asteroid01,
    // asteroid02,
    attack,
    flyingShip01,
  } = images

  return (
    <div className={styles.AttackScene}>
      <div className={styles.primary}>
        <div className={styles.children}>{children}</div>
        <div className={styles.flyingShip01} data-speed="0.8">
          <div className={styles.flyingShip01__inner}>
            <ResponsiveImg {...flyingShip01} isLazy={false} />
          </div>
        </div>
        <div className={styles.asteroid01} data-speed="0.9">
          <div className={styles.asteroid01__inner}>
            <ResponsiveImg {...asteroid01} isLazy={false} />
          </div>
        </div>
        {/* <div className={styles.asteroid02} data-speed="0.7">
          <ResponsiveImg {...asteroid02} isLazy={false} />
        </div> */}
      </div>
      <div className={styles.attack} data-speed="0.9">
        <ResponsiveImg {...attack} isLazy={false} />
      </div>
    </div>
  )
}

AttackScene.propTypes = {
  children: PropTypes.node,
  images: PropTypes.object.isRequired,
}

export { AttackScene }
