import { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Toggle visibility of intro and outro with positioning
 * based on the middle of the planet reaching the midle
 * of the viewport.
 *
 * – Fixed positioning is handled with `useFixedPosition()`
 * – Outro transition is handled with `useOutroTransition()`
 */

export const useIntroTransition = (ref, { trigger, isEnabled }) => {
  const [isIntroVisbile, setIsIntroVisible] = useState(true)

  useEffect(() => {
    const $root = ref.current
    const $trigger = $root.querySelector(trigger)
    let scrollTrigger

    if (isEnabled) {
      scrollTrigger = ScrollTrigger.create({
        trigger: $trigger,
        start: -100, //0, // using negative value to allow for mobile scroll elasticity
        end: 'center center',
        onToggle: (self) => {
          setIsIntroVisible(
            !self.isActive && self.direction === 1 ? false : true
          )
        },
      })
    }

    return () => {
      scrollTrigger?.kill()
    }
  }, [ref, trigger, isEnabled])

  return isIntroVisbile
}
