import React, { useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player/lazy'
import { useInView } from '@/hooks/useInView'
import * as styles from './VideoLoop.styles.scss'

const VideoLoop = ({
  isPlaying,
  isLooping,
  src,
  isFull,
  aspectRatio,
  objectFit,
}) => {
  const ref = useRef()
  const isInView = useInView(ref)

  return (
    <div
      ref={ref}
      className={classNames(styles.VideoLoop, {
        [styles.isFull]: isFull,
        [styles.isCovered]: objectFit === 'cover',
        [styles.isContained]: objectFit === 'contain',
      })}
    >
      {!isFull && (
        <div
          className={styles.sizer}
          style={{
            paddingTop: !!aspectRatio && `${100 / aspectRatio}%`,
          }}
        />
      )}
      <div className={styles.inner}>
        <ReactPlayer
          url={src}
          playing={isPlaying || isInView}
          width="100%"
          height="100%"
          controls={false}
          muted={true}
          playsinline
          loop={isLooping}
        />
      </div>
    </div>
  )
}

VideoLoop.defaultProps = {
  isPlaying: undefined,
  isLooping: true,
  isFull: false,
  objectFit: 'cover',
}

VideoLoop.propTypes = {
  isPlaying: PropTypes.bool,
  isLooping: PropTypes.bool,
  src: PropTypes.string,
  aspectRatio: PropTypes.number,
  isFull: PropTypes.bool,
  objectFit: PropTypes.string,
}

export { VideoLoop }
