import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Frame } from '@/components/Frame'
// import { Media } from '@/components/Media'
import { ResponsiveImg } from '@/components/ResponsiveImg'
import { VideoLightbox } from '@/components/VideoLightbox'
import TriangleArrowSvg from '@/assets/triangle-arrow.inline.svg'
import * as styles from './VideoThumbLink.styles.scss'

import { shaderConfig } from '@/components/GlitchCanvas/shaders/glitch-video-effect.js'
import trailerThumbSrc from '@/assets/images/trailer-thumb.webp'

const VideoThumbLink = ({ thumbMedia, thumbLabel, video }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const handleClickThumb = () => setIsLightboxOpen(true)
  const handeClose = () => setIsLightboxOpen(false)

  if (!video?.url) return

  return (
    <div>
      <VideoLightbox
        url={video?.url}
        aspectRatio={video?.aspectRatio}
        onClickClose={handeClose}
        isOpen={isLightboxOpen}
      />
      <button className={styles.thumb} onClick={handleClickThumb}>
        <Frame />
        <div className={styles.thumb__media}>
          {/* <Media {...thumbMedia} isFull objectFit="cover" /> */}
          {/* GlitchCanvas should use {...thumbMedia}, however CORS policy 'Access-Control-Allow-Origin' header needs to be present from storyblok to allow image to be used in gl */}
          <ResponsiveImg
            src={trailerThumbSrc}
            mediaType={'image'}
            isFull={true}
            objectFit={'cover'}
            shaderConfig={shaderConfig}
            enableGlitchCanvas={true}
          />
          <div className={styles.thumb__overlay} />
          <span className={styles.thumb__label}>
            {thumbLabel}
            <TriangleArrowSvg className={styles.thumb__icon} />
          </span>
        </div>
      </button>
    </div>
  )
}

VideoThumbLink.propTypes = {
  thumbMedia: PropTypes.object,
  thumbLabel: PropTypes.string,
  video: PropTypes.object,
}

export { VideoThumbLink }
