import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import useEmblaCarousel from 'embla-carousel-react'
import ReactTooltip from 'react-tooltip'
import { Container } from '@/components/Container'
import { BtnPaginate } from '@/components/BtnPaginate'
import { CarouselProgress } from '@/components/CarouselProgress'
import * as styles from './Carousel.styles.scss'

const Carousel = ({ items, uid }) => {
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(0)
  const [, setSelectedIndex] = useState(0)
  const [slidesInView, setSlidesInView] = useState(0)
  const [indent, setIndent] = useState(0)
  const indentRef = useRef()
  const [viewportRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: true,
  })

  const [, setScrollSnaps] = useState([])

  const onResize = useCallback(() => {
    if (!embla) return
    setSlidesInView(embla.slidesInView())
    setIndent(indentRef.current.offsetLeft)
  }, [embla, setSlidesInView, setIndent])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  const onScroll = useCallback(() => {
    if (!embla) return
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()))
    setScrollProgress(progress)
  }, [embla, setScrollProgress])

  useEffect(() => {
    if (!embla) return
    onSelect()
    onScroll()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
    embla.on('scroll', onScroll)
    embla.on('resize', onResize)
    embla.on('init', onResize)
    setIndent(indentRef.current.offsetLeft)
  }, [embla, setScrollSnaps, onSelect, onScroll, onResize])

  const handleClickPrev = useCallback(() => {
    if (embla) embla.scrollPrev()
  }, [embla])

  const handleClickNext = useCallback(() => {
    if (embla) embla.scrollNext()
  }, [embla])

  const tipId = `icon-${uid}`

  return (
    <div style={{ '--indent': `${indent}px` }}>
      <div>
        <Container indent={{ desktop: 2 }}>
          <div ref={indentRef} />
        </Container>
      </div>
      <ReactTooltip id={tipId} arrowColor="transparent">
        Drag
      </ReactTooltip>
      <div className={styles.embla} ref={viewportRef}>
        <div className={styles.embla__container} data-tip data-for={tipId}>
          {items.map((item) => {
            return (
              <div key={item.uid} className={styles.embla__item}>
                {item.children}
              </div>
            )
          })}
        </div>
      </div>
      <Container indent={{ desktop: 2 }}>
        <nav className={styles.nav}>
          <div className={styles.nav__progress}>
            <CarouselProgress
              slidesInView={slidesInView}
              totalSlides={items.length}
              scrollProgress={scrollProgress}
            />
          </div>
          <div className={styles.nav__btn}>
            <BtnPaginate
              onClick={handleClickPrev}
              direction="prev"
              isDisabled={!prevBtnEnabled}
            />
          </div>
          <div className={styles.nav__btn}>
            <BtnPaginate
              onClick={handleClickNext}
              direction="next"
              isDisabled={!nextBtnEnabled}
            />
          </div>
        </nav>
      </Container>
    </div>
  )
}

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { Carousel }
