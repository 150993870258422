import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import * as styles from './LogoIcon.styles.scss'
import IconSvg from './images/icon.inline.svg'
import HexagonSvg from './images/hexagon.inline.svg'

const LogoIcon = ({ href }) => {
  return (
    <span aria-label="Star Origins" className={styles.LogoIcon}>
      <Link to={href} className={styles.link}>
        <IconSvg className={styles.icon} />
        <HexagonSvg className={styles.hexagon} />
      </Link>
    </span>
  )
}

LogoIcon.propTypes = {
  href: PropTypes.string,
}

export { LogoIcon }
