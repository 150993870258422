import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Container.styles.scss'

const Container = ({ children, className, indent }) => {
  return (
    <div className={classNames(styles.Container, className)}>
      {typeof indent === 'object' ? (
        <div
          className={classNames({
            [styles.gridRowD]: !!indent.desktop,
            [styles.gridRowM]: !!indent.mobile,
          })}
        >
          <div
            className={classNames({
              [styles[`indentD${indent.desktop}`]]: !!indent.desktop,
              [styles[`indentM${indent.mobile}`]]: !!indent.mobile,
            })}
          >
            {children}
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  indent: PropTypes.shape({
    desktop: PropTypes.number,
    mobile: PropTypes.number,
  }),
}

export { Container }
