import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useSlideshowTransition } from '../../hooks/useSlideshowTransition'
import { Slideshow } from '../Slideshow'
import { TokenScene } from '../TokenScene'
import { TransitionCanvas } from '../TransitionCanvas'

import * as styles from './SlideshowTokens.styles.scss'

const SlideshowTokens = ({ items }) => {
  const [isEffectTransitioning, setIsEffectTransitioning] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleClickThumb = (index) => setCurrentIndex(index)
  const ref = useRef()

  const { isTransitioning, foregroundSrcA, foregroundSrcB } =
    useSlideshowTransition(ref, {
      currentIndex,
      selectors: { foregroundMedia: `[data-foreground-media] img` },
    })

  return (
    <Slideshow
      ref={ref}
      onNavigate={handleClickThumb}
      currentIndex={currentIndex}
      hasBorders={false}
      renderTransitions={
        <div>
          <div className={styles.transitionCanvas}>
            <div className={styles.transitionCanvas__inner}>
              <TransitionCanvas
                isTransitioning={isTransitioning}
                isEffectTransitioning={isEffectTransitioning}
                setIsEffectTransitioning={setIsEffectTransitioning}
                objectFit="contain"
                objectPosition="center"
                cropTextures={true}
                imageSrcA={foregroundSrcA}
                imageSrcB={foregroundSrcB}
              />
            </div>
          </div>
        </div>
      }
      items={items.map((item, index) => {
        return {
          ...item,
          renderScene: (
            <TokenScene
              media={item.media}
              isActive={currentIndex === index && !isEffectTransitioning}
              isVisible={!isEffectTransitioning}
            />
          ),
        }
      })}
    />
  )
}

SlideshowTokens.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { SlideshowTokens }
