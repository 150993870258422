import { atom } from 'recoil'

/**
 * Store app loading state
 *
 * @usage
 * const { isLoaded, pathname } = useRecoilValue(loadingState)
 *
 * See usePageLoaded() hook for watching current page is loaded
 */

export const loadingState = atom({
  key: 'loadingState', // unique ID (with respect to other atoms/selectors)
  default: { pathname: null, isLoaded: false },
})
