import { useEffect } from 'react'
import { gsap } from 'gsap'
import { useRecoilValue } from 'recoil'
import { TRANSITION_STATES } from '@/constants/pageTransition'
import { smoothScrollState, transitionState } from '@/store'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const useScrollEffects = (ref) => {
  const isSmoothScroll = useRecoilValue(smoothScrollState)
  const transition = useRecoilValue(transitionState)

  // Trigger a ScrollTrigger re-creation when page changes
  const isReady = transition === TRANSITION_STATES.IN

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        start: 0,
        end: 200,
        scrub: 1,
      },
    })
    tl.fromTo(ref.current, { autoAlpha: 1 }, { autoAlpha: 0 })

    return () => {
      tl.kill()
    }
  }, [ref, isSmoothScroll, isReady])
}
